export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلق"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع"])}
            }
          }
        }
      },
      "ar-EG": {
        "payment": {
          "status": {
            "pending": {
              
            },
            "paid": {
              
            }
          }
        }
      },
      "ar-SA": {
        "payment": {
          "status": {
            "pending": {
              
            },
            "paid": {
              
            }
          }
        }
      },
      "de-DE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offen"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])}
            }
          }
        }
      },
      "de-AT": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offen"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])}
            }
          }
        }
      },
      "de-CH": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offen"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])}
            }
          }
        }
      },
      "en-US": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])}
            }
          }
        }
      },
      "en-GB": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])}
            }
          }
        }
      },
      "it-IT": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in sospeso"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagato"])}
            }
          }
        }
      },
      "fr-FR": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en attente"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])}
            }
          }
        }
      },
      "es-ES": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pendiente"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagado"])}
            }
          }
        }
      },
      "pl-PL": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w oczekiwaniu"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["płatny"])}
            }
          }
        }
      },
      "nl-NL": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aanhangig"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betaald"])}
            }
          }
        }
      },
      "bg-BG": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["висящ"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["платен"])}
            }
          }
        }
      },
      "bs-BA": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na čekanju"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaćeni"])}
            }
          }
        }
      },
      "da-DK": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verserende"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betalt"])}
            }
          }
        }
      },
      "et-EE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ootel"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["makstud"])}
            }
          }
        }
      },
      "fi-FI": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odotettaessa"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maksettu"])}
            }
          }
        }
      },
      "el-GR": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εκκρεμής"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επί πληρωμή"])}
            }
          }
        }
      },
      "he-IL": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ממתין ל"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שולם"])}
            }
          }
        }
      },
      "ga-IE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar feitheamh"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["íoctha"])}
            }
          }
        }
      },
      "ja-JP": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未確定"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払った"])}
            }
          }
        }
      },
      "hr-HR": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neriješen"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaćen"])}
            }
          }
        }
      },
      "lv-LV": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gaida"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samaksāts"])}
            }
          }
        }
      },
      "lt-LT": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kol"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mokama"])}
            }
          }
        }
      },
      "pt-PT": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pendente"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pago"])}
            }
          }
        }
      },
      "ro-RO": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in asteptarea"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plătit"])}
            }
          }
        }
      },
      "ru-RU": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в ожидании"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оплаченный"])}
            }
          }
        }
      },
      "sv-SE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i väntan på"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betald"])}
            }
          }
        }
      },
      "sk-SK": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nevykonaného"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaplatil"])}
            }
          }
        }
      },
      "cs-CZ": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čekající"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaplaceno"])}
            }
          }
        }
      },
      "tr-TR": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["askıda olan"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ödenmiş"])}
            }
          }
        }
      },
      "hu-HU": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["függőben levő"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fizetett"])}
            }
          }
        }
      },
      "fr-BE": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en attente"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])}
            }
          }
        }
      },
      "fr-LU": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en attente"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])}
            }
          }
        }
      },
      "mt-MT": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pendenti"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imħallas"])}
            }
          }
        }
      },
      "sl-SI": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v teku"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plačan"])}
            }
          }
        }
      },
      "el-CY": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εκκρεμής"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επί πληρωμή"])}
            }
          }
        }
      },
      "tr-CY": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["askıda olan"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ödenmiş"])}
            }
          }
        }
      },
      "uk-UA": {
        "payment": {
          "status": {
            "pending": {
              
            },
            "paid": {
              
            }
          }
        }
      },
      "zh-CN": {
        "payment": {
          "status": {
            "pending": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待定"])}
            },
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已付"])}
            }
          }
        }
      }
    }
  })
}
