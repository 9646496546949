<script setup>
import { useI18n } from 'vue-i18n'
import StatusTagComplete from "./StatusTags/StatusTagComplete";
import StatusTagPending from "./StatusTags/StatusTagPending";
const { t } = useI18n()
</script>
<script>
  export default {
    name: "StatusTag",
    props: {
      status: {type: Boolean, required: true}
    }
  }
</script>

<template>
  <span class="status-tag">
    <template v-if="status">
      <span class="status-complete"><StatusTagComplete class="status-icon"/>{{t('payment.status.paid.label')}}</span>
      </template>
      <template v-else>
        <span class="status-pending"><StatusTagPending class="status-icon"/>{{t('payment.status.pending.label')}}</span>
      </template>
    </span>
</template>

<style scoped lang="scss">
.status-tag {
  white-space: nowrap;
}
.status-icon {
  margin-inline-end: .25em;
}
.status-complete {
  color: var(--use-theme__event__success__font-color);
}
.status-pending {
  color: var(--use-theme__event__warning__font-color);
}
</style>

<i18n>
{
  "ar-AE": {
    "payment": {
      "status": {
        "pending": {
          "label": "معلق"
        },
        "paid": {
          "label": "دفع"
        }
      }
    }
  },
  "ar-EG": {
    "payment": {
      "status": {
        "pending": {},
        "paid": {}
      }
    }
  },
  "ar-SA": {
    "payment": {
      "status": {
        "pending": {},
        "paid": {}
      }
    }
  },
  "de-DE": {
    "payment": {
      "status": {
        "pending": {
          "label": "offen"
        },
        "paid": {
          "label": "bezahlt"
        }
      }
    }
  },
  "de-AT": {
    "payment": {
      "status": {
        "pending": {
          "label": "offen"
        },
        "paid": {
          "label": "bezahlt"
        }
      }
    }
  },
  "de-CH": {
    "payment": {
      "status": {
        "pending": {
          "label": "offen"
        },
        "paid": {
          "label": "bezahlt"
        }
      }
    }
  },
  "en-US": {
    "payment": {
      "status": {
        "pending": {
          "label": "pending"
        },
        "paid": {
          "label": "paid"
        }
      }
    }
  },
  "en-GB": {
    "payment": {
      "status": {
        "pending": {
          "label": "pending"
        },
        "paid": {
          "label": "paid"
        }
      }
    }
  },
  "it-IT": {
    "payment": {
      "status": {
        "pending": {
          "label": "in sospeso"
        },
        "paid": {
          "label": "pagato"
        }
      }
    }
  },
  "fr-FR": {
    "payment": {
      "status": {
        "pending": {
          "label": "en attente"
        },
        "paid": {
          "label": "payé"
        }
      }
    }
  },
  "es-ES": {
    "payment": {
      "status": {
        "pending": {
          "label": "pendiente"
        },
        "paid": {
          "label": "pagado"
        }
      }
    }
  },
  "pl-PL": {
    "payment": {
      "status": {
        "pending": {
          "label": "w oczekiwaniu"
        },
        "paid": {
          "label": "płatny"
        }
      }
    }
  },
  "nl-NL": {
    "payment": {
      "status": {
        "pending": {
          "label": "aanhangig"
        },
        "paid": {
          "label": "betaald"
        }
      }
    }
  },
  "bg-BG": {
    "payment": {
      "status": {
        "pending": {
          "label": "висящ"
        },
        "paid": {
          "label": "платен"
        }
      }
    }
  },
  "bs-BA": {
    "payment": {
      "status": {
        "pending": {
          "label": "na čekanju"
        },
        "paid": {
          "label": "plaćeni"
        }
      }
    }
  },
  "da-DK": {
    "payment": {
      "status": {
        "pending": {
          "label": "verserende"
        },
        "paid": {
          "label": "betalt"
        }
      }
    }
  },
  "et-EE": {
    "payment": {
      "status": {
        "pending": {
          "label": "ootel"
        },
        "paid": {
          "label": "makstud"
        }
      }
    }
  },
  "fi-FI": {
    "payment": {
      "status": {
        "pending": {
          "label": "odotettaessa"
        },
        "paid": {
          "label": "maksettu"
        }
      }
    }
  },
  "el-GR": {
    "payment": {
      "status": {
        "pending": {
          "label": "εκκρεμής"
        },
        "paid": {
          "label": "επί πληρωμή"
        }
      }
    }
  },
  "he-IL": {
    "payment": {
      "status": {
        "pending": {
          "label": "ממתין ל"
        },
        "paid": {
          "label": "שולם"
        }
      }
    }
  },
  "ga-IE": {
    "payment": {
      "status": {
        "pending": {
          "label": "ar feitheamh"
        },
        "paid": {
          "label": "íoctha"
        }
      }
    }
  },
  "ja-JP": {
    "payment": {
      "status": {
        "pending": {
          "label": "未確定"
        },
        "paid": {
          "label": "支払った"
        }
      }
    }
  },
  "hr-HR": {
    "payment": {
      "status": {
        "pending": {
          "label": "neriješen"
        },
        "paid": {
          "label": "plaćen"
        }
      }
    }
  },
  "lv-LV": {
    "payment": {
      "status": {
        "pending": {
          "label": "gaida"
        },
        "paid": {
          "label": "samaksāts"
        }
      }
    }
  },
  "lt-LT": {
    "payment": {
      "status": {
        "pending": {
          "label": "kol"
        },
        "paid": {
          "label": "mokama"
        }
      }
    }
  },
  "pt-PT": {
    "payment": {
      "status": {
        "pending": {
          "label": "pendente"
        },
        "paid": {
          "label": "pago"
        }
      }
    }
  },
  "ro-RO": {
    "payment": {
      "status": {
        "pending": {
          "label": "in asteptarea"
        },
        "paid": {
          "label": "plătit"
        }
      }
    }
  },
  "ru-RU": {
    "payment": {
      "status": {
        "pending": {
          "label": "в ожидании"
        },
        "paid": {
          "label": "оплаченный"
        }
      }
    }
  },
  "sv-SE": {
    "payment": {
      "status": {
        "pending": {
          "label": "i väntan på"
        },
        "paid": {
          "label": "betald"
        }
      }
    }
  },
  "sk-SK": {
    "payment": {
      "status": {
        "pending": {
          "label": "nevykonaného"
        },
        "paid": {
          "label": "zaplatil"
        }
      }
    }
  },
  "cs-CZ": {
    "payment": {
      "status": {
        "pending": {
          "label": "čekající"
        },
        "paid": {
          "label": "zaplaceno"
        }
      }
    }
  },
  "tr-TR": {
    "payment": {
      "status": {
        "pending": {
          "label": "askıda olan"
        },
        "paid": {
          "label": "ödenmiş"
        }
      }
    }
  },
  "hu-HU": {
    "payment": {
      "status": {
        "pending": {
          "label": "függőben levő"
        },
        "paid": {
          "label": "fizetett"
        }
      }
    }
  },
  "fr-BE": {
    "payment": {
      "status": {
        "pending": {
          "label": "en attente"
        },
        "paid": {
          "label": "payé"
        }
      }
    }
  },
  "fr-LU": {
    "payment": {
      "status": {
        "pending": {
          "label": "en attente"
        },
        "paid": {
          "label": "payé"
        }
      }
    }
  },
  "mt-MT": {
    "payment": {
      "status": {
        "pending": {
          "label": "pendenti"
        },
        "paid": {
          "label": "imħallas"
        }
      }
    }
  },
  "sl-SI": {
    "payment": {
      "status": {
        "pending": {
          "label": "v teku"
        },
        "paid": {
          "label": "plačan"
        }
      }
    }
  },
  "el-CY": {
    "payment": {
      "status": {
        "pending": {
          "label": "εκκρεμής"
        },
        "paid": {
          "label": "επί πληρωμή"
        }
      }
    }
  },
  "tr-CY": {
    "payment": {
      "status": {
        "pending": {
          "label": "askıda olan"
        },
        "paid": {
          "label": "ödenmiş"
        }
      }
    }
  },
  "uk-UA": {
    "payment": {
      "status": {
        "pending": {},
        "paid": {}
      }
    }
  },
  "zh-CN": {
    "payment": {
      "status": {
        "pending": {
          "label": "待定"
        },
        "paid": {
          "label": "已付"
        }
      }
    }
  }
}
</i18n>
