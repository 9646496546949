<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import {ElFormItem, ElForm} from 'element-plus';
import 'element-plus/es/components/form-item/style/css';
import 'element-plus/es/components/form/style/css';
import {ElCol, ElRow} from 'element-plus';
import 'element-plus/es/components/col/style/css';
import 'element-plus/es/components/row/style/css';
</script>
<script>
  import axios from 'axios';
  import each from 'lodash/each';
  import cloneDeep from 'lodash/cloneDeep';
  import get from 'lodash/get';
  import {mapState, mapActions} from 'pinia';
  import { useEnvStore, useCompanyStore } from '@dutypay/store-modules'
  import {useReportsParameterStore} from "../../../stores/reportsParameters";

  import dayjs from 'dayjs';
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc);
  const localizedFormat = require('dayjs/plugin/localizedFormat')
  dayjs.extend(localizedFormat);
  const customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);
  const localeData = require('dayjs/plugin/localeData');
  dayjs.extend(localeData);

  export default {
    name: "FilterForm",
    data() {
      return {
        spReportTypeIdLoading: false,
        spReportTypeIdOptions: [],
        spCountryIdLoading: false,
        spPaymentInstrumentIdLoading: false,
        spPaymentInstrumentIdOptions: [],
        spCountryIdOptions: [],
        filter: {
          spCountryId: '',
          reportingPeriodStartDate: {op: '', date: null},
          reportingPeriodEndDate: {op: '', date: null},
          spReportTypeId: '',
          orderDate: {op: '', date: null},
          paymentStartTime: {op: '', date: null},
          spPaymentInstrumentId: ''
        },
        defaultFilterValues: {
          spCountryId: '',
          reportingPeriodStartDate: {op: '', date: null},
          reportingPeriodEndDate: {op: '', date: null},
          spReportTypeId: '',
          orderDate: {op: '', date: null},
          paymentStartTime: {op: '', date: null},
          spPaymentInstrumentId: ''
        }
      };
    },
    computed: {
      ...mapState(useEnvStore, ['apiUrls']),
      ...mapState(useCompanyStore, ['selectedCompany']),
      ...mapState(useReportsParameterStore, ['queryParameters']),
      selectedCompanyId() {
        return this.selectedCompany.sp.company.id;
      },
      i18nLocale() {
        return this.$i18n.locale;
      },
    },
    watch: {
      queryParameters: {
        deep: true,
        handler() {
          this.readFilters();
        }
      },
      i18nLocale: {
        handler() {
          this.readFilters();
        }
      }
    },
    methods: {
      ...mapActions(useReportsParameterStore, ['pushQueryParameters']),
      readLocalizedDate(date) {
        if (date === null)
          return null;
        return dayjs.utc(date).local().format('L')
      },
      readFilters() {
        this.filter.spCountryId = get(this.queryParameters, 'spCountryId', '');
        this.filter.reportingPeriodStartDate = {
          date: this.readLocalizedDate(get(this.queryParameters, 'reportingPeriodStartDate', null)),
          op: get(this.queryParameters, 'reportingPeriodStartDateOp', 'eq')
        };
        this.filter.reportingPeriodEndDate = {
          date: this.readLocalizedDate(get(this.queryParameters, 'reportingPeriodEndDate', null)),
          op: get(this.queryParameters, 'reportingPeriodEndDateOp', 'eq')
        };
        this.filter.spReportTypeId = get(this.queryParameters, 'spReportTypeId', '');
        this.filter.orderDate = {
          date: this.readLocalizedDate(get(this.queryParameters, 'orderDate', null)),
          op: get(this.queryParameters, 'orderDateOp', 'eq')
        };
        this.filter.paymentStartTime = {
          date: this.readLocalizedDate(get(this.queryParameters, 'paymentStartTime', null)),
          op: get(this.queryParameters, 'paymentStartTimeOp', 'eq')
        };
        this.filter.spPaymentInstrumentId = get(this.queryParameters, 'spPaymentInstrumentId', '');
      },
      resetFilters() {
        this.filter = cloneDeep(this.defaultFilterValues);
        this.applyFilter();
      },
      writeLocalizedDate(date) {
        if (date === null)
          return null;
        return dayjs(date, [dayjs.localeData().longDateFormat('L'), dayjs.localeData().longDateFormat('l')], dayjs.locale()).format()
      },
      async applyFilter() {
        let self = this;
        this.$refs['filterForm'].validate(
          async (valid) => {
            if (valid) {
              self.pushQueryParameters({
                page: undefined,
                spCountryId: self.filter.spCountryId || undefined,
                reportingPeriodStartDate: self.filter.reportingPeriodStartDate.date ? self.writeLocalizedDate(self.filter.reportingPeriodStartDate.date) : undefined,
                reportingPeriodStartDateOp: self.filter.reportingPeriodStartDate.date ? self.filter.reportingPeriodStartDate.op : undefined,
                reportingPeriodEndDate: self.filter.reportingPeriodEndDate.date ? self.writeLocalizedDate(self.filter.reportingPeriodEndDate.date) : undefined,
                reportingPeriodEndDateOp: self.filter.reportingPeriodEndDate.date ? self.filter.reportingPeriodEndDate.op : undefined,
                spReportTypeId: self.filter.spReportTypeId || undefined,
                orderDate: self.filter.orderDate.date ? self.writeLocalizedDate(self.filter.orderDate.date) : undefined,
                orderDateOp: self.filter.orderDate.date ? self.filter.orderDate.op : undefined,
                paymentStartTime: self.filter.paymentStartTime.date ? self.writeLocalizedDate(self.filter.paymentStartTime.date) : undefined,
                paymentStartTimeOp: self.filter.paymentStartTime.date ? self.filter.paymentStartTime.op : undefined,
                spPaymentInstrumentId: self.filter.spPaymentInstrumentId || undefined,
              });
              self.$emit('apply-filter');
              self.$emit('close');
            }
          }
        );
      },
      getOptionsForFilterValues(values, type) {
        let prefix = "";
        // eslint-disable-next-line no-unused-vars
        let prefixAlias = "";
        switch (type) {
          case "country":
            prefix = 'taxhub.pages.Reports.ListReports.FilterForm.contractCountry.filterValues-';
            // eslint-disable-next-line no-unused-vars
            prefixAlias = this.$t('taxhub.pages.Reports.ListReports.FilterForm.contractCountry.filterValues-');
            break;
          case "report-type":
            prefix = 'taxhub.pages.Reports.ListReports.FilterForm.report.type.filterValues-';
            // eslint-disable-next-line no-unused-vars
            prefixAlias = this.$t('taxhub.pages.Reports.ListReports.FilterForm.report.type.filterValues-');
            break;
          case "payment-instrument":
            prefix = 'taxhub.pages.Reports.ListReports.FilterForm.payment.instrument.filterValues-';
            // eslint-disable-next-line no-unused-vars
            prefixAlias = this.$t('taxhub.pages.Reports.ListReports.FilterForm.payment.instrument.filterValues-');
            break;
        }
        let labelsAndValues = [{
          value: '',
          translationKey: 'any.label',
          translationKeyAlias: this.$t('any.label')
        }];
        each(values, function (value) {
          labelsAndValues.push({
            value: value,
            translationKey: prefix + value
          });
        });
        return labelsAndValues;
      },
      async getFilterOptions() {
        this.spReportTypeIdLoading = true;
        this.spCountryIdLoading = true;
        this.spPaymentInstrumentIdLoading = true;
        axios.all([
          this.$http.get(`${this.apiUrls.taxhub}/api/v0/report-type/${this.selectedCompanyId}`),
          this.$http.get(`${this.apiUrls.taxhub}/api/v0/country/${this.selectedCompanyId}`),
          this.$http.get(`${this.apiUrls.taxhub}/api/v0/payment-instrument/${this.selectedCompanyId}`)
        ]).then((...responses) => {
          responses = responses[0];

          this.spReportTypeIdOptions = (responses[0] !== undefined && responses[0].data !== undefined) ? this.getOptionsForFilterValues(responses[0].data, 'report-type') : [];
          this.spCountryIdOptions = (responses[1] !== undefined && responses[1].data !== undefined) ? this.getOptionsForFilterValues(responses[1].data, 'country') : [];
          this.spPaymentInstrumentIdOptions = (responses[2] !== undefined && responses[2].data !== undefined) ? this.getOptionsForFilterValues(responses[2].data, 'payment-instrument') : [];

          this.spReportTypeIdLoading = false;
          this.spCountryIdLoading = false;
          this.spPaymentInstrumentIdLoading = false;

        }).catch(e => {
          throw(e);
        });
      }
    },
    async mounted() {
      await this.getFilterOptions();
      this.readFilters();
    }
  }
</script>

<template>
  <div class="partition depth-1">
    <h4>{{t('filter.label')}}</h4>
    <el-form :model="filter"
             label-position="top"
             ref="filterForm"
             @submit.prevent>
      <el-row :gutter="20">
        <el-col :xl="{span: 8}" :sm="{span: 12}" :xs="{span: 24}">

          <DpSelectCustom v-model="filter.spCountryId"
                          :label="t('contractCountry.label')"
                          v-loading="spCountryIdLoading"
                          :options="spCountryIdOptions"
                          prop="spCountryId"
                          :t-function="t"/>

        </el-col>
        <el-col :xl="{span: 8}" :sm="{span: 12}" :xs="{span: 24}">

          <DpSelectCustom v-model="filter.spReportTypeId"
                          :label="t('report.type.label')"
                          :filterable="true"
                          v-loading="spReportTypeIdLoading"
                          :options="spReportTypeIdOptions"
                          prop="spReportTypeId"
                          :t-function="t"/>

        </el-col>
        <el-col :xl="{span: 8}" :sm="{span: 12}" :xs="{span: 24}">

          <DpSelectCustom v-model="filter.spPaymentInstrumentId"
                          :label="t('payment.instrument.label')"
                          v-loading="spPaymentInstrumentIdLoading"
                          :options="spPaymentInstrumentIdOptions"
                          prop="spPaymentInstrumentId"
                          :t-function="t"/>

        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :sm="{span: 12}" :xs="{span: 24}">

          <DpDatePickerCustomOp v-model="filter.orderDate"
                                :label="t('order.date.label')"
                                prop="orderDate"/>

        </el-col>
        <el-col :sm="{span: 12}" :xs="{span: 24}">

          <DpDatePickerCustomOp v-model="filter.paymentStartTime"
                                :label="t('payment.startTime.label')"
                                prop="paymentStartTime"/>

        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :sm="{span: 12}" :xs="{span: 24}">

          <DpDatePickerCustomOp v-model="filter.reportingPeriodStartDate"
                                :label="t('reportingPeriod.startDate.label')"
                                prop="paymentStartTime"/>

        </el-col>
        <el-col :sm="{span: 12}" :xs="{span: 24}">

          <DpDatePickerCustomOp v-model="filter.reportingPeriodEndDate"
                                :label="t('reportingPeriod.endDate.label')"
                                prop="paymentStartTime"/>

        </el-col>
      </el-row>

      <el-form-item>
        <dp-button purpose="secondary" @click="resetFilters()">{{t('clearFilters.buttonLabel')}}</dp-button>
      </el-form-item>
      <el-form-item>
        <dp-button purpose="primary" @click="applyFilter()">
          {{t('applyFilters.buttonLabel')}}
        </dp-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style scoped lang="scss">
</style>

<i18n>
{
  "ar-AE": {
    "payment": {
      "startTime": {
        "label": "وقت السداد"
      },
      "instrument": {
        "label": "وسيلة الدفع"
      }
    },
    "any": {
      "label": "لا يوجد فلتر"
    },
    "applyFilters": {
      "buttonLabel": "تطبيق المرشحات"
    },
    "clearFilters": {
      "buttonLabel": "مرشحات واضحة"
    },
    "filter": {
      "label": "منقي"
    },
    "order": {
      "date": {
        "label": "تاريخ الطلب"
      }
    },
    "contractCountry": {
      "label": "بلد متعاقد"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "تاريخ بدء الإبلاغ"
      },
      "endDate": {
        "label": "تاريخ انتهاء التقرير"
      }
    },
    "report": {
      "type": {
        "label": "نوع التقرير"
      }
    }
  },
  "ar-EG": {
    "payment": {
      "startTime": {},
      "instrument": {}
    },
    "any": {},
    "applyFilters": {},
    "clearFilters": {},
    "filter": {},
    "order": {
      "date": {}
    },
    "contractCountry": {},
    "reportingPeriod": {
      "startDate": {},
      "endDate": {}
    },
    "report": {
      "type": {}
    }
  },
  "ar-SA": {
    "payment": {
      "startTime": {},
      "instrument": {}
    },
    "any": {},
    "applyFilters": {},
    "clearFilters": {},
    "filter": {},
    "order": {
      "date": {}
    },
    "contractCountry": {},
    "reportingPeriod": {
      "startDate": {},
      "endDate": {}
    },
    "report": {
      "type": {}
    }
  },
  "de-DE": {
    "payment": {
      "startTime": {
        "label": "Zahlungszeitpunkt"
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      }
    },
    "any": {
      "label": "kein Filter"
    },
    "applyFilters": {
      "buttonLabel": "Filter anwenden"
    },
    "clearFilters": {
      "buttonLabel": "Filter löschen"
    },
    "filter": {
      "label": "Filter"
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum"
      }
    },
    "contractCountry": {
      "label": "Vertragsland"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Meldezeitraumbeginn"
      },
      "endDate": {
        "label": "Meldezeitraumende"
      }
    },
    "report": {
      "type": {
        "label": "Berichtstyp"
      }
    }
  },
  "de-AT": {
    "payment": {
      "startTime": {
        "label": "Zahlungszeitpunkt"
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      }
    },
    "any": {
      "label": "kein Filter"
    },
    "applyFilters": {
      "buttonLabel": "Filter anwenden"
    },
    "clearFilters": {
      "buttonLabel": "Filter löschen"
    },
    "filter": {
      "label": "Filter"
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum"
      }
    },
    "contractCountry": {
      "label": "Vertragsland"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Meldezeitraumbeginn"
      },
      "endDate": {
        "label": "Meldezeitraumende"
      }
    },
    "report": {
      "type": {
        "label": "Berichtstyp"
      }
    }
  },
  "de-CH": {
    "payment": {
      "startTime": {
        "label": "Zahlungszeitpunkt"
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      }
    },
    "any": {
      "label": "kein Filter"
    },
    "applyFilters": {
      "buttonLabel": "Filter anwenden"
    },
    "clearFilters": {
      "buttonLabel": "Filter löschen"
    },
    "filter": {
      "label": "Filter"
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum"
      }
    },
    "contractCountry": {
      "label": "Vertragsland"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Meldezeitraumbeginn"
      },
      "endDate": {
        "label": "Meldezeitraumende"
      }
    },
    "report": {
      "type": {
        "label": "Berichtstyp"
      }
    }
  },
  "en-US": {
    "payment": {
      "startTime": {
        "label": "Transfer time"
      },
      "instrument": {
        "label": "Payment instrument"
      }
    },
    "any": {
      "label": "no filter"
    },
    "applyFilters": {
      "buttonLabel": "Apply filters"
    },
    "clearFilters": {
      "buttonLabel": "Clear filters"
    },
    "filter": {
      "label": "Filter"
    },
    "order": {
      "date": {
        "label": "Order date"
      }
    },
    "contractCountry": {
      "label": "Contract country"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Reporting start date"
      },
      "endDate": {
        "label": "Reporting end date"
      }
    },
    "report": {
      "type": {
        "label": "Report type"
      }
    }
  },
  "en-GB": {
    "payment": {
      "startTime": {
        "label": "Transfer time"
      },
      "instrument": {
        "label": "Payment instrument"
      }
    },
    "any": {
      "label": "no filter"
    },
    "applyFilters": {
      "buttonLabel": "Apply filters"
    },
    "clearFilters": {
      "buttonLabel": "Clear filters"
    },
    "filter": {
      "label": "Filter"
    },
    "order": {
      "date": {
        "label": "Order date"
      }
    },
    "contractCountry": {
      "label": "Contract country"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Reporting start date"
      },
      "endDate": {
        "label": "Reporting end date"
      }
    },
    "report": {
      "type": {
        "label": "Report type"
      }
    }
  },
  "it-IT": {
    "payment": {
      "startTime": {
        "label": "Tempo di trasferimento"
      },
      "instrument": {
        "label": "Strumento di pagamento"
      }
    },
    "any": {
      "label": "senza Filtro"
    },
    "applyFilters": {
      "buttonLabel": "Applica filtri"
    },
    "clearFilters": {
      "buttonLabel": "Cancella filtri"
    },
    "filter": {
      "label": "Filtro"
    },
    "order": {
      "date": {
        "label": "Data dell'ordine"
      }
    },
    "contractCountry": {
      "label": "Paese contraente"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Data di inizio della segnalazione"
      },
      "endDate": {
        "label": "Data di fine del rapporto"
      }
    },
    "report": {
      "type": {
        "label": "Tipo di Rapporto"
      }
    }
  },
  "fr-FR": {
    "payment": {
      "startTime": {
        "label": "Temps de transfert"
      },
      "instrument": {
        "label": "Instrument de paiement"
      }
    },
    "any": {
      "label": "pas de filtre"
    },
    "applyFilters": {
      "buttonLabel": "Appliquer des filtres"
    },
    "clearFilters": {
      "buttonLabel": "Effacer les filtres"
    },
    "filter": {
      "label": "Filtre"
    },
    "order": {
      "date": {
        "label": "Date de commande"
      }
    },
    "contractCountry": {
      "label": "Pays contractant"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Date de début du rapport"
      },
      "endDate": {
        "label": "Date de fin du rapport"
      }
    },
    "report": {
      "type": {
        "label": "Type de rapport"
      }
    }
  },
  "es-ES": {
    "payment": {
      "startTime": {
        "label": "Tiempo de transferencia"
      },
      "instrument": {
        "label": "Instrumento de pago"
      }
    },
    "any": {
      "label": "sin filtro"
    },
    "applyFilters": {
      "buttonLabel": "Aplicar filtros"
    },
    "clearFilters": {
      "buttonLabel": "Filtros claros"
    },
    "filter": {
      "label": "Filtrar"
    },
    "order": {
      "date": {
        "label": "Fecha de orden"
      }
    },
    "contractCountry": {
      "label": "País contratante"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Fecha de inicio de informes"
      },
      "endDate": {
        "label": "Fecha de finalización del informe"
      }
    },
    "report": {
      "type": {
        "label": "Tipo de Informe"
      }
    }
  },
  "pl-PL": {
    "payment": {
      "startTime": {
        "label": "Data płatności"
      },
      "instrument": {
        "label": "Metoda płatności"
      }
    },
    "any": {
      "label": "bez filtra"
    },
    "applyFilters": {
      "buttonLabel": "Zastosuj filtry"
    },
    "clearFilters": {
      "buttonLabel": "wyczyść filtry"
    },
    "filter": {
      "label": "Filtr"
    },
    "order": {
      "date": {
        "label": "Data zatwierdzenia danych"
      }
    },
    "contractCountry": {
      "label": "Obsługiwany kraj"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Początek okresu podlegającego zgłoszeniu"
      },
      "endDate": {
        "label": "Koniec okresu podlegającego zgłoszeniu"
      }
    },
    "report": {
      "type": {
        "label": "Typ Raportu"
      }
    }
  },
  "nl-NL": {
    "payment": {
      "startTime": {
        "label": "Tijdstip van betaling"
      },
      "instrument": {
        "label": "Betaalinstrument"
      }
    },
    "any": {
      "label": "geen filter"
    },
    "applyFilters": {
      "buttonLabel": "Filters toepassen"
    },
    "clearFilters": {
      "buttonLabel": "duidelijke filters"
    },
    "filter": {
      "label": "Filter"
    },
    "order": {
      "date": {
        "label": "Besteldatum"
      }
    },
    "contractCountry": {
      "label": "Verdragsluitende land"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Startdatum rapportage"
      },
      "endDate": {
        "label": "Einddatum rapportage"
      }
    },
    "report": {
      "type": {
        "label": "Rapporttype"
      }
    }
  },
  "bg-BG": {
    "payment": {
      "startTime": {
        "label": "Време на плащане"
      },
      "instrument": {
        "label": "Платежно нареждане"
      }
    },
    "any": {
      "label": "без филтър"
    },
    "applyFilters": {
      "buttonLabel": "Прилагане на филтри"
    },
    "clearFilters": {
      "buttonLabel": "изчистване на филтрите"
    },
    "filter": {
      "label": "филтър"
    },
    "order": {
      "date": {
        "label": "Дата на поръчка"
      }
    },
    "contractCountry": {
      "label": "Договаряща се страна"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Отчитане на начална дата"
      },
      "endDate": {
        "label": "Крайна дата на отчитане"
      }
    },
    "report": {
      "type": {
        "label": "Тип Oтчет"
      }
    }
  },
  "bs-BA": {
    "payment": {
      "startTime": {
        "label": "Vrijeme plaćanja"
      },
      "instrument": {
        "label": "Instrument plaćanja"
      }
    },
    "any": {
      "label": "bez filtera"
    },
    "applyFilters": {
      "buttonLabel": "Primjena filtera"
    },
    "clearFilters": {
      "buttonLabel": "jasni filtri"
    },
    "filter": {
      "label": "Filter"
    },
    "order": {
      "date": {
        "label": "Datum narudžbe"
      }
    },
    "contractCountry": {
      "label": "Država ugovornica"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Datum početka izvještavanja"
      },
      "endDate": {
        "label": "Krajnji datum izvještavanja"
      }
    },
    "report": {
      "type": {
        "label": "Vrsta Izvještaja"
      }
    }
  },
  "da-DK": {
    "payment": {
      "startTime": {
        "label": "Tidspunkt for betaling"
      },
      "instrument": {
        "label": "Betalingsinstrument"
      }
    },
    "any": {
      "label": "intet filter"
    },
    "applyFilters": {
      "buttonLabel": "Anvend filtre"
    },
    "clearFilters": {
      "buttonLabel": "ryd filtre"
    },
    "filter": {
      "label": "Filter"
    },
    "order": {
      "date": {
        "label": "Bestillingsdato"
      }
    },
    "contractCountry": {
      "label": "Kontraherende land"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Rapporterings startdato"
      },
      "endDate": {
        "label": "Rapporterings slutdato"
      }
    },
    "report": {
      "type": {
        "label": "Rapporttype"
      }
    }
  },
  "et-EE": {
    "payment": {
      "startTime": {
        "label": "Makse aeg"
      },
      "instrument": {
        "label": "Maksevahend"
      }
    },
    "any": {
      "label": "ilma filtrita"
    },
    "applyFilters": {
      "buttonLabel": "Rakenda filtrid"
    },
    "clearFilters": {
      "buttonLabel": "tühjendage filtrid"
    },
    "filter": {
      "label": "Filtreeri"
    },
    "order": {
      "date": {
        "label": "Tellimuse kuupäev"
      }
    },
    "contractCountry": {
      "label": "Lepinguosaline riik"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Aruande alguskuupäev"
      },
      "endDate": {
        "label": "Aruande lõppkuupäev"
      }
    },
    "report": {
      "type": {
        "label": "Aruande Tüüp"
      }
    }
  },
  "fi-FI": {
    "payment": {
      "startTime": {
        "label": "Maksuaika"
      },
      "instrument": {
        "label": "Maksutapa"
      }
    },
    "any": {
      "label": "ei filtteriä"
    },
    "applyFilters": {
      "buttonLabel": "Käytä suodattimia"
    },
    "clearFilters": {
      "buttonLabel": "tyhjennä suodattimet"
    },
    "filter": {
      "label": "Suodattaa"
    },
    "order": {
      "date": {
        "label": "Tilauspäivämäärä"
      }
    },
    "contractCountry": {
      "label": "Sopimusmaa"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Raportoinnin alkamispäivä"
      },
      "endDate": {
        "label": "Raportoinnin lopetuspäivä"
      }
    },
    "report": {
      "type": {
        "label": "Raporttityyppi"
      }
    }
  },
  "el-GR": {
    "payment": {
      "startTime": {
        "label": "Ώρα πληρωμής"
      },
      "instrument": {
        "label": "Μέσο πληρωμής"
      }
    },
    "any": {
      "label": "χωρίς φίλτρο"
    },
    "applyFilters": {
      "buttonLabel": "Εφαρμόστε φίλτρα"
    },
    "clearFilters": {
      "buttonLabel": "καθαρά φίλτρα"
    },
    "filter": {
      "label": "Φίλτρο"
    },
    "order": {
      "date": {
        "label": "Ημερομηνία παραγγελίας"
      }
    },
    "contractCountry": {
      "label": "Συμβαλλόμενη χώρα"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Ημερομηνία έναρξης αναφοράς"
      },
      "endDate": {
        "label": "Ημερομηνία λήξης αναφοράς"
      }
    },
    "report": {
      "type": {
        "label": "Τύπος Aναφοράς"
      }
    }
  },
  "he-IL": {
    "payment": {
      "startTime": {
        "label": "זמן התשלום"
      },
      "instrument": {
        "label": "מכשיר תשלום"
      }
    },
    "any": {
      "label": "ללא מסנן"
    },
    "applyFilters": {
      "buttonLabel": "החל פילטרים"
    },
    "clearFilters": {
      "buttonLabel": "מסננים ברורים"
    },
    "filter": {
      "label": "לְסַנֵן"
    },
    "order": {
      "date": {
        "label": "תאריך הזמנה"
      }
    },
    "contractCountry": {
      "label": "מדינה מתקשרת"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "מדווח על תאריך התחלה"
      },
      "endDate": {
        "label": "תאריך סיום מדווח"
      }
    },
    "report": {
      "type": {
        "label": "סוג דוח"
      }
    }
  },
  "ga-IE": {
    "payment": {
      "startTime": {
        "label": "Am na híocaíochta"
      },
      "instrument": {
        "label": "Lonstraim íocaíochta"
      }
    },
    "any": {
      "label": "Gan Scagaire"
    },
    "applyFilters": {
      "buttonLabel": "Cuir scagairí i bhfeidhm"
    },
    "clearFilters": {
      "buttonLabel": "scagairí soiléire"
    },
    "filter": {
      "label": "Scagaire"
    },
    "order": {
      "date": {
        "label": "Dáta ordaithe"
      }
    },
    "contractCountry": {
      "label": "Tír chonarthach"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Dáta tosaigh tuairiscithe"
      },
      "endDate": {
        "label": "Dáta deiridh tuairiscithe"
      }
    },
    "report": {
      "type": {
        "label": "Cineál Tuairisc"
      }
    }
  },
  "ja-JP": {
    "payment": {
      "startTime": {
        "label": "お支払い時期"
      },
      "instrument": {
        "label": "支払い方法"
      }
    },
    "any": {
      "label": "フィルターなし"
    },
    "applyFilters": {
      "buttonLabel": "フィルターを適用する"
    },
    "clearFilters": {
      "buttonLabel": "フィルターをクリア"
    },
    "filter": {
      "label": "フィルタ"
    },
    "order": {
      "date": {
        "label": "注文日"
      }
    },
    "contractCountry": {
      "label": "締約国"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "レポート開始日"
      },
      "endDate": {
        "label": "レポート終了日"
      }
    },
    "report": {
      "type": {
        "label": "レポートタイプ"
      }
    }
  },
  "hr-HR": {
    "payment": {
      "startTime": {
        "label": "Vrijeme plaćanja"
      },
      "instrument": {
        "label": "Instrument plaćanja"
      }
    },
    "any": {
      "label": "bez filtera"
    },
    "applyFilters": {
      "buttonLabel": "Primjena filtera"
    },
    "clearFilters": {
      "buttonLabel": "čisti filtri"
    },
    "filter": {
      "label": "Filtar"
    },
    "order": {
      "date": {
        "label": "Datum narudžbe"
      }
    },
    "contractCountry": {
      "label": "Zemlja ugovornica"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Datum početka izvještavanja"
      },
      "endDate": {
        "label": "Krajnji datum izvještavanja"
      }
    },
    "report": {
      "type": {
        "label": "Vrsta Izvješća"
      }
    }
  },
  "lv-LV": {
    "payment": {
      "startTime": {
        "label": "Apmaksas laiks"
      },
      "instrument": {
        "label": "Maksāšanas līdzeklis"
      }
    },
    "any": {
      "label": "nav filtra"
    },
    "applyFilters": {
      "buttonLabel": "Lietot filtrus"
    },
    "clearFilters": {
      "buttonLabel": "notīrīt filtrus"
    },
    "filter": {
      "label": "Filtrēt"
    },
    "order": {
      "date": {
        "label": "Pasūtījuma datums"
      }
    },
    "contractCountry": {
      "label": "Līgumslēdzēja valsts"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Ziņošanas sākuma datums"
      },
      "endDate": {
        "label": "Pārskata beigu datums"
      }
    },
    "report": {
      "type": {
        "label": "Pārskata Veids"
      }
    }
  },
  "lt-LT": {
    "payment": {
      "startTime": {
        "label": "Mokėjimo laikas"
      },
      "instrument": {
        "label": "Mokėjimo priemonė"
      }
    },
    "any": {
      "label": "nėra filtro"
    },
    "applyFilters": {
      "buttonLabel": "Taikyti filtrus"
    },
    "clearFilters": {
      "buttonLabel": "išvalyti filtrus"
    },
    "filter": {
      "label": "Filtras"
    },
    "order": {
      "date": {
        "label": "Užsakymo data"
      }
    },
    "contractCountry": {
      "label": "Susitariančioji šalis"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Ataskaitų teikimo pradžios data"
      },
      "endDate": {
        "label": "Ataskaitos pabaigos data"
      }
    },
    "report": {
      "type": {
        "label": "Ataskaitos Tipas"
      }
    }
  },
  "pt-PT": {
    "payment": {
      "startTime": {
        "label": "Hora do pagamento"
      },
      "instrument": {
        "label": "Instrumento de pagamento"
      }
    },
    "any": {
      "label": "Sem filtro"
    },
    "applyFilters": {
      "buttonLabel": "Aplicar filtros"
    },
    "clearFilters": {
      "buttonLabel": "limpar filtros"
    },
    "filter": {
      "label": "Filtro"
    },
    "order": {
      "date": {
        "label": "Data do pedido"
      }
    },
    "contractCountry": {
      "label": "País contratante"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Data de início do relatório"
      },
      "endDate": {
        "label": "Data de término do relatório"
      }
    },
    "report": {
      "type": {
        "label": "Tipo de Relatório"
      }
    }
  },
  "ro-RO": {
    "payment": {
      "startTime": {
        "label": "Ora de plată"
      },
      "instrument": {
        "label": "Instrument de plată"
      }
    },
    "any": {
      "label": "fără filtru"
    },
    "applyFilters": {
      "buttonLabel": "Aplicați filtre"
    },
    "clearFilters": {
      "buttonLabel": "filtre clare"
    },
    "filter": {
      "label": "Filtru"
    },
    "order": {
      "date": {
        "label": "Data comandă"
      }
    },
    "contractCountry": {
      "label": "Țara contractantă"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Data începerii raportării"
      },
      "endDate": {
        "label": "Data de încheiere a raportării"
      }
    },
    "report": {
      "type": {
        "label": "Tip de Raport"
      }
    }
  },
  "ru-RU": {
    "payment": {
      "startTime": {
        "label": "Время оплаты"
      },
      "instrument": {
        "label": "Платежный инструмент"
      }
    },
    "any": {
      "label": "нет фильтра"
    },
    "applyFilters": {
      "buttonLabel": "Применить фильтры"
    },
    "clearFilters": {
      "buttonLabel": "очистить фильтры"
    },
    "filter": {
      "label": "Фильтр"
    },
    "order": {
      "date": {
        "label": "Дата предоставления данных"
      }
    },
    "contractCountry": {
      "label": "Договаривающаяся страна"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Дата начала периода отчётности"
      },
      "endDate": {
        "label": "Дата окончания периода отчётности"
      }
    },
    "report": {
      "type": {
        "label": "Вид отчёта"
      }
    }
  },
  "sv-SE": {
    "payment": {
      "startTime": {
        "label": "Betalningstid"
      },
      "instrument": {
        "label": "Betalningsinstrument"
      }
    },
    "any": {
      "label": "inget filter"
    },
    "applyFilters": {
      "buttonLabel": "Använd filter"
    },
    "clearFilters": {
      "buttonLabel": "rensa filter"
    },
    "filter": {
      "label": "Filtrera"
    },
    "order": {
      "date": {
        "label": "Orderdatum"
      }
    },
    "contractCountry": {
      "label": "Avtalsland"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Rapporteringsstartdatum"
      },
      "endDate": {
        "label": "Rapporteringens slutdatum"
      }
    },
    "report": {
      "type": {
        "label": "Rapporttyp"
      }
    }
  },
  "sk-SK": {
    "payment": {
      "startTime": {
        "label": "Čas platby"
      },
      "instrument": {
        "label": "Platobný nástroj"
      }
    },
    "any": {
      "label": "žiadny filter"
    },
    "applyFilters": {
      "buttonLabel": "Použite filtre"
    },
    "clearFilters": {
      "buttonLabel": "vyčistiť filtre"
    },
    "filter": {
      "label": "Filter"
    },
    "order": {
      "date": {
        "label": "Dátum objednávky"
      }
    },
    "contractCountry": {
      "label": "Zmluvná krajina"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Dátum začiatku vykazovania"
      },
      "endDate": {
        "label": "Dátum ukončenia prehľadu"
      }
    },
    "report": {
      "type": {
        "label": "Typ Hlásenia"
      }
    }
  },
  "cs-CZ": {
    "payment": {
      "startTime": {
        "label": "Čas platby"
      },
      "instrument": {
        "label": "Platební nástroj"
      }
    },
    "any": {
      "label": "žádný filtr"
    },
    "applyFilters": {
      "buttonLabel": "Použít filtry"
    },
    "clearFilters": {
      "buttonLabel": "vymazat filtry"
    },
    "filter": {
      "label": "Filtr"
    },
    "order": {
      "date": {
        "label": "Datum objednávky"
      }
    },
    "contractCountry": {
      "label": "Smluvní země"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Datum zahájení hlášení"
      },
      "endDate": {
        "label": "Datum ukončení vykazování"
      }
    },
    "report": {
      "type": {
        "label": "Typ Zprávy"
      }
    }
  },
  "tr-TR": {
    "payment": {
      "startTime": {
        "label": "Ödeme zamanı"
      },
      "instrument": {
        "label": "Ödeme aracı"
      }
    },
    "any": {
      "label": "filtresiz"
    },
    "applyFilters": {
      "buttonLabel": "Filtreleri uygula"
    },
    "clearFilters": {
      "buttonLabel": "filtreleri temizle"
    },
    "filter": {
      "label": "Filtre"
    },
    "order": {
      "date": {
        "label": "Sipariş tarihi"
      }
    },
    "contractCountry": {
      "label": "Sözleşme yapılan ülke"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Raporlama başlangıç tarihi"
      },
      "endDate": {
        "label": "Raporlama bitiş tarihi"
      }
    },
    "report": {
      "type": {
        "label": "Rapor Türü"
      }
    }
  },
  "hu-HU": {
    "payment": {
      "startTime": {
        "label": "Fizetés időpontja"
      },
      "instrument": {
        "label": "Fizetés módja"
      }
    },
    "any": {
      "label": "nincs szűrő"
    },
    "applyFilters": {
      "buttonLabel": "Szűrők alkalmazása"
    },
    "clearFilters": {
      "buttonLabel": "törölje a szűrőket"
    },
    "filter": {
      "label": "Szűrő"
    },
    "order": {
      "date": {
        "label": "Rendelés dátuma"
      }
    },
    "contractCountry": {
      "label": "Szerződő ország"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Jelentés kezdete"
      },
      "endDate": {
        "label": "Jelentés befejezési dátuma"
      }
    },
    "report": {
      "type": {
        "label": "Jelentés Típusa"
      }
    }
  },
  "fr-BE": {
    "payment": {
      "startTime": {
        "label": "Temps de transfert"
      },
      "instrument": {
        "label": "Instrument de paiement"
      }
    },
    "any": {
      "label": "pas de filtre"
    },
    "applyFilters": {
      "buttonLabel": "Appliquer des filtres"
    },
    "clearFilters": {
      "buttonLabel": "Effacer les filtres"
    },
    "filter": {
      "label": "Filtre"
    },
    "order": {
      "date": {
        "label": "Date de commande"
      }
    },
    "contractCountry": {
      "label": "Pays contractant"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Date de début du rapport"
      },
      "endDate": {
        "label": "Date de fin du rapport"
      }
    },
    "report": {
      "type": {
        "label": "Type de rapport"
      }
    }
  },
  "fr-LU": {
    "payment": {
      "startTime": {
        "label": "Temps de transfert"
      },
      "instrument": {
        "label": "Instrument de paiement"
      }
    },
    "any": {
      "label": "pas de filtre"
    },
    "applyFilters": {
      "buttonLabel": "Appliquer des filtres"
    },
    "clearFilters": {
      "buttonLabel": "Effacer les filtres"
    },
    "filter": {
      "label": "Filtre"
    },
    "order": {
      "date": {
        "label": "Date de commande"
      }
    },
    "contractCountry": {
      "label": "Pays contractant"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Date de début du rapport"
      },
      "endDate": {
        "label": "Date de fin du rapport"
      }
    },
    "report": {
      "type": {
        "label": "Type de rapport"
      }
    }
  },
  "mt-MT": {
    "payment": {
      "startTime": {
        "label": "Ħin tat-trasferiment"
      },
      "instrument": {
        "label": "Strument tal-ħlas"
      }
    },
    "any": {
      "label": "l-ebda filtru"
    },
    "applyFilters": {
      "buttonLabel": "Applika filtru"
    },
    "clearFilters": {
      "buttonLabel": "Filtri Ċari"
    },
    "filter": {
      "label": "Iffiltra"
    },
    "order": {
      "date": {
        "label": "data tal-ordni"
      }
    },
    "contractCountry": {
      "label": "Pajjiż Kontraenti"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Bidu tal-perjodu tar-rappurtar"
      },
      "endDate": {
        "label": "Tmiem tal-perjodu tar-rappurtar"
      }
    },
    "report": {
      "type": {
        "label": "Tip ta 'Rapport"
      }
    }
  },
  "sl-SI": {
    "payment": {
      "startTime": {
        "label": "Čas plačila"
      },
      "instrument": {
        "label": "Plačilni instrument"
      }
    },
    "any": {
      "label": "brez filtra"
    },
    "applyFilters": {
      "buttonLabel": "Uporabi filtre"
    },
    "clearFilters": {
      "buttonLabel": "čisti filtri"
    },
    "filter": {
      "label": "Filter"
    },
    "order": {
      "date": {
        "label": "Datum naročila"
      }
    },
    "contractCountry": {
      "label": "Država pogodbenica"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Datum začetka poročanja"
      },
      "endDate": {
        "label": "Končni datum poročanja"
      }
    },
    "report": {
      "type": {
        "label": "Vrsta Poročila"
      }
    }
  },
  "el-CY": {
    "payment": {
      "startTime": {
        "label": "Ώρα πληρωμής"
      },
      "instrument": {
        "label": "Μέσο πληρωμής"
      }
    },
    "any": {
      "label": "χωρίς φίλτρο"
    },
    "applyFilters": {
      "buttonLabel": "Εφαρμόστε φίλτρα"
    },
    "clearFilters": {
      "buttonLabel": "καθαρά φίλτρα"
    },
    "filter": {
      "label": "Φίλτρο"
    },
    "order": {
      "date": {
        "label": "Ημερομηνία παραγγελίας"
      }
    },
    "contractCountry": {
      "label": "Συμβαλλόμενη χώρα"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Ημερομηνία έναρξης αναφοράς"
      },
      "endDate": {
        "label": "Ημερομηνία λήξης αναφοράς"
      }
    },
    "report": {
      "type": {
        "label": "Τύπος Aναφοράς"
      }
    }
  },
  "tr-CY": {
    "payment": {
      "startTime": {
        "label": "Ödeme zamanı"
      },
      "instrument": {
        "label": "Ödeme aracı"
      }
    },
    "any": {
      "label": "filtresiz"
    },
    "applyFilters": {
      "buttonLabel": "Filtreleri uygula"
    },
    "clearFilters": {
      "buttonLabel": "filtreleri temizle"
    },
    "filter": {
      "label": "Filtre"
    },
    "order": {
      "date": {
        "label": "Sipariş tarihi"
      }
    },
    "contractCountry": {
      "label": "Sözleşme yapılan ülke"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "Raporlama başlangıç tarihi"
      },
      "endDate": {
        "label": "Raporlama bitiş tarihi"
      }
    },
    "report": {
      "type": {
        "label": "Rapor Türü"
      }
    }
  },
  "uk-UA": {
    "payment": {
      "startTime": {},
      "instrument": {}
    },
    "any": {},
    "applyFilters": {},
    "clearFilters": {},
    "filter": {},
    "order": {
      "date": {}
    },
    "contractCountry": {},
    "reportingPeriod": {
      "startDate": {},
      "endDate": {}
    },
    "report": {
      "type": {}
    }
  },
  "zh-CN": {
    "payment": {
      "startTime": {
        "label": "付款时间"
      },
      "instrument": {
        "label": "付款方式"
      }
    },
    "any": {
      "label": "没有过滤器"
    },
    "applyFilters": {
      "buttonLabel": "套用筛选器"
    },
    "clearFilters": {
      "buttonLabel": "清除筛选"
    },
    "filter": {
      "label": "过滤"
    },
    "order": {
      "date": {
        "label": "订购日期"
      }
    },
    "contractCountry": {
      "label": "缔约国"
    },
    "reportingPeriod": {
      "startDate": {
        "label": "报告开始日期"
      },
      "endDate": {
        "label": "报告结束日期"
      }
    },
    "report": {
      "type": {
        "label": "报告类型"
      }
    }
  }
}
</i18n>
