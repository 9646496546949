export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معهد"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد مقر المعهد"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معهد المدفوع لأمره"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد مقر المعهد"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستعمال"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلق"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير تفاصيل الدفع"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير تفاصيل الدفع"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المدفوع"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الدفع"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية فترة السداد"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسيلة الدفع"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشارة دفع"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية فترة السداد"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الدفع"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الضريبة"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظرة عامة"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء ملف PDF في"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المستند"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم العميل"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الضريبي"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم تعريف الضريبة على القيمة المضافة"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ في خدمتنا. حاول مرة اخرى."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحميلات"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص بيانات التقارير"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة المشمولة بالتقرير"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ بدء الإبلاغ"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انتهاء التقرير"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضريبة الدخل"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضريبة المدخلات من الفواتير"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ائتمان"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ائتمان"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإمدادات داخل المجتمع ، مجموع"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقل داخل المجتمع"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الولادات المعفاة من الضرائب"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل الضريبة"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل الضريبة"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبيعات معفاة من الضرائب"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل الضريبة"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلطة"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم التعريف الخاص بالطلب"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضريبة القيمة المضافة"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الدفع"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الفائدة"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فائدة"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الطلب"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صافي المبيعات"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع التقرير"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقل"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صافي الإيرادات للرسوم العكسية (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Reverse charge) عكس الالتزام الضريبي"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمليات الاستحواذ داخل المجتمع"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع عمليات الاستحواذ داخل المجتمع"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمليات الاستحواذ داخل المجتمع"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل الضريبة"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبيعات الخاضعة للضريبة"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضريبة المدخلات المقتطعة"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استيراد ضريبة القيمة المضافة"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير"])}
        }
      },
      "ar-EG": {
        "payment": {
          "agent": {
            "bank": {
              "country": {
                
              }
            },
            "iban": {
              
            },
            "swiftcode": {
              
            },
            "account": {
              "owner": {
                
              }
            }
          },
          "recipient": {
            "bank": {
              "country": {
                
              }
            },
            "account": {
              "owner": {
                
              }
            },
            "iban": {
              
            },
            "swiftcode": {
              
            }
          },
          "purpose": {
            
          },
          "status": {
            "paid": {
              
            },
            "unpaid": {
              
            },
            "edit": {
              
            },
            "set": {
              
            }
          },
          "amount": {
            
          },
          "information": {
            
          },
          "endDueTime": {
            
          },
          "instrument": {
            
          },
          "reference": {
            
          },
          "startDueTime": {
            
          },
          "details": {
            
          }
        },
        "VATRate": {
          
        },
        "overview": {
          
        },
        "document": {
          "created": {
            "startTime": {
              
            }
          },
          "number": {
            
          }
        },
        "agent": {
          "number": {
            
          },
          "company": {
            "TIN": {
              
            }
          },
          "VATIN": {
            
          }
        },
        "notification": {
          "asyncError": {
            
          }
        },
        "downloads": {
          "downloadPDF": {
            
          }
        },
        "reporting": {
          "data": {
            "summary": {
              
            }
          }
        },
        "details": {
          
        },
        "reportingPeriod": {
          "startDate": {
            
          },
          "endDate": {
            
          }
        },
        "inputVAT": {
          "amount": {
            
          }
        },
        "credit": {
          "amount": {
            
          }
        },
        "iCSOG": {
          "total": {
            
          },
          "transfer": {
            
          },
          "net": {
            
          }
        },
        "parkingRate": {
          
        },
        "sRR": {
          
        },
        "taxFreeSales": {
          
        },
        "standardRate": {
          
        },
        "authority": {
          
        },
        "references": {
          "order": {
            "id": {
              
            }
          }
        },
        "outputVAT": {
          
        },
        "export": {
          "total": {
            
          }
        },
        "totalPaymentDue": {
          
        },
        "interest": {
          "amount": {
            
          }
        },
        "order": {
          "date": {
            
          }
        },
        "net": {
          
        },
        "report": {
          "type": {
            
          }
        },
        "reverseCharge": {
          "net": {
            
          }
        },
        "iCAOG": {
          "total": {
            "net": {
              
            }
          }
        },
        "reducedRate": {
          
        },
        "taxableSales": {
          
        },
        "deductibleTax": {
          
        },
        "importVAT": {
          "amount": {
            
          }
        },
        "edit": {
          
        }
      },
      "ar-SA": {
        "payment": {
          "agent": {
            "bank": {
              "country": {
                
              }
            },
            "iban": {
              
            },
            "swiftcode": {
              
            },
            "account": {
              "owner": {
                
              }
            }
          },
          "recipient": {
            "bank": {
              "country": {
                
              }
            },
            "account": {
              "owner": {
                
              }
            },
            "iban": {
              
            },
            "swiftcode": {
              
            }
          },
          "purpose": {
            
          },
          "status": {
            "paid": {
              
            },
            "unpaid": {
              
            },
            "edit": {
              
            },
            "set": {
              
            }
          },
          "amount": {
            
          },
          "information": {
            
          },
          "endDueTime": {
            
          },
          "instrument": {
            
          },
          "reference": {
            
          },
          "startDueTime": {
            
          },
          "details": {
            
          }
        },
        "VATRate": {
          
        },
        "overview": {
          
        },
        "document": {
          "created": {
            "startTime": {
              
            }
          },
          "number": {
            
          }
        },
        "agent": {
          "number": {
            
          },
          "company": {
            "TIN": {
              
            }
          },
          "VATIN": {
            
          }
        },
        "notification": {
          "asyncError": {
            
          }
        },
        "downloads": {
          "downloadPDF": {
            
          }
        },
        "reporting": {
          "data": {
            "summary": {
              
            }
          }
        },
        "details": {
          
        },
        "reportingPeriod": {
          "startDate": {
            
          },
          "endDate": {
            
          }
        },
        "inputVAT": {
          "amount": {
            
          }
        },
        "credit": {
          "amount": {
            
          }
        },
        "iCSOG": {
          "total": {
            
          },
          "transfer": {
            
          },
          "net": {
            
          }
        },
        "parkingRate": {
          
        },
        "sRR": {
          
        },
        "taxFreeSales": {
          
        },
        "standardRate": {
          
        },
        "authority": {
          
        },
        "references": {
          "order": {
            "id": {
              
            }
          }
        },
        "outputVAT": {
          
        },
        "export": {
          "total": {
            
          }
        },
        "totalPaymentDue": {
          
        },
        "interest": {
          "amount": {
            
          }
        },
        "order": {
          "date": {
            
          }
        },
        "net": {
          
        },
        "report": {
          "type": {
            
          }
        },
        "reverseCharge": {
          "net": {
            
          }
        },
        "iCAOG": {
          "total": {
            "net": {
              
            }
          }
        },
        "reducedRate": {
          
        },
        "taxableSales": {
          
        },
        "deductibleTax": {
          
        },
        "importVAT": {
          "amount": {
            
          }
        },
        "edit": {
          
        }
      },
      "de-DE": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut des Zahlungsempfängers"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungszweck"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offen"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlter Betrag"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformation"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende der Zahlfrist"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsreferenz"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn der Zahlfrist"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzsteuersatz"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF erstellt am"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belegnummer"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzsteuer-Identifikationsnummer"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung der Meldedaten"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraum"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumbeginn"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumende"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsteuer"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsteuer aus Rechnungen"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Lieferungen, gesamt"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbringungen"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Lieferungen"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Umsätze"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behörde"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsnummer"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzsteuer"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausfuhr"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausfuhr"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlbetrag"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinsbetrag"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinsen"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragungsdatum"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoumsatz"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtsart"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoumsatz für Umkehrung der Steuerschuldnerschaft (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umkehrung der Steuerschuldnerschaft (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Erwerbe"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe der innergemeinschaftliche Erwerbe"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Erwerbe"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerpflichtige Umsätze"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abziehbare Vorsteuerbeträge"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfuhrumsatzsteuer"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])}
        }
      },
      "de-AT": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut des Zahlungsempfängers"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungszweck"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offen"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlter Betrag"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformation"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende der Zahlfrist"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsreferenz"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn der Zahlfrist"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrwertsteuersatz"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF erstellt am"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belegnummer"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzsteuer-Identifikationsnummer"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung der Meldedaten"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraum"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumbeginn"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumende"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsteuer"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsteuer aus Rechnungen"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Lieferungen, gesamt"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbringungen"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Lieferungen"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Umsätze"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behörde"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsnummer"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzsteuer"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausfuhr"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausfuhr"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlbetrag"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinsbetrag"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinsen"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragungsdatum"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoumsatz"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtsart"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoumsatz für Umkehrung der Steuerschuldnerschaft (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umkehrung der Steuerschuldnerschaft (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Erwerbe"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe der innergemeinschaftliche Erwerbe"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Erwerbe"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerpflichtiger Umsatz"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abziehbare Vorsteuerbeträge"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfuhrumsatzsteuer"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])}
        }
      },
      "de-CH": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut des Zahlungsempfängers"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungszweck"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bezahlt"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offen"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlter Betrag"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformation"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende der Zahlfrist"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsreferenz"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn der Zahlfrist"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrwertsteuersatz"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF erstellt am"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belegnummer"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzsteuer-Identifikationsnummer"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei unserem Service ist ein Fehler aufgetreten. Bitte versuche es erneut."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung der Meldedaten"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraum"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumbeginn"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumende"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsteuer"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsteuer aus Rechnungen"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guthaben"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Lieferungen, gesamt"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbringungen"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Lieferungen"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreie Umsätze"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behörde"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsnummer"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzsteuer"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausfuhr"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausfuhr"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlbetrag"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinsbetrag"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinsen"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragungsdatum"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoumsatz"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtsart"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoumsatz für Umkehrung der Steuerschuldnerschaft (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umkehrung der Steuerschuldnerschaft (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Erwerbe"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe der innergemeinschaftliche Erwerbe"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Erwerbe"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuersatz"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerpflichtiger Umsatz"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abziehbare Vorsteuerbeträge"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfuhrumsatzsteuer"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])}
        }
      },
      "en-US": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institute"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of Seat of the Institut"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payee's Institute"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of Seat of the Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment details"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment details"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount paid"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of the payment period"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instrument"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment reference"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginning of the payment period"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT rate"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF created on"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer number"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax number"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT identification number"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred in our service. Please try again."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of reporting data"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting period"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting start date"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting end date"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input tax"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input tax from invoices"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community supplies, total"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community transfers"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax free deliveries"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax rate"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax rate"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax free sales"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax rate"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authority"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Id"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value-added tax"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment amount"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest amount"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net sales"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report type"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net revenues for reverse charge"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversal of tax liability (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community acquisitions"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of intra-community acquisitions"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community acquisitions"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax rate"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxable sales"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deductible input tax"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import VAT"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
        }
      },
      "en-GB": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institute"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of Seat of the Institut"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payee's Institute"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of Seat of the Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose of transfer"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment details"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment details"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount paid"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of the payment period"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instrument"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment reference"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginning of the payment period"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT rate"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF created on"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer number"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax number"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT identification number"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred in our service. Please try again."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of reporting data"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting period"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting start date"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting end date"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input tax"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input tax from invoices"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community supplies, total"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community transfer"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax free deliveries"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax rate"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax rate"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax-free sales"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax rate"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authority"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Id"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value added tax"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment amount"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest amount"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net sales"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report type"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net revenues for reverse charge"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversal of tax liability (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community acquisitions"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of intra-community acquisitions"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community acquisitions"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax rate"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxable sales"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deductible input tax"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import VAT"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
        }
      },
      "it-IT": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istituto"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese di sede dell'Istituto"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare dell'account"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istituto del beneficiario"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese di sede dell'Istituto"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare dell'account"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzo"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagato"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in sospeso"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i dettagli di pagamento"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i dettagli di pagamento"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo pagato"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul pagamento"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine del periodo di pagamento"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strumento di pagamento"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenza di pagamento"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio del periodo di pagamento"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli di pagamento"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliquota IVA"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF creato in data"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del documento"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero cliente"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["codice fiscale"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di identificazione IVA"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore nel nostro servizio. Per favore riprova."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo dei dati di reporting"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di riferimento"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di inizio della segnalazione"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di fine del rapporto"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta a monte"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta a monte dalle fatture"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forniture intracomunitarie, totale"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento intracomunitario"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegne esenti da imposte"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliquota fiscale"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliquota fiscale"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite esenti da imposte"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliquota fiscale"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorità"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ordine"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esportare"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esportare"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo del pagamento"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo degli interessi"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesse"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dell'ordine"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite nette"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di Rapporto"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporto"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrate nette per inversione contabile (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento dell'obbligo fiscale (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisizione intracomunitaria"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma delle acquisizioni intracomunitarie"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisizione intracomunitaria"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliquota fiscale"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite imponibili"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta a monte deducibile"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importazione IVA"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])}
        }
      },
      "fr-FR": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut du bénéficiaire"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en attente"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant payé"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la période de paiement"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de paiement"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début de la période de paiement"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de paiement"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de TVA"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF créé le"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de document"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de client"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification fiscale"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification TVA"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite dans notre service. Veuillez réessayer."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargements"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé des données de rapport"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de rapport"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début du rapport"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin du rapport"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe d'entrée"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe déductible des factures"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournitures intracommunautaires, total"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert intra-communautaire"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraisons hors taxes"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes hors taxes"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorité"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de commande"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe sur la valeur ajoutée"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportation"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportation"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant des intérêts"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêt"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes nettes"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de rapport"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes nettes pour l'autoliquidation (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inversion de l'obligation fiscale (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition intracommunautaire"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme des acquisitions intracommunautaires"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition intracommunautaire"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes taxables"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe en amont déductible"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de vente à l'importation"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])}
        }
      },
      "es-ES": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de sede del Institut"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario de la cuenta"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto del beneficiario"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de sede del Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario de la cuenta"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagado"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pendiente"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalles de pago"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalles de pago"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad pagada"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del pago"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin del período de pago"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumento de pago"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia de pago"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio del período de pago"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del pago"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipo de IVA"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visión general"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF creado en"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número del Documento"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cliente"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de impuesto"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación de IVA"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha habido un error en nuestro servicio. Inténtelo de nuevo, por favor."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargas"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de los datos de informes"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de información"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio de informes"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización del informe"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto soportado"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto soportado de facturas"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suministros intracomunitarios, total"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia intracomunitaria"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregas libres de impuestos"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de impuesto"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de impuesto"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas libres de impuestos"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de impuesto"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoridad"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar ID"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto al valor agregado"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto del pago"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de interés"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesar"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de orden"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las ventas netas"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Informe"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos netos por cargo inverso (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversión de la obligación tributaria (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adquisición intracomunitaria"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma de adquisiciones intracomunitarias"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adquisición intracomunitaria"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de impuesto"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas gravables"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto soportado deducible"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA de importación"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])}
        }
      },
      "pl-PL": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instytut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj siedziby instytutu"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Właściciel konta"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instytut odbiorcy płatności"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj siedziby instytutu"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Właściciel konta"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stosowanie"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["płatny"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w oczekiwaniu"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj szczegóły płatności"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj szczegóły płatności"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłata zapłacona"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacja o płatności"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec terminu płatności"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda płatności"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł przelewu"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początek okresu płatności"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły płatności"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stawka VAT"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przegląd"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF utworzony dnia"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer dokumentu"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer klienta"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numer identyfikacji podatkowej"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer identyfikacyjny VAT"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W naszym serwisie wystąpił błąd. Prosimy, spróbuj ponownie."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pliki do pobrania"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pobierz PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podsumowanie danych sprawozdawczych"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacja szczegółowa"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres sprawozdawczy"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początek okresu podlegającego zgłoszeniu"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec okresu podlegającego zgłoszeniu"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podatek naliczony"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podatek naliczony z faktur"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota do zwrotu"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota do zwrotu"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawy wewnątrzwspólnotowe, całkowity"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnątrzwspólnotowy przepływ towarów"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpodatkowa dostawa towarów"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stawka"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stawka"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż bezpodatkowa"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stawka"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urząd"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer usługi"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podatek VAT"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość należności"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota odsetek"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zainteresowanie"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data zatwierdzenia danych"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż netto"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ Raportu"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raport"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż z odwrotnym obciążeniem (reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odwrócenie zobowiązania podatkowego (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnątrzwspólnotowe nabycia towarów"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma przejęć wewnątrzwspólnotowych"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnątrzwspólnotowe nabycia towarów"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stawka"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż opodatkowana"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podatek naliczony do odliczenia"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT importowy"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])}
        }
      },
      "nl-NL": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land van zetel van het Institut"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounteigenaar"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het instituut van de begunstigde"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land van zetel van het Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounteigenaar"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betaald"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestand"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aanhangig"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsgegevens bewerken"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsgegevens bewerken"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaald bedrag"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformatie"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde van de betalingsperiode"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalinstrument"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingskenmerk"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin van de betalingsperiode"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsdetails"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW-tarief"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF gemaakt op"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Nummer"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantnummer"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW Nummer"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW-identificatienummer"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden in onze service. Gelieve opnieuw te proberen."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenvatting van rapportagegegevens"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verslagperiode"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum rapportage"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einddatum rapportage"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoerbelasting"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbelasting van facturen"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intracommunautaire leveringen, totaal"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdracht binnen de gemeenschap"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingvrije leveringen"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingtarief"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingtarief"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingvrije verkoop"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingtarief"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezag"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order ID"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belasting over de toegevoegde waarde"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te betalen bedrag"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentebedrag"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesseren"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besteldatum"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto-omzet"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporttype"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto-inkomsten voor verleggingskosten (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terugname van belastingverplichting (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intracommunautaire verwervingen"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som van intracommunautaire acquisities"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intracommunautaire verwervingen"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingtarief"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastbare verkopen"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aftrekbare voorbelasting"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omzetbelasting importeren"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])}
        }
      },
      "bg-BG": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Институт"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Държава на седалището на Института"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cобственик на акаунт"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Институтът на бенефициента"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Държава на седалището на Института"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cобственик на акаунт"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Употреба"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["платен"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cтатус"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["висящ"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на данните за плащане"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на данните за плащане"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платено количество"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация за плащане"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок за плащане на ДДС"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежно нареждане"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справка за плащане"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало на периода на плащане"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробности на плащане"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка на ДДС"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF, създаден на"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер на документ"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиентски номер"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["данъчен номер"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификационен номер по ДДС"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Възникна грешка в нашата услуга. Моля, опитайте отново."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтегляния"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтеглете PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обобщение на отчетни данни"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["детайли"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчетен период"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчитане на начална дата"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крайна дата на отчитане"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Входен данък"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Входен данък от фактури"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредит"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредит"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вътрешнообщностни доставки, обща сума"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вътрешнообщностен трансфер"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без такси доставки"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данъчна ставка"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данъчна ставка"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без данъци"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данъчна ставка"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["власт"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идент. № на поръчката"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["данък върху добавената стойност"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["износ"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["износ"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума за плащане"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума на лихвите"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лихва"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на поръчка"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нетните продажби"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип Oтчет"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доклад"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нетни приходи за обратна такса (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отмяна на данъчно задължение (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Придобивания в рамките на Общността"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума на вътреобщностните придобивания"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Придобивания в рамките на Общността"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данъчна ставка"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Облагаеми продажби"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приспадаем входен данък"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данък върху продажбите върху вноса"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране"])}
        }
      },
      "bs-BA": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja sjedišta Instituta"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlasnik računa"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut primatelja"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja sjedišta Instituta"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlasnik računa"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upotreba"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaćeni"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statusa"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na čekanju"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređivanje podataka o plaćanju"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređivanje podataka o plaćanju"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznos plaćen"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o plaćanju"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj perioda plaćanja"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument plaćanja"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenca o uplati"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početak perioda plaćanja"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalji plaćanja"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopa PDV-a"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF kreiran na"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj dokumenta"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj kupca"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["porezni broj"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDV broj"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Došlo je do greške u našem servisu. Molimo pokušajte ponovo."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzimanja"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmite PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sažetak podataka izvještavanja"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalji"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveštajni period"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum početka izvještavanja"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajnji datum izvještavanja"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulazni porez"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulazni porez iz računa"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opskrba unutar zajednice, ukupno"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenos unutar zajednice"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isporuke bez poreza"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopa poreza"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopa poreza"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodaja bez poreza"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopa poreza"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoritet"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id narudžbe"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porez na dodanu vrijednost"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvoz"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvoz"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznos plaćanja"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznos kamate"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamata"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narudžbe"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neto prihoda od prodaje"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta Izvještaja"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvještaj"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neto prihod za obrnuto trošak (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukidanje poreske obaveze (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akvizicije unutar zajednice"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbir akvizicija unutar zajednice"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akvizicije unutar zajednice"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopa poreza"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oporeziva prodaja"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbitni pretporez"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvezi PDV"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi"])}
        }
      },
      "da-DK": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut for sæde"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoindehaver"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsmodtagerens institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut for sæde"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoindehaver"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvendelse"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betalt"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verserende"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger betalingsoplysninger"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger betalingsoplysninger"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalt beløb"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformation"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsperiodens slutning"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinstrument"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalings reference"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begyndelsen af betalingsperioden"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsoplysninger"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momssats"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF oprettet den"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument Nummer"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenummer"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattenummer"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moms-identifikationsnummer"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en fejl i vores service. Prøv igen."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumé af rapporteringsdata"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporteringsperiode"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporterings startdato"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporterings slutdato"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input skat"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indgående skat fra fakturaer"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsyninger inden for samfundet, Total"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community overførsel"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattefrie leveringer"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatteprocent"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatteprocent"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattefrit salg"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatteprocent"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myndighed"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre ID"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merværdiafgift"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsbeløb"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rente beløb"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesse"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestillingsdato"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettosalg"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporttype"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoomsætning til omvendt gebyr (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbageførsel af skattepligt (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhvervelser inden for Fællesskabet"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summen af erhvervelser inden for samfundet"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhvervelser inden for Fællesskabet"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatteprocent"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattepligtigt salg"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fradragsberettiget indgående skat"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import moms"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])}
        }
      },
      "et-EE": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituudi asukohariik"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto omanik"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saaja instituut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituudi asukohariik"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto omanik"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutamine"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["makstud"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staatus"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ootel"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutke makse üksikasju"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutke makse üksikasju"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makstud summa"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseteave"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseperioodi lõpp"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksevahend"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseviide"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseperioodi algus"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse andmed"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käibemaksumäär"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülevaade"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF loodud"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumendi number"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi number"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maksunumber"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käibemaksukohustuslase registreerimisnumber"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meie teenuses on ilmnenud tõrge. Palun proovi uuesti."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allalaadimised"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadige alla PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruandlusandmete kokkuvõte"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üksikasjad"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruandeperiood"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruande alguskuupäev"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruande lõppkuupäev"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisendmaks"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisendmaks arvetelt"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krediit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krediit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühendusesisesed tarned, kokku"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogukonnasisene ülekandmine"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuvabad tarned"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksumäär"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksumäär"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuvaba müük"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksumäär"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriteet"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuse ID"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käibemaks"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse summa"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intressi summa"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huvi"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuse kuupäev"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügitulu"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruande Tüüp"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruanne"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pöördmaksustamise puhastulu (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksukohustuse tühistamine (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühendusesisesed omandamised"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühendusesiseste omandamiste summa"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühendusesisesed omandamised"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksumäär"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksustatav müük"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahaarvatav sisendkäibemaks"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impordi käibemaks"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigeeri"])}
        }
      },
      "fi-FI": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituutti"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituutin kotimaa"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin omistaja"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksunsaajan instituutti"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituutin kotimaa"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin omistaja"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttö"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maksettu"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odotettaessa"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa maksutietoja"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa maksutietoja"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksettu summa"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietoja maksusta"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuajan päättyminen"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksutapa"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuviite"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuajan alku"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksutiedot"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALV"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiskatsaus"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF luotu"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakirjan numero"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakasnumero"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veronumero"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALV-tunnistenumero"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelussamme on tapahtunut virhe. Olkaa hyvä, ja yrittäkää uudelleen."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataukset"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteenveto raportointitiedoista"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityiskohdat"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportointikausi"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportoinnin alkamispäivä"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportoinnin lopetuspäivä"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostoihin sisältyvä vero"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskujen vero"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luotto"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luotto"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteisön sisäiset tarvikkeet, kaikki yhteensä"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteisön sisäinen siirto"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veroton toimitus"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veroaste"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veroaste"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verovapaa myynti"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veroaste"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auktoriteetti"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilausnumero"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvonlisävero"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viedä"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viedä"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksun määrä"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korkomäärä"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinnostuksen kohde"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilauspäivämäärä"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liikevaihdosta"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporttityyppi"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportti"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käänteisen verotuksen nettotuotot (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verovelvollisuuden peruuttaminen (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteisön sisäiset hankinnat"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteisön sisäisten yritysostojen summa"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteisön sisäiset hankinnat"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veroaste"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verollinen myynti"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vähennyskelpoinen ostoihin sisältyvä vero"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuo myyntivero"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta"])}
        }
      },
      "el-GR": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ινστιτούτο"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα έδρας του Ινστιτούτου"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ιδιοκτήτης λογαριασμού"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ινστιτούτο δικαιούχου πληρωμής"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα έδρας του Ινστιτούτου"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ιδιοκτήτης λογαριασμού"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήση"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επί πληρωμή"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κατάσταση"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εκκρεμής"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στοιχείων πληρωμής"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στοιχείων πληρωμής"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό που καταβάλλεται"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες Πληρωμής"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήξη της περιόδου πληρωμής"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσο πληρωμής"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά πληρωμής"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναρξη της περιόδου πληρωμής"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι λεπτομέρειες πληρωμής"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συντελεστή ΦΠΑ"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το PDF δημιουργήθηκε στις"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός Εγγράφου"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός των πελατών"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΑΦΜ"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός αναγνώρισης ΦΠΑ"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρουσιάστηκε σφάλμα στην υπηρεσία μας. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψεις"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περίληψη των δεδομένων αναφοράς"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέριες"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περίοδος αναφοράς"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία έναρξης αναφοράς"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία λήξης αναφοράς"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρος εισροών"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρος εισροών από τιμολόγια"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίστωση"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίστωση"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδοκοινοτικές προμήθειες, σύνολο"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδοκοινοτική μεταφορά"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφορολόγητες παραδόσεις"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορολογικός συντελεστής"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορολογικός συντελεστής"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφορολόγητες πωλήσεις"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορολογικός συντελεστής"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εξουσία"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός Παραγγελίας"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρος Προστιθέμενης Αξίας"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εξαγωγή"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εξαγωγή"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό ΠΛΗΡΩΜΗΣ"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό τόκου"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδιαφέρον"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία παραγγελίας"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθαρές πωλήσεις"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος Aναφοράς"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανω ΑΝΑΦΟΡΑ"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθαρά έσοδα για αντίστροφη χρέωση (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αντιστροφή της φορολογικής υποχρέωσης (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδοκοινοτικές εξαγορές"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άθροισμα ενδοκοινοτικών εξαγορών"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδοκοινοτικές εξαγορές"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορολογικός συντελεστής"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορολογητέες πωλήσεις"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπεστέος φόρος εισροών"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγωγή ΦΠΑ"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])}
        }
      },
      "he-IL": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכון"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ארץ מושב המכון"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בעל החשבון"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכון מקבל התשלום"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ארץ מושב המכון"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בעל החשבון"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נוֹהָג"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שולם"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סטָטוּס"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ממתין ל"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערוך את פרטי התשלום"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערוך את פרטי התשלום"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סכום ששולם"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מידע על התשלום"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוף תקופת התשלום"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכשיר תשלום"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הפניה לתשלום"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תחילת תקופת התשלום"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פרטי תשלום"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שיעור מע＂מ"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סקירה כללית"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF נוצר ב-"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מספר מסמך"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מספר לקוח"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מספר מס"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מספר זיהוי מע＂מ"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אירעה שגיאה בשירות שלנו. בבקשה נסה שוב."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הורדות"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הורד PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סיכום נתוני הדיווח"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פרטים"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תקופת הדיווח"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מדווח על תאריך התחלה"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך סיום מדווח"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מס תשומות"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מס תשומות מחשבוניות"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אַשׁרַאי"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אַשׁרַאי"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אספקה פנים-קהילתית, סה＂כ"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["העברה פנים-קהילתית"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["משלוחים ללא מס"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שיעור מס"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שיעור מס"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכירות ללא מס"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שיעור מס"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רְשׁוּת"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מספר הזמנה"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מס ערך מוסף"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יְצוּא"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יְצוּא"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סכום לתשלום"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סכום ריבית"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ריבית"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך הזמנה"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכירות רשת"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוג דוח"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["להגיש תלונה"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הכנסות נטו לחיוב הפוך (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Reverse charge) ביטול חבות המס"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רכישות פנים-קהילתיות"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סכום הרכישות התוך-קהילתיות"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רכישות פנים-קהילתיות"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שיעור מס"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכירות חייבות במס"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מס תשומות לניכוי"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יבוא מע＂מ"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערוך"])}
        }
      },
      "ga-IE": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institiúid"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tír Suíochán an Institut"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úinéir an chuntais"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institiúid an íocaí"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tír Suíochán an Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úinéir an chuntais"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úsáid"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["íoctha"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stádas"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar feitheamh"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir sonraí íocaíochta in eagar"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir sonraí íocaíochta in eagar"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méid a d'íoc"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faisnéis íocaíochta"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deireadh na tréimhse íocaíochta"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lonstraim íocaíochta"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagairt íocaíochta"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tús na tréimhse íocaíochta"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraí íocaíochta"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ráta CBL"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbhreathnú"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF cruthaithe ar"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uimhir an doiciméid"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uimhir an chustaiméara"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uimhir chánach"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uimhir aitheantais CBL"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tharla earráid inár seirbhís. Arís, le d'thoil."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Íoslódálacha"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Íoslódáil PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achoimre ar na sonraí tuairiscithe"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraí"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tréimhse tuairiscithe"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta tosaigh tuairiscithe"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta deiridh tuairiscithe"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cáin ionchuir"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cáin ionchuir ó shonraisc"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creidmheas"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creidmheas"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soláthairtí laistigh den phobal, Iomlán"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aistriú laistigh den phobal"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seachadtaí saor ó cháin"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ráta cánach"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ráta cánach"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díolacháin saor ó cháin"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ráta cánach"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údarás"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordú Id"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cáin bhreisluacha"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnmhairiú"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnmhairiú"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méid íocaíochta"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méid úis"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leas"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta ordaithe"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glan-dhíolacháin"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cineál Tuairisc"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuarascáil"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glanioncam le haghaidh muirear droim ar ais (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dliteanas cánach a aisiompú (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éadálacha laistigh den Chomhphobal"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suim na n-éadálacha laistigh den phobal"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éadálacha laistigh den Chomhphobal"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ráta cánach"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díolacháin inchánach"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cáin ionchuir inasbhainte"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cáin díolacháin ar allmhairí"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir in eagar"])}
        }
      },
      "ja-JP": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所の座席の国"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの所有者"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受取人の研究所"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所の座席の国"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの所有者"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用法"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払った"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未確定"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いの詳細を編集"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いの詳細を編集"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["払込金額"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払情報"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い期間の終了"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いリファレンス"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い期間の開始"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払詳細"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT率"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概観"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["で作成されたPDF"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["書類番号"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客番号"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税番号"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT識別番号"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスでエラーが発生しました。 もう一度お試しください。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルのダウンロード"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDFをダウンロード"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポートデータの要約"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["細部"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報告期間"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポート開始日"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポート終了日"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投入税"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求書からの仮払消費税"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジット"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジット"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニティ内供給、 合計"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニティ内転送"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免税配送"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税率"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税率"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免税販売"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税率"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文ID"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付加価値税"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["書き出す"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["書き出す"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払金額"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利息額"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["興味"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文日"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["純売上高"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポートタイプ"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報告する"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リバースチャージの純収益 (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["納税義務の反転 (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニティ内の買収"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニティ内買収の合計"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニティ内の買収"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税率"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課税売上"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["控除可能な仮払消費税"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VATのインポート"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])}
        }
      },
      "hr-HR": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja sjedišta Instituta"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlasnik računa"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut primatelja"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja sjedišta Instituta"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlasnik računa"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upotreba"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaćen"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neriješen"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređivanje podataka o plaćanju"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređivanje podataka o plaćanju"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uplaćeni iznos"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o plaćanju"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj razdoblja plaćanja"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument plaćanja"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenca o plaćanju"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početak razdoblja plaćanja"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojedinosti o plaćanju"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopa PDV-a"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF stvoren dana"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj dokumenta"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj kupca"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porezni broj"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDV identifikacijski broj"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Došlo je do pogreške u našoj usluzi. Molim vas pokušajte ponovno."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzimanja"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmite PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sažetak izvještajnih podataka"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalji"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvještajno razdoblje"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum početka izvještavanja"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajnji datum izvještavanja"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulazni porez"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulazni porez iz računa"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditna"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditna"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opskrba unutar zajednice, ukupno"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijenos unutar zajednice"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostava bez poreza"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porezna stopa"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porezna stopa"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodaja bez poreza"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porezna stopa"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlast"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id narudžbe"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porezna dodanu vrijednost"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvoz"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvoz"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznos plaćanja"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznos kamate"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interes"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narudžbe"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neto prodaja"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta Izvješća"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvješće"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neto prihod za obrnuto naplaćivanje (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukidanje porezne obveze (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akvizicije unutar Zajednice"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbir akvizicija unutar zajednice"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akvizicije unutar Zajednice"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porezna stopa"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oporeziva prodaja"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbitni pretporez"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porez na promet"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urediti"])}
        }
      },
      "lv-LV": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institūts"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institūta galvenā mītnes valsts"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta īpašnieks"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma saņēmēja institūts"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institūta galvenā mītnes valsts"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta īpašnieks"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietošana"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samaksāts"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statusu"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gaida"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt maksājuma informāciju"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt maksājuma informāciju"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samaksātā summa"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informācija par maksājumu"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma termiņa beigas"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksāšanas līdzeklis"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma atsauce"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma perioda sākums"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājumu informācija"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVN likme"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskats"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF izveidots"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenta numurs"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klienta numurs"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nodokļu numurs"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVN identifikācijas numurs"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mūsu pakalpojumā ir radusies kļūda. Lūdzu, mēģiniet vēlreiz."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejupielādes"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejupielādējiet PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskatu datu kopsavilkums"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sīkāka informācija"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskata periods"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņošanas sākuma datums"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskata beigu datums"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priekšnodoklis"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priekšnodoklis no rēķiniem"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredīts"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredīts"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krājumi Kopienas iekšienē, Kopā"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārvietošana Kopienas iekšienē"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piegādes bez nodokļiem"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodokļa likme"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodokļa likme"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beznodokļu pārdošana"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodokļa likme"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoritāte"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasūtījuma ID"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienotās vērtības nodoklis"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportēt"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportēt"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma summa"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procentu summa"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interese"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasūtījuma datums"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neto apgrozījums"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskata Veids"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neto ieņēmumi par apgriezto maksāšanu (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodokļu saistības atcelšana (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legāde Kopienas iekšienē"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopienas iekšējo iegāžu summa"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legāde Kopienas iekšienē"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodokļa likme"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar nodokli apliekama pārdošana"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atskaitāms priekšnodoklis"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa PVN"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt"])}
        }
      },
      "lt-LT": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutas"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto buveinės šalis"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paskyros savininkas"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gavėjo institutas"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto buveinės šalis"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paskyros savininkas"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naudojimas"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mokama"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statusas"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kol"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redaguoti mokėjimo informaciją"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redaguoti mokėjimo informaciją"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumokėta suma"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo informacija"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo laikotarpio pabaiga"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo priemonė"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo tvarka"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo laikotarpio pradžia"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo detalės"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVM tarifas"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apžvalga"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF sukurta"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumento numeris"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliento numeris"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokesčių numeris"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVM mokėtojo kodas"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mūsų tarnyboje įvyko klaida. Bandykite dar kartą."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsisiuntimai"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsisiųsti PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitų duomenų santrauka"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išsami informacija"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitinis laikotarpis"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitų teikimo pradžios data"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitos pabaigos data"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkimo mokestis"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkimo mokestis iš sąskaitų faktūrų"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditas"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditas"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsargos bendruomenės viduje, viso"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perkėlimas bendruomenės viduje"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemokamas pristatymas"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokesčio tarifas"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokesčio tarifas"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neapmokestinami pardavimai"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokesčio tarifas"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoritetas"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo ID"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėtinės vertės mokestis"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportuoti"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportuoti"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo suma"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palūkanų suma"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palūkanų"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo data"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grynieji pardavimai"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitos Tipas"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaita"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grynosios pajamos už atvirkštinį apmokestinimą (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokestinės prievolės panaikinimas (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įsigijimai Bendrijos viduje"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bendrovės vidaus įsigijimų suma"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įsigijimai Bendrijos viduje"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokesčio tarifas"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmokestinamas pardavimas"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atskaitytinas pirkimo mokestis"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo pardavimo mokestis"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redaguoti"])}
        }
      },
      "pt-PT": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de Sede do Institut"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dono da conta"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto do beneficiário"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de Sede do Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dono da conta"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pago"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pendente"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalhes do pagamento"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalhes do pagamento"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia paga"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação de pagamento"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim do período de pagamento"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumento de pagamento"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referência de pagamento"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início do período de pagamento"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes do pagamento"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taxa do IVA"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF criado em"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do documento"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do cliente"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número de identificação fiscal"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificação VAT"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro no nosso serviço. Tente novamente."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferências"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo dos dados de relatório"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período coberto pelo relatório"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de início do relatório"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de término do relatório"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA pago"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserção de impostos de faturas"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédito"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimentos intracomunitários, total"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência intracomunitária"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregas isentas de impostos"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de imposto"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de imposto"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendas isentas de impostos"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de imposto"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoridade"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id do pedido"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto sobre o Valor Acrescentado"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor do pagamento"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante de juros"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesse"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data do pedido"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendas líquidas"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Relatório"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatório"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receita líquida de cobrança reversa (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversão de passivo fiscal (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquisições intracomunitárias"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soma das aquisições intracomunitárias"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquisições intracomunitárias"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de imposto"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendas tributáveis"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insumos fiscais dedutíveis"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA de importação"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])}
        }
      },
      "ro-RO": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Țara de reședință a Institutului"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietarul contului"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutul beneficiarului"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Țara de reședință a Institutului"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietarul contului"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizare"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plătit"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in asteptarea"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editați detaliile de plată"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editați detaliile de plată"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma plătită"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatii de plata"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfârșitul perioadei de plată"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de plată"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referință de plată"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Începutul perioadei de plată"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaliile platii"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cota TVA"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezentare generală"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF creat pe"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numarul documentului"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numarul clientului"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cod fiscal"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate numerele de identificare a impozitului pe vânzări"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A apărut o eroare în serviciu. Încercați din nou."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descărcări"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarcă PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezumatul datelor de raportare"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalii"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioadă de raportare"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data începerii raportării"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de încheiere a raportării"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de intrare"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de intrare din facturi"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furnizare intracomunitară, total"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferul intra-comunitar"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livrări fără taxe"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cota de impozitare"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cota de impozitare"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vânzări fără taxe"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cota de impozitare"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoritate"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comanda ID"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa pe valoare adaugata"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma de plată"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma dobânzii"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interes"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data comandă"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vânzări nete"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip de Raport"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raport"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venit net pentru taxa inversă (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inversarea obligației fiscale (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achiziții intracomunitare"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma achizițiilor intracomunitare"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achiziții intracomunitare"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cota de impozitare"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vânzări impozabile"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxă de intrare deductibilă"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import TVA"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editare"])}
        }
      },
      "ru-RU": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Институт"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна местонахождения института"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["владелец счета"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Институт получателя платежей"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна местонахождения института"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["владелец счета"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["использование"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оплаченный"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["положение дел"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в ожидании"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить платежные реквизиты"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить платежные реквизиты"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплаченная сумма"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежная информация"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец платежного периода"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежный инструмент"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на платеж"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало платежного периода"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали оплаты"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка НДС"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обзор"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF создан на"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер счёта"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер клиента"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификационный номер налогоплательщика"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификационный номер плательщика НДС"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка в нашем сервисе. Пожалуйста, попробуйте еще раз."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузки"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резюме отчетных данных"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подробности"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчетный период"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала периода отчётности"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания периода отчётности"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный налог"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный налог из счетов-фактур"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положительное сальдо"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положительное сальдо"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутриобщественные поставки, общее количество"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Передача внутри сообщества"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безналоговые поставки"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка налога"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка налога"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки не облагаемые налогом"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка налога"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["учреждение"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер заказа"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["налог на добавленную стоимость"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["экспорт"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["экспорт"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма к оплате"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма процентов"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интерес"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата предоставления данных"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чистые продажи"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид отчёта"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oтчет"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чистый доход за обратный платеж (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["восстановление налогового обязательства (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["приобретение в пределах сообщества (ЕС)"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма приобретений внутри сообщества"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["приобретение в пределах сообщества (ЕС)"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка налога"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки, облагаемые налогом"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вычитаемый предварительный налог"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налог с продаж на импорт"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])}
        }
      },
      "sv-SE": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inleda"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutets säte"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto ägare"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsmottagarens institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutets säte"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto ägare"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användande"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betald"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i väntan på"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera betalningsinformation"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera betalningsinformation"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalt belopp"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information om betalning"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsperiodens slut"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsinstrument"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsreferens"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Början på betalningsperioden"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsinformation"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattesats"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översikt"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF skapad den"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentnummer"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundnummer"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skattenummer"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momsnummer"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett fel har uppstått i vår tjänst. Var god försök igen."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedladdningar"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda ner PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammanfattning av rapporteringsdata"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporteringsperiod"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporteringsstartdatum"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporteringens slutdatum"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingående skatt"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingående skatt från fakturor"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditera"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditera"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbehör inom gemenskapen, total"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Överföring inom gemenskapen"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattefria leveranser"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattenivå"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattenivå"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattefri försäljning"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattenivå"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auktoritet"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beställnings-id"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mervärdesskatt"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsbelopp"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Räntebelopp"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intressera"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderdatum"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettointäkter"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporttyp"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapportera"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoomsättning för omvänd avgift (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återföring av skatteskuld (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förvärv inom gemenskapen"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summan av förvärv inom samhället"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förvärv inom gemenskapen"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattenivå"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skattepliktig försäljning"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avdragsgill ingående skatt"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importera moms"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera"])}
        }
      },
      "sk-SK": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ústav"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina sídla inštitútu"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník účtu"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inštitút príjemcu platby"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina sídla inštitútu"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník účtu"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používanie"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaplatil"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavenie"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nevykonaného"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravte podrobnosti platby"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravte podrobnosti platby"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka vyplatená"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie o platbe"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec platobného obdobia"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platobný nástroj"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označenie platby"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začiatok platobného obdobia"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platobné údaje"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadzba DPH"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľad"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF vytvorené dňa"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo dokumentu"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákaznícke číslo"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daňové číslo"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IČ DPH"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V našej službe sa vyskytla chyba. Prosím skúste znova."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K stiahnutiu"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnite si PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súhrn vykazovaných údajov"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vykazované obdobie"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum začiatku vykazovania"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum ukončenia prehľadu"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daň na vstupe"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daň na vstupe z faktúr"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úver"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úver"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodávky v rámci Spoločenstva, Celkom"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenos v rámci Spoločenstva"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodávky bez dane"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadzba dane"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadzba dane"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj bez dane"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadzba dane"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorita"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo objednávky"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daň z pridanej hodnoty"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka na úhradu"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výška úroku"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úrok"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum objednávky"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čisté tržby"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ Hlásenia"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čistý výnos z prenesenia daňovej povinnosti (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušenie daňovej povinnosti (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadobudnutie v rámci Spoločenstva"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súčet akvizícií v rámci Spoločenstva"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadobudnutie v rámci Spoločenstva"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadzba dane"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdaniteľný predaj"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpočítateľná daň na vstupe"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dovozná daň z obratu"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť"])}
        }
      },
      "cs-CZ": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ústav"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země sídla institutu"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majitel účtu"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut příjemce platby"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země sídla institutu"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majitel účtu"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používání"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaplaceno"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavení"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čekající"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit platební údaje"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit platební údaje"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka vyplacená"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o platbě"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konec platebního období"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platební nástroj"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na platbu"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začátek platebního období"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platební údaje"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sazba DPH"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF vytvořeno dne"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo dokumentu"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zákaznické číslo"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daňové číslo"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIČ"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V naší službě se vyskytla chyba. Zkuste to prosím znovu."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubory ke stažení"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhnout PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhrn údajů z hlášení"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba ohlášení"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum zahájení hlášení"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum ukončení vykazování"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daň na vstupu"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daň na vstupu z faktur"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zásoby uvnitř Společenství, celkový"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přenos v rámci Společenství"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodávky bez daně"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sazba daně"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sazba daně"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodej bez daně"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sazba daně"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoritet"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo objednávky"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daň z přidané hodnoty"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vývozní"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vývozní"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka k úhradě"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výše úroku"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zájem"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum objednávky"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čisté tržby"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ Zprávy"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpráva"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čistý výnos z převodu (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení daňové povinnosti (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akvizice uvnitř Společenství"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Součet akvizic uvnitř Společenství"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akvizice uvnitř Společenství"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sazba daně"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdanitelný prodej"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpočitatelná daň na vstupu"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dovozní DPH"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])}
        }
      },
      "tr-TR": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitü"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitünün Bulunduğu Ülke"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap sahibi"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alacaklı enstitüsü"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitünün Bulunduğu Ülke"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap sahibi"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ödenmiş"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["askıda olan"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını düzenleme"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını düzenleme"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödenen miktar"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme bilgileri"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme süresinin sonu"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme aracı"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme referansı"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme döneminin başlangıcı"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme detayları"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KDV oranı"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakış"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF tarihinde oluşturuldu"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge Numarası"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri numarası"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi numarası"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT kimlik numarası"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmetimizde bir hata oluştu. Lütfen tekrar deneyin."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndirilenler"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF İndir"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama verilerinin özeti"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayrıntılar"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama dönemi"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama başlangıç tarihi"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama bitiş tarihi"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdi vergisi"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturalardan giriş vergisi"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi malzemeler, Toplam"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi aktarım"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergisiz teslimatlar"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi oranı"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi oranı"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergisiz satışlar"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi oranı"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş Kimliği"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["katma değer Vergisi"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihracat"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihracat"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme miktarı"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faiz tutarı"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faiz"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş tarihi"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net satış"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Türü"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ters ibraz için net gelir (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi borcunun iptali (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi edinimler"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi satın almaların toplamı"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi edinimler"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi oranı"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergiye tabi satışlar"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndirilebilir girdi vergisi"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İthalat satış vergisi"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])}
        }
      },
      "hu-HU": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intézet"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az intézet székhelye"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlatulajdonos"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kedvezményezett intézmény"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az intézet székhelye"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlatulajdonos"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használat"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fizetett"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állapot"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["függőben levő"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési adatok szerkesztése"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési adatok szerkesztése"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kifizetett összeg"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési információ"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési időszak vége"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés módja"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési referencia"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési időszak kezdete"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési részletek"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áfakulcs"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áttekintés"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF létrehozva"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentum szám"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyfélszám"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adószám"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HÉA-azonosító szám"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba történt a szolgáltatásunkban. Próbálja meg újra."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letöltések"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse le a PDF fájlt"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelentési adatok összefoglalása"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részletek"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentési időszak"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentés kezdete"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentés befejezési dátuma"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input adó"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adószámla számlákból"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitel"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hitel"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közösségen belüli ellátás, teljes"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közösségen belüli transzfer"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adómentes szállítás"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adókulcs"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adókulcs"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adómentes értékesítés"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adókulcs"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állami hatóság"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendelés azonosító"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áfa"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végösszeg"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamatösszeg"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érdeklődés"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendelés dátuma"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettó árbevétele"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentés Típusa"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentés"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettó bevétel fordított adózás céljából (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az adófizetési kötelezettség megfordítása (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közösségen belüli beszerzések"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A közösségen belüli beszerzések összege"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közösségen belüli beszerzések"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adókulcs"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adóköteles értékesítés"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levonható előzetes adó"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import forgalmi adó"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkeszt"])}
        }
      },
      "fr-BE": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut du bénéficiaire"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But du transfert"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en attente"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant payé"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la période de paiement"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de paiement"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début de la période de paiement"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de paiement"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de TVA"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF créé le"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de document"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de client"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification fiscale"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification TVA"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite dans notre service. Veuillez réessayer."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargements"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé des données de rapport"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de rapport"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début du rapport"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin du rapport"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe d'entrée"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe déductible des factures"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournitures intracommunautaires, total"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert intra-communautaire"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraisons hors taxes"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes hors taxes"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorité"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de commande"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe sur la valeur ajoutée"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportation"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportation"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant des intérêts"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêt"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes nettes"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de rapport"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes nettes pour l'autoliquidation (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inversion de l'obligation fiscale (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition intracommunautaire"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme des acquisitions intracommunautaires"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition intracommunautaire"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes taxables"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe en amont déductible"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de vente à l'importation"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])}
        }
      },
      "fr-LU": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut du bénéficiaire"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But du transfert"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en attente"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant payé"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la période de paiement"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de paiement"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début de la période de paiement"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de paiement"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de TVA"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF créé le"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de document"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de client"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification fiscale"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification TVA"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite dans notre service. Veuillez réessayer."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargements"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé des données de rapport"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de rapport"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début du rapport"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin du rapport"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe d'entrée"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe déductible des factures"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournitures intracommunautaires, total"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert intra-communautaire"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraisons hors taxes"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes hors taxes"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorité"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de commande"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe sur la valeur ajoutée"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportation"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportation"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant des intérêts"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêt"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes nettes"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de rapport"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recettes nettes pour l'autoliquidation (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inversion de l'obligation fiscale (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition intracommunautaire"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somme des acquisitions intracommunautaires"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition intracommunautaire"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'imposition"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes taxables"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe en amont déductible"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe de vente à l'importation"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])}
        }
      },
      "mt-MT": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istitut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pajjiż tas-Sede tal-Istitut"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sid tal-kont"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L-istitut ta 'Payee"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pajjiż tas-Sede tal-Istitut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sid tal-kont"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użu"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imħallas"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pendenti"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editja d-dettalji tal-ħlas"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editja d-dettalji tal-ħlas"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammont imħallas"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazzjoni dwar il-ħlas"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tmiem il-perjodu tal-ħlas"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strument tal-ħlas"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenza tal-ħlas"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidu tal-perjodu tal-ħlas"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettalji taż-Żahlungs"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata tal-VAT"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ħarsa ġenerali"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF maħluq fuq"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numru tad-dokument"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numru tal-klijent"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numru Tat-Taxxa"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numru ta 'identifikazzjoni tal-VAT"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seħħ żball fis-servizz tagħna. Jekk jogħġbok erġa' pprova."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tniżżil"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tniżżel PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommarju tad-dejta tar-rappurtar"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettalji"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perjodu ta 'Rappurtar"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidu tal-perjodu tar-rappurtar"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tmiem tal-perjodu tar-rappurtar"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxxa fuq l-input"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxxa fuq l-input mill-fatturi"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditu"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditu"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunsinni intra-komunitarji, totali"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferiment intra-komunitarju"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunsinni bla taxxa"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata tat-taxxa"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata tat-taxxa"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejgħ bla taxxa"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata tat-taxxa"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awtorità"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id tal-Ordni"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxxa fuq il-valur miżjud"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esportazzjoni"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esportazzjoni"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammont tal-ħlas"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammont ta 'imgħax"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interess"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data tal-ordni"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejgħ nett"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip ta 'Rapport"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejgħ nett għal reverse charge"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treġġigħ lura ta 'obbligazzjoni tat-taxxa"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkwisti intra-komunitarji"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma ta 'akkwisti intra-komunitarji"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkwisti intra-komunitarji"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata tat-taxxa"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejgħ taxxabbli"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxxa fuq l-input deduċibbli"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT tal-importazzjoni"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editja"])}
        }
      },
      "sl-SI": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inštitut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država sedeža Inštituta"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastnik računa"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inštitut prejemnika plačila"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država sedeža Inštituta"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastnik računa"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uporaba"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plačan"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanje"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v teku"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredite podatke o plačilu"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredite podatke o plačilu"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačani znesek"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o plačilu"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konec plačilnega obdobja"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačilni instrument"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklic na plačilo"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začetek plačilnega obdobja"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podatki o plačilu"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stopnja DDV"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF, ustvarjen dne"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Številka dokumenta"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Številka kupca"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davčna številka"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikacijska številka za DDV"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prišlo je do napake v naši storitvi. Poskusite ponovno."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenosi"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenesite PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povzetek podatkov poročanja"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdobje poročanja"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum začetka poročanja"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Končni datum poročanja"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vhodni davek"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vhodni davek iz računov"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrebščine znotraj skupnosti, skupaj"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenos znotraj skupnosti"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brez davkov"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davčna stopnja"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davčna stopnja"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodaja brez davkov"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davčna stopnja"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avtoriteto"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Številka naročila"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["davek na dodano vrednost"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvoz"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvoz"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znesek plačila"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znesek obresti"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obresti"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum naročila"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čisti prihodki od prodaje"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta Poročila"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poročilo"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čisti prihodek za povratno bremenitev (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razveljavitev davčne obveznosti (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridobitve znotraj Skupnosti"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vsota pridobitev znotraj skupnosti"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridobitve znotraj Skupnosti"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davčna stopnja"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdavčljiva prodaja"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbitni vstopni davek"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvozni prometni davek"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi"])}
        }
      },
      "el-CY": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ινστιτούτο"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα έδρας του Ινστιτούτου"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ιδιοκτήτης λογαριασμού"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ινστιτούτο δικαιούχου πληρωμής"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα έδρας του Ινστιτούτου"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ιδιοκτήτης λογαριασμού"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σκοπός μεταφοράς"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επί πληρωμή"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κατάσταση"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εκκρεμής"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στοιχείων πληρωμής"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στοιχείων πληρωμής"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό που καταβάλλεται"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες Πληρωμής"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήξη της περιόδου πληρωμής"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσο πληρωμής"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά πληρωμής"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναρξη της περιόδου πληρωμής"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι λεπτομέρειες πληρωμής"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συντελεστή ΦΠΑ"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το PDF δημιουργήθηκε στις"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός Εγγράφου"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός των πελατών"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΑΦΜ"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός αναγνώρισης ΦΠΑ"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρουσιάστηκε σφάλμα στην υπηρεσία μας. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψεις"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περίληψη των δεδομένων αναφοράς"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέριες"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περίοδος αναφοράς"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία έναρξης αναφοράς"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία λήξης αναφοράς"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρος εισροών"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρος εισροών από τιμολόγια"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίστωση"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίστωση"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδοκοινοτικές προμήθειες, σύνολο"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδοκοινοτική μεταφορά"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφορολόγητες παραδόσεις"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορολογικός συντελεστής"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορολογικός συντελεστής"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφορολόγητες πωλήσεις"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορολογικός συντελεστής"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εξουσία"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός Παραγγελίας"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρος Προστιθέμενης Αξίας"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εξαγωγή"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εξαγωγή"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό ΠΛΗΡΩΜΗΣ"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό τόκου"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδιαφέρον"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία παραγγελίας"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθαρές πωλήσεις"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος Aναφοράς"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανω ΑΝΑΦΟΡΑ"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθαρά έσοδα για αντίστροφη χρέωση (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αντιστροφή της φορολογικής υποχρέωσης (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδοκοινοτικές εξαγορές"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άθροισμα ενδοκοινοτικών εξαγορών"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδοκοινοτικές εξαγορές"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φορολογικός συντελεστής"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerpflichtiger Umsatz"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκπεστέος φόρος εισροών"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγωγή ΦΠΑ"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])}
        }
      },
      "tr-CY": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitü"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitünün Bulunduğu Ülke"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap sahibi"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alacaklı enstitüsü"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitünün Bulunduğu Ülke"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap sahibi"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktarımın amacı"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ödenmiş"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["askıda olan"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını düzenleme"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını düzenleme"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödenen miktar"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme bilgileri"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme süresinin sonu"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme aracı"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme referansı"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme döneminin başlangıcı"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme detayları"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KDV oranı"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakış"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF tarihinde oluşturuldu"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belge Numarası"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müşteri numarası"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi numarası"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT kimlik numarası"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmetimizde bir hata oluştu. Lütfen tekrar deneyin."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndirilenler"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF İndir"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama verilerinin özeti"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayrıntılar"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama dönemi"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama başlangıç tarihi"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama bitiş tarihi"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdi vergisi"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturalardan giriş vergisi"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi malzemeler, Toplam"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi aktarım"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergisiz teslimatlar"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi oranı"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi oranı"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergisiz satışlar"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi oranı"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş Kimliği"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["katma değer Vergisi"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihracat"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihracat"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme miktarı"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faiz tutarı"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faiz"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş tarihi"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net satış"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Türü"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ters ibraz için net gelir (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi borcunun iptali (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi edinimler"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi satın almaların toplamı"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi edinimler"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi oranı"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerpflichtiger Umsatz"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndirilebilir girdi vergisi"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İthalat satış vergisi"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])}
        }
      },
      "uk-UA": {
        "payment": {
          "agent": {
            "bank": {
              "country": {
                
              }
            },
            "iban": {
              
            },
            "swiftcode": {
              
            },
            "account": {
              "owner": {
                
              }
            }
          },
          "recipient": {
            "bank": {
              "country": {
                
              }
            },
            "account": {
              "owner": {
                
              }
            },
            "iban": {
              
            },
            "swiftcode": {
              
            }
          },
          "purpose": {
            
          },
          "status": {
            "paid": {
              
            },
            "unpaid": {
              
            },
            "edit": {
              
            },
            "set": {
              
            }
          },
          "amount": {
            
          },
          "information": {
            
          },
          "endDueTime": {
            
          },
          "instrument": {
            
          },
          "reference": {
            
          },
          "startDueTime": {
            
          },
          "details": {
            
          }
        },
        "VATRate": {
          
        },
        "overview": {
          
        },
        "document": {
          "created": {
            "startTime": {
              
            }
          },
          "number": {
            
          }
        },
        "agent": {
          "number": {
            
          },
          "company": {
            "TIN": {
              
            }
          },
          "VATIN": {
            
          }
        },
        "notification": {
          "asyncError": {
            
          }
        },
        "downloads": {
          "downloadPDF": {
            
          }
        },
        "reporting": {
          "data": {
            "summary": {
              
            }
          }
        },
        "details": {
          
        },
        "reportingPeriod": {
          "startDate": {
            
          },
          "endDate": {
            
          }
        },
        "inputVAT": {
          "amount": {
            
          }
        },
        "credit": {
          "amount": {
            
          }
        },
        "iCSOG": {
          "total": {
            
          },
          "transfer": {
            
          },
          "net": {
            
          }
        },
        "parkingRate": {
          
        },
        "sRR": {
          
        },
        "taxFreeSales": {
          
        },
        "standardRate": {
          
        },
        "authority": {
          
        },
        "references": {
          "order": {
            "id": {
              
            }
          }
        },
        "outputVAT": {
          
        },
        "export": {
          "total": {
            
          }
        },
        "totalPaymentDue": {
          
        },
        "interest": {
          "amount": {
            
          }
        },
        "order": {
          "date": {
            
          }
        },
        "net": {
          
        },
        "report": {
          "type": {
            
          }
        },
        "reverseCharge": {
          "net": {
            
          }
        },
        "iCAOG": {
          "total": {
            "net": {
              
            }
          }
        },
        "reducedRate": {
          
        },
        "taxableSales": {
          
        },
        "deductibleTax": {
          
        },
        "importVAT": {
          "amount": {
            
          }
        },
        "edit": {
          
        }
      },
      "zh-CN": {
        "payment": {
          "agent": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所所在地的国家"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伊班"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工商银行"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户所有者"])}
              }
            }
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款人学院"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所所在地的国家"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户所有者"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伊班"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工商银行"])}
            }
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用法"])}
          },
          "status": {
            "paid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已付"])}
            },
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
            "unpaid": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待定"])}
            },
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改付款明细"])}
            },
            "set": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改付款明细"])}
            }
          },
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付的金额"])}
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付信息"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款期结束"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])}
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款凭据"])}
          },
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款期开始"])}
          },
          "details": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款详情"])}
          }
        },
        "VATRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增值税率"])}
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总览"])}
        },
        "document": {
          "created": {
            "startTime": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF创建于"])}
            }
          },
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件编号"])}
          }
        },
        "agent": {
          "number": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客号码"])}
          },
          "company": {
            "TIN": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税号"])}
            }
          },
          "VATIN": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增值税识别号"])}
          }
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的服务发生了错误。 请再试一遍。"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])}
          }
        },
        "downloads": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资料下载"])},
          "downloadPDF": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载PDF"])}
          }
        },
        "reporting": {
          "data": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告数据汇总"])}
            }
          }
        },
        "details": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["细节"])}
        },
        "reportingPeriod": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告期"])},
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告开始日期"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告结束日期"])}
          }
        },
        "inputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进项税"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票进项税"])}
          }
        },
        "credit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用"])},
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用"])}
          }
        },
        "iCSOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社区内用品， 总"])}
          },
          "transfer": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社区内转移"])}
          },
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免税送货"])}
          }
        },
        "parkingRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税率"])}
        },
        "sRR": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税率"])}
        },
        "taxFreeSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免税销售"])}
        },
        "standardRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税率"])}
        },
        "authority": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["权威"])}
        },
        "references": {
          "order": {
            "id": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单编号"])}
            }
          }
        },
        "outputVAT": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增值税"])}
        },
        "export": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出口"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出口"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付金额"])}
        },
        "interest": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利息金额"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兴趣"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订购日期"])}
          }
        },
        "net": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["净销售额"])}
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告类型"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告"])}
        },
        "reverseCharge": {
          "net": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冲销净收入 (Reverse charge)"])}
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冲销应纳税额 (Reverse charge)"])}
        },
        "iCAOG": {
          "total": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社区内收购"])},
            "net": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社区内收购的总和"])}
            }
          },
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社区内收购"])}
        },
        "reducedRate": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税率"])}
        },
        "taxableSales": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应税销售"])}
        },
        "deductibleTax": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可抵扣进项税"])}
        },
        "importVAT": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进口销售税"])}
          }
        },
        "edit": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])}
        }
      }
    }
  })
}
