import {createI18n} from 'vue-i18n'
import {useJurisdictionSwitch} from "@dutypay/components-library/src/composables/jurisdictionSwitch";
const { loadLocale } = useJurisdictionSwitch()
import '@dutypay/components-library/src/locales/numeralLocales'

const locale = await loadLocale();
async function loadLocaleMessages() {
  let messages = {};
  const translationValues = await import(`/src/locales/${locale}/translationValues.json`);
  const elementLocale = await import(`@dutypay/components-library/src/locales/element-plus/${locale}.js`);
  messages[locale] = Object.assign(elementLocale.default, translationValues.default);
  return messages;
}

export const i18n = createI18n({
  globalInjection: true,
  useScope: "global",
  legacy: false,
  locale: locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
  messages: await loadLocaleMessages(),
  fallbackWarn: false,
  silentTranslationWarn: true,
  silentFallbackWarn: true
});

