import './styles.scss';
import Reports from './pages/Reports';
import ListReports from "./pages/Reports/ListReports";
import ReportDetails from "./pages/Reports/ReportDetails";

const pages = [
    {
      path: '/',
      redirect: '/reports'
    },
    {
      path: '/reports',
      name: 'Reports',
      component: Reports,
      children: [
        {
          path: '',
          redirect: '/reports/overview'
        },
        {
          path: 'overview',
          name: 'ListReports',
          component: ListReports
        },
        {
          path: 'details/:reportId',
          name: 'ReportDetails',
          component: ReportDetails,
          props: true
        }
      ]
    }
];

import {computed} from 'vue';
import { i18n } from './i18n';
const { t } = i18n.global;

const menuItems = computed(() => [{
            slug: 'reports',
            path: '/reports',
            name: 'Reports',
            label: t('taxhub.pages.menuItems.reports.linkLabel'),
            children: [
                {
                    path: '/reports/overview',
                    name: 'ListReports',
                    label: t('taxhub.pages.menuItems.reports.children.overview.linkLabel')
                }
            ]
        }]);

    
export {
    pages,
    menuItems
}