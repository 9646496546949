export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير تفاصيل الدفع"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الدفع"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الدفع"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسيلة الدفع"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت السداد"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية فترة السداد"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة المشمولة بالتقرير"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإنشاء"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظرة عامة"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منقي"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل المزيد"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الدفع"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الطلب"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد متعاقد"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإمدادات داخل المجتمع"])}
        }
      },
      "ar-EG": {
        "payment": {
          "status": {
            "edit": {
              
            }
          },
          "information": {
            "show": {
              
            }
          },
          "instrument": {
            
          },
          "startTime": {
            
          },
          "endDueTime": {
            
          }
        },
        "reportingPeriod": {
          "period": {
            
          },
          "year": {
            
          },
          "months": {
            
          }
        },
        "created": {
          "startTime": {
            
          }
        },
        "overview": {
          
        },
        "filter": {
          "toggle": {
            
          }
        },
        "loadMore": {
          
        },
        "totalPaymentDue": {
          
        },
        "order": {
          "date": {
            
          }
        },
        "contractCountry": {
          
        },
        "iCSOG": {
          
        }
      },
      "ar-SA": {
        "payment": {
          "status": {
            "edit": {
              
            }
          },
          "information": {
            "show": {
              
            }
          },
          "instrument": {
            
          },
          "startTime": {
            
          },
          "endDueTime": {
            
          }
        },
        "reportingPeriod": {
          "period": {
            
          },
          "year": {
            
          },
          "months": {
            
          }
        },
        "created": {
          "startTime": {
            
          }
        },
        "overview": {
          
        },
        "filter": {
          "toggle": {
            
          }
        },
        "loadMore": {
          
        },
        "totalPaymentDue": {
          
        },
        "order": {
          "date": {
            
          }
        },
        "contractCountry": {
          
        },
        "iCSOG": {
          
        }
      },
      "de-DE": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformation"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformation"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende der Zahlfrist"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraum"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelldatum"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr laden"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlbetrag"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragungsdatum"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsland"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Lieferungen"])}
        }
      },
      "de-AT": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformation"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformation"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende der Zahlfrist"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraum"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelldatum"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr laden"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlbetrag"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragungsdatum"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsland"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Lieferungen"])}
        }
      },
      "de-CH": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformation"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformation"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende der Zahlfrist"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraum"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelldatum"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr laden"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlbetrag"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragungsdatum"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsland"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innergemeinschaftliche Lieferungen"])}
        }
      },
      "en-US": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment details"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instrument"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer time"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of the payment period"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting period"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment amount"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract country"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community supplies"])}
        }
      },
      "en-GB": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment details"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instrument"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer time"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of the payment period"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting period"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment amount"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract country"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-community supply"])}
        }
      },
      "it-IT": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i dettagli di pagamento"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul pagamento"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul pagamento"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strumento di pagamento"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di trasferimento"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine del periodo di pagamento"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di riferimento"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica di più"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo del pagamento"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dell'ordine"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese contraente"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forniture intracomunitarie"])}
        }
      },
      "fr-FR": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la période de paiement"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de rapport"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger plus"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays contractant"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournitures intracommunautaires"])}
        }
      },
      "es-ES": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalles de pago"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del pago"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del pago"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumento de pago"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de transferencia"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin del período de pago"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de información"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visión general"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga más"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto del pago"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de orden"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País contratante"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suministros intracomunitarios"])}
        }
      },
      "pl-PL": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj szczegóły płatności"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacja o płatności"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacja o płatności"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda płatności"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data płatności"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec terminu płatności"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres sprawozdawczy"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesiąc"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przegląd"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załaduj więcej"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość należności"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data zatwierdzenia danych"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsługiwany kraj"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawy wewnątrzwspólnotowe"])}
        }
      },
      "nl-NL": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsgegevens bewerken"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformatie"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformatie"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalinstrument"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip van betaling"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde van de betalingsperiode"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verslagperiode"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaar"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maand"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaakdatum"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer laden"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te betalen bedrag"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besteldatum"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdragsluitende land"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intracommunautaire leveringen"])}
        }
      },
      "bg-BG": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на данните за плащане"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация за плащане"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация за плащане"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежно нареждане"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Време на плащане"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок за плащане на ДДС"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчетен период"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Година"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месец"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на създаване"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["филтър"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареди още"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума за плащане"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на поръчка"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договаряща се страна"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вътрешнообщностни доставки"])}
        }
      },
      "bs-BA": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređivanje podataka o plaćanju"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o plaćanju"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o plaćanju"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument plaćanja"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme plaćanja"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj perioda plaćanja"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveštajni period"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godina"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mjesec"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum stvaranja"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učitaj još"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznos plaćanja"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narudžbe"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država ugovornica"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opskrba unutar zajednice"])}
        }
      },
      "da-DK": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger betalingsoplysninger"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformation"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformation"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinstrument"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidspunkt for betaling"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsperiodens slutning"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporteringsperiode"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["År"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måned"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelsesdato"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indlæs mere"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsbeløb"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestillingsdato"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontraherende land"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsyninger inden for samfundet"])}
        }
      },
      "et-EE": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutke makse üksikasju"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseteave"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseteave"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksevahend"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse aeg"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseperioodi lõpp"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruandeperiood"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aasta"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuu"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loomise kuupäev"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülevaade"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreeri"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lae rohkem"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse summa"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuse kuupäev"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lepinguosaline riik"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühendusesisesed tarned"])}
        }
      },
      "fi-FI": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa maksutietoja"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietoja maksusta"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietoja maksusta"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksutapa"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuaika"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuajan päättyminen"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportointikausi"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuosi"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausi"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luomispäivä"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiskatsaus"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodattaa"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa lisää"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksun määrä"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilauspäivämäärä"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sopimusmaa"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteisön sisäiset tarvikkeet"])}
        }
      },
      "el-GR": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στοιχείων πληρωμής"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες Πληρωμής"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες Πληρωμής"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσο πληρωμής"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρα πληρωμής"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήξη της περιόδου πληρωμής"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περίοδος αναφοράς"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετος"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μήνας"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρο"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρτωσε περισσότερα"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό ΠΛΗΡΩΜΗΣ"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία παραγγελίας"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμβαλλόμενη χώρα"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδοκοινοτικές προμήθειες"])}
        }
      },
      "he-IL": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערוך את פרטי התשלום"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מידע על התשלום"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מידע על התשלום"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכשיר תשלום"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["זמן התשלום"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוף תקופת התשלום"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תקופת הדיווח"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שָׁנָה"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["חוֹדֶשׁ"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך היווצרות"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סקירה כללית"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לְסַנֵן"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["טען עוד"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סכום לתשלום"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך הזמנה"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מדינה מתקשרת"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["אספקה פנים-קהילתית"])}
        }
      },
      "ga-IE": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir sonraí íocaíochta in eagar"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faisnéis íocaíochta"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faisnéis íocaíochta"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lonstraim íocaíochta"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am na híocaíochta"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deireadh na tréimhse íocaíochta"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tréimhse tuairiscithe"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bliain"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mí"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta cruthaithe"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbhreathnú"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scagaire"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luchtaigh níos mó"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méid íocaíochta"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta ordaithe"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tír chonarthach"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soláthairtí laistigh den phobal"])}
        }
      },
      "ja-JP": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いの詳細を編集"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払情報"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払情報"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い時期"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い期間の終了"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報告期間"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概観"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルタ"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと読み込む"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払金額"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文日"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["締約国"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニティ内供給"])}
        }
      },
      "hr-HR": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređivanje podataka o plaćanju"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o plaćanju"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o plaćanju"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument plaćanja"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme plaćanja"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj razdoblja plaćanja"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvještajno razdoblje"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godina"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mjesec"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum stvaranja"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtar"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učitaj više"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznos plaćanja"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narudžbe"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja ugovornica"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opskrba unutar zajednice"])}
        }
      },
      "lv-LV": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt maksājuma informāciju"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informācija par maksājumu"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informācija par maksājumu"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksāšanas līdzeklis"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmaksas laiks"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma termiņa beigas"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskata periods"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gads"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mēnesis"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveidošanas datums"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskats"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lelādēt vairāk"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma summa"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasūtījuma datums"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Līgumslēdzēja valsts"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krājumi Kopienas iekšienē"])}
        }
      },
      "lt-LT": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redaguoti mokėjimo informaciją"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo informacija"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo informacija"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo priemonė"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo laikas"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo laikotarpio pabaiga"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitinis laikotarpis"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metai"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mėnuo"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukūrimo data"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apžvalga"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtras"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įkelkite daugiau"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo suma"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo data"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susitariančioji šalis"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsargos bendruomenės viduje"])}
        }
      },
      "pt-PT": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalhes do pagamento"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação de pagamento"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação de pagamento"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumento de pagamento"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora do pagamento"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim do período de pagamento"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período coberto pelo relatório"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregue mais"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor do pagamento"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data do pedido"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País contratante"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimentos intracomunitários"])}
        }
      },
      "ro-RO": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editați detaliile de plată"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatii de plata"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatii de plata"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de plată"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora de plată"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfârșitul perioadei de plată"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioadă de raportare"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lună"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data crearii"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezentare generală"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtru"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incarca mai mult"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma de plată"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data comandă"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Țara contractantă"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furnizare intracomunitară"])}
        }
      },
      "ru-RU": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить платежные реквизиты"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежная информация"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежная информация"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежный инструмент"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время оплаты"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец платежного периода"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчетный период"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обзор"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузи больше"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма к оплате"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата предоставления данных"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договаривающаяся страна"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["доставка в пределах сообщества (ЕС)"])}
        }
      },
      "sv-SE": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera betalningsinformation"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information om betalning"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information om betalning"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsinstrument"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningstid"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsperiodens slut"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporteringsperiod"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["År"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månad"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapelsedagen"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Översikt"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrera"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda mer"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsbelopp"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderdatum"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avtalsland"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbehör inom gemenskapen"])}
        }
      },
      "sk-SK": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravte podrobnosti platby"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie o platbe"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie o platbe"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platobný nástroj"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas platby"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec platobného obdobia"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vykazované obdobie"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesiac"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum vytvorenia"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľad"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítať viac"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka na úhradu"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum objednávky"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmluvná krajina"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodávky v rámci Spoločenstva"])}
        }
      },
      "cs-CZ": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit platební údaje"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o platbě"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o platbě"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platební nástroj"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas platby"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konec platebního období"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba ohlášení"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíc"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vzniku"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načíst další"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka k úhradě"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum objednávky"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smluvní země"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zásoby uvnitř Společenství"])}
        }
      },
      "tr-TR": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını düzenleme"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme bilgileri"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme bilgileri"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme aracı"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme zamanı"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme süresinin sonu"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama dönemi"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıl"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ay"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulma tarihi"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakış"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla yükle"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme miktarı"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş tarihi"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme yapılan ülke"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi malzemeler"])}
        }
      },
      "hu-HU": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési adatok szerkesztése"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési információ"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési információ"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés módja"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés időpontja"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési időszak vége"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentési időszak"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Év"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hónap"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozás dátuma"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áttekintés"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltsön be többet"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végösszeg"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendelés dátuma"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerződő ország"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Közösségen belüli ellátás"])}
        }
      },
      "fr-BE": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la période de paiement"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de rapport"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger plus"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays contractant"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournitures intracommunautaires"])}
        }
      },
      "fr-LU": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la période de paiement"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de rapport"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger plus"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays contractant"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournitures intracommunautaires"])}
        }
      },
      "mt-MT": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editja d-dettalji tal-ħlas"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazzjoni dwar il-ħlas"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazzjoni dwar il-ħlas"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strument tal-ħlas"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ħin tat-trasferiment"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tmiem tal-perjodu tal-ħlas"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perjodu ta 'Rappurtar"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sena"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xahar"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data tal-ħolqien"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ħarsa ġenerali"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iffiltra"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagħbija aktar"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammont tal-ħlas"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data tal-ordni"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pajjiż Kontraenti"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provvisti intra-komunitarji"])}
        }
      },
      "sl-SI": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredite podatke o plačilu"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o plačilu"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o plačilu"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačilni instrument"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas plačila"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konec plačilnega obdobja"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdobje poročanja"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leto"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesec"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum nastanka"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naloži več"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znesek plačila"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum naročila"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država pogodbenica"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrebščine znotraj skupnosti"])}
        }
      },
      "el-CY": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στοιχείων πληρωμής"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες Πληρωμής"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες Πληρωμής"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσο πληρωμής"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρα πληρωμής"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήξη της περιόδου πληρωμής"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περίοδος αναφοράς"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετος"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μήνας"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρο"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρτωσε περισσότερα"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό ΠΛΗΡΩΜΗΣ"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία παραγγελίας"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμβαλλόμενη χώρα"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδοκοινοτικές προμήθειες"])}
        }
      },
      "tr-CY": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını düzenleme"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme bilgileri"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme bilgileri"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme aracı"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme zamanı"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme süresinin sonu"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama dönemi"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıl"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ay"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulma tarihi"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel bakış"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla yükle"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme miktarı"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş tarihi"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme yapılan ülke"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topluluk içi malzemeler"])}
        }
      },
      "uk-UA": {
        "payment": {
          "status": {
            "edit": {
              
            }
          },
          "information": {
            "show": {
              
            }
          },
          "instrument": {
            
          },
          "startTime": {
            
          },
          "endDueTime": {
            
          }
        },
        "reportingPeriod": {
          "period": {
            
          },
          "year": {
            
          },
          "months": {
            
          }
        },
        "created": {
          "startTime": {
            
          }
        },
        "overview": {
          
        },
        "filter": {
          "toggle": {
            
          }
        },
        "loadMore": {
          
        },
        "totalPaymentDue": {
          
        },
        "order": {
          "date": {
            
          }
        },
        "contractCountry": {
          
        },
        "iCSOG": {
          
        }
      },
      "zh-CN": {
        "payment": {
          "status": {
            "edit": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改付款明细"])}
            }
          },
          "information": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付信息"])},
            "show": {
              "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付信息"])}
            }
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款时间"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款期结束"])}
          }
        },
        "reportingPeriod": {
          "period": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告期"])}
          },
          "year": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])}
          },
          "months": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])}
          }
        },
        "overview": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总览"])}
        },
        "filter": {
          "toggle": {
            "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤"])}
          }
        },
        "loadMore": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["装载更多"])}
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付金额"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订购日期"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缔约国"])}
        },
        "iCSOG": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社区内用品"])}
        }
      }
    }
  })
}
