<script setup>
import FilterForm from "./ListReports/FilterForm";
import FilterTags from "./ListReports/FilterTags";
import SearchBar from "./ListReports/SearchBar";
import SortBy from "./ListReports/SortBy";
import StatusTagMobile from "./ListReports/StatusTagMobile";
import StatusTag from "./ListReports/StatusTag";
import PaymentTimeForm from "./ReportDetails/PaymentTimeForm";
import PaymentInformation from "./ReportDetails/PaymentInformation";

import { inject, onMounted, watch, computed, ref, reactive } from "vue";
import { get, each, concat, isEmpty } from 'lodash'
import numeral from 'numeral';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat);
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
import { interceptor } from '@dutypay/utilities'
import { useEnvStore, useCompanyStore, useScrollPositionStore } from '@dutypay/store-modules'
const envStore = useEnvStore()
const companyStore = useCompanyStore()
const scrollPositionStore = useScrollPositionStore()
import { useReportsParameterStore } from "../../stores/reportsParameters";
const reportsParameterStore = useReportsParameterStore()
import {to} from "await-to-js";
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import { useRouter } from 'vue-router'
const router = useRouter()
const lastToken = ref('')
const loadingMore = ref(false)
const loading = ref(false)
const showFilterForm = ref(false)
const showQuickActions = reactive({});
const data = reactive({});
const transactions = reactive([]);
const numberOfResults = {
  desktop: 30,
  mobile: 15
}

const composables = inject('composables');
const { isMobile } = composables.useViewportSize();

// eslint-disable-next-line no-unused-vars
const tKAAliases = [
  t('taxhub.pages.Reports.ListReports.payment.instrument.displayValues-'),
  t("taxhub.pages.Reports.ListReports.contractCountry.displayValues-"),
  t("taxhub.pages.Reports.ListReports.currency.displayValues-")
]

const noData = computed(() => {
  return isEmpty(transactions.value)
})
const queryParameters = computed(() => {
  return reportsParameterStore.queryParameters
})
const next = computed(() => {
  let multiplier = (lastToken.value) ? 1 : parseInt(get(queryParameters.value, 'page', 1), 10);
  if (isMobile.value)
    return numberOfResults.mobile * multiplier;
  else
    return numberOfResults.desktop * multiplier;
})
const apiUrls = computed(() => {
  return envStore.apiUrls
})
const selectedCompany = computed(() => {
  return companyStore.selectedCompany
})

function toggleQuickActions(reportId, action) {
  showQuickActions[reportId][action] = !(showQuickActions[reportId][action]);
}
function addClickAction(reports) {
  return each(reports, function (report) {
    return Object.assign(report, {action: () => {
        router.push({name: 'ReportDetails', params: {reportId: report.id}});
      }});
  });
}
function populateShowQuickActionsObject() {
  each(transactions.value, function(item) {
    showQuickActions[item.id] = {
      paymentInformation: false
    }
  });
}

function formatDate(date) {
  if (date === undefined)
    return undefined;
  return dayjs.utc(date).local().format('YYYY-MM-DD[T00:00:00]')
}
async function getReports() {
  if (lastToken.value)
    loadingMore.value = true;
  else
    loading.value = true;
  let [err, res] = await to(interceptor.get(`${apiUrls.value.taxhub}/api/v0/reports`, {
    params: {
      company: selectedCompany.value.hs.company.id,
      spCountryId: (isMobile.value) ? undefined : get(queryParameters.value, 'spCountryId', undefined),
      reportingPeriodStartDate: (isMobile.value) ? undefined : formatDate(get(queryParameters.value, 'reportingPeriodStartDate', undefined)),
      reportingPeriodStartDateOp: (isMobile.value) ? undefined : get(queryParameters.value, 'reportingPeriodStartDateOp', undefined),
      reportingPeriodEndDate: (isMobile.value) ? undefined : formatDate(get(queryParameters.value, 'reportingPeriodEndDate', undefined)),
      reportingPeriodEndDateOp: (isMobile.value) ? undefined : get(queryParameters.value, 'reportingPeriodEndDateOp', undefined),
      spReportTypeId: (isMobile.value) ? undefined : get(queryParameters.value, 'spReportTypeId', undefined),
      orderDate: (isMobile.value) ? undefined : formatDate(get(queryParameters.value, 'orderDate', undefined)),
      orderDateOp: (isMobile.value) ? undefined : get(queryParameters.value, 'orderDateOp', undefined),
      paymentStartTime: (isMobile.value) ? undefined : formatDate(get(queryParameters.value, 'paymentStartTime', undefined)),
      paymentStartTimeOp: (isMobile.value) ? undefined : get(queryParameters.value, 'paymentStartTimeOp', undefined),
      spPaymentInstrumentId: (isMobile.value) ? undefined : get(queryParameters.value, 'spPaymentInstrumentId', undefined),
      sortBy: get(queryParameters.value, 'sortBy', undefined),
      search: get(queryParameters.value, 'search', undefined),
      token: (lastToken.value) ? lastToken.value : undefined,
      next: next.value
    }
  }));
  if (err) {
    loadingMore.value = false;
    loading.value = false;
    throw err;
  }
  data.value = res.data;
  let reports = addClickAction(res.data.reports);
  if (lastToken.value) {
    transactions.value = concat(transactions.value, reports);
    lastToken.value = undefined;
  } else {
    transactions.value = reports;
    scrollPositionStore.scrollToStoredPosition();
    scrollPositionStore.resetScrollPosition();
  }
  populateShowQuickActionsObject();
  loading.value = false;
  loadingMore.value = false;
}

function closeFilterForm() {
  showFilterForm.value = false;
}
function toggleFilterForm() {
  showFilterForm.value = !showFilterForm.value;
}
async function reloadResults() {
  await getReports();
}

async function loadMore() {
  lastToken.value = data.value.token;
  let page = get(queryParameters, 'page', 1);
  if (parseInt(page, 10) == page)
    page = parseInt(page, 10) + 1;
  else
    page = '2';
  reportsParameterStore.pushQueryParameters({page});
  await getReports();
}

function displayValues(path, key) {
  if (key === undefined || isEmpty(key))
    return undefined;
  if (!envStore.debug && t(path + key) === path + key)
    return key;
  return t(path + key);
}

function routeToReportDetails(reportId) {
  scrollPositionStore.storeScrollPosition();
  router.push({name: 'ReportDetails', params: {reportId: reportId}});
}

onMounted(async () => {
  await getReports()
})

watch(isMobile.value, async (newValue, oldValue) => {
  if (oldValue !== undefined)
    await getReports();
})

</script>
<script>
  export default {
    name: "ListReports",
  }
</script>

<template>
  <div>
    <div v-if="!isMobile">
      <div class="dp-flex wrap justify-content__space-between">
        <h2>{{t('overview.label')}}</h2>
        <div class="align-self__flex-end inline-box hidden-xs-only h-padding" style="padding-inline-start: 1em;">
          <dp-button purpose="secondary" style="margin-inline-end: 0.5em;" @click="toggleFilterForm">
            <div class="dp-flex justify-content__center">
              <DpIcon icon="filter"/>{{t('filter.toggle.buttonLabel')}}
            </div>
          </dp-button>
          <SortBy v-on:select="reloadResults"/>
        </div>
      </div>

      <DpExpand>
        <FilterForm v-show="showFilterForm"
                    v-on:close="closeFilterForm"
                    v-on:apply-filter="reloadResults"/>
      </DpExpand>

      <DpExpand>
        <FilterTags v-show="!showFilterForm && reportsParameterStore.hasFilter"
                    v-on:remove-filter="reloadResults"/>
      </DpExpand>

      <div style="margin-bottom: 1em;">
        <SearchBar v-on:submit="reloadResults" v-on:clear-search="reloadResults"/>
      </div>

      <template v-if="loading">
        <DpListItem v-for="index in next"
                  :key="index"
                  class="dp-skeleton-load"
                  style="height: 12.66rem;">
        </DpListItem>
      </template>
      <template v-else-if="noData">
        <DpNoDataIndicator/>
      </template>
      <template v-else>
      <DpListItem v-for="(report) in transactions.value"
                  :quick-actions="true"
                  pointer
                  :key="get(report, 'id', undefined)"
                  :class="{'marked': !(get(report, 'payment._isPaid', false)), 'list-item__hover-animation': true}"
                  :action="() => {routeToReportDetails(get(report, 'id', undefined))}">
        <template #headline>
          <div class="dp-flex justify-content__space-between">
          <div class="inline-block">
           <DpFlag :flagISOCode="$dp.mapSpCountryToFlag(get(report, 'sp.country.id', undefined))"/> {{displayValues('taxhub.pages.Reports.ListReports.contractCountry.displayValues-', get(report, 'sp.country.id', undefined))}}
          </div>
          <div class="inline-block flex-basis-0" style="padding-inline-start: 1em;">
            <StatusTag :status="get(report, 'payment._isPaid', false)"/>
          </div>
          </div>
        </template>

        <div class="dp-flex justify-content__space-between align-items__flex-start">
          <div class="inline-block text-align-left">
            <DpReadValue inline
                         padding-right
                         :data-key="t('reportingPeriod.year.label')"
                         :data-value="(dayjs.utc(get(report, 'reportingPeriod.startDate', null)).format('YYYY') === dayjs.utc(get(report, 'reportingPeriod.endDate', null)).format('YYYY'))
                                      ? dayjs.utc(get(report, 'reportingPeriod.startDate', null)).format('YYYY')
                                      : dayjs.utc(get(report, 'reportingPeriod.startDate', null)).format('YYYY') + ' - ' + dayjs.utc(get(report, 'reportingPeriod.endDate', null)).format('YYYY')"/>
            <DpReadValue inline
                         padding-right
                         :data-key="t('reportingPeriod.months.label', dayjs.utc(get(report, 'reportingPeriod.startDate', null)).format('M') === dayjs.utc(get(report, 'reportingPeriod.endDate', null)).format('M') ? 1 : 2)"
                         :data-value="(dayjs.utc(get(report, 'reportingPeriod.startDate', null)).format('M') === dayjs.utc(get(report, 'reportingPeriod.endDate', null)).format('M'))
                                      ? dayjs.utc(get(report, 'reportingPeriod.startDate', null)).format('MMMM')
                                      : dayjs.utc(get(report, 'reportingPeriod.startDate', null)).format('MMMM') + ' - ' + dayjs.utc(get(report, 'reportingPeriod.endDate', null)).format('MMMM')"/>
           <DpReadValue inline padding-right :data-key="t('order.date.label')" :data-value="dayjs.utc(get(report, 'order.date', null)).local().format('LL')"/>
           <DpReadValue v-if="envStore.debug" inline padding-right :data-key="t('created.startTime.label')" :data-value="dayjs.utc(get(report, 'created.startTime', null)).local().format('LLL')"/>
          </div>
          <div class="inline-block text-align-right">
            <template v-if="get(report, 'payment._isPaid', false)">
              <DpReadValue inline padding-left :data-key="t('payment.startTime.label')" :data-value="dayjs.utc(get(report, 'payment.startTime', null)).local().format('LL')"/>
              <DpReadValue inline padding-left :data-key="t('totalPaymentDue.label')" :data-value="numeral(get(report, 'totalPaymentDue', null)).format('0,0.00') + ' ' + displayValues('taxhub.pages.Reports.ListReports.currency.displayValues-', get(report, 'sp.currency.id', undefined))"/>
            </template>
            <template v-else>
              <DpReadValue inline padding-left :data-key="t('payment.instrument.label')" :data-value="displayValues('taxhub.pages.Reports.ListReports.payment.instrument.displayValues-', get(report, 'sp.payment.instrument.id', undefined))"/>
              <DpReadValue inline padding-left :data-key="t('payment.endDueTime.label')" :data-value="dayjs.utc(get(report, 'payment.endDueTime', null)).local().format('LLL')"/>
            </template>
          </div>
        </div>

        <template #quick-actions>
          <div class="dp-flex justify-content__space-between">
          <div class="inline-block">
            <ul class="quick-actions__links">
            </ul>
           </div>
          <div class="inline-block" style="padding-inline-start: 1em;">
            <ul class="quick-actions__links">
              <li v-if="get(report, 'payment._isPaid', false)" @click="toggleQuickActions(report.id, 'paymentInformation')"><DpButton purpose="secondaryAction" size="small">{{t('payment.status.edit.buttonLabel')}}</DpButton></li>
              <li v-if="!get(report, 'payment._isPaid', false)" @click="toggleQuickActions(report.id, 'paymentInformation')"><DpButton purpose="secondaryAction" size="small">{{t('payment.information.show.buttonLabel')}}</DpButton></li>
            </ul>
          </div>
          </div>

          <div class="quick-actions__payment-information">
            <DpExpand>
              <div class="dp-flex row reverse justify-content__space-between align-items__flex-start" v-show="showQuickActions[report.id].paymentInformation">
                <div class="flex-basis-1 partition">
                  <h3>{{ t('payment.startTime.label') }}</h3>
                  <PaymentTimeForm :payment_start-time="get(report, 'payment.startTime', null)" :id="report.id" v-on:update="reloadResults" v-on:cancel="showQuickActions[report.id].paymentInformation = false"/>
                </div>
                <div class="flex-basis-auto partition" v-if="!get(report, 'payment._isPaid', false)">
                  <h3>{{ t("payment.information.label") }}</h3>
                  <PaymentInformation :data="report"/>
                </div>
              </div>
            </DpExpand>

          </div>
        </template>
      </DpListItem>
      </template>
      <template v-if="loadingMore">
        <DpListItem v-for="index in 30"
                  :key="index"
                  class="dp-skeleton-load"
                  style="height: 12.66rem;">
        </DpListItem>
      </template>

    </div>

    <div v-if="isMobile">
      <h2>{{t('overview.label')}}</h2>
      <SortBy v-on:select="reloadResults"/>
      <div style="margin-bottom: 1em;">
        <SearchBar v-on:submit="reloadResults" v-on:clear-search="reloadResults"/>
      </div>

      <template v-if="loading">
        <DpMobileListItem v-for="index in next"
                  :key="index"
                  class="dp-skeleton-load"
                  style="height: 3.37rem;">
        </DpMobileListItem>
      </template>
      <template v-else-if="noData">
        <DpNoDataIndicator/>
      </template>
      <template v-else>
      <DpMobileListItem v-for="(report, index) in transactions.value"
                        :key="index"
                        @click="() => {routeToReportDetails(get(report, 'id', undefined))}"
                        :class="{'marked': !(get(report, 'payment._isPaid', false))}">
        <template #headline>
          <div class="dp-flex justify-content__space-between align-content__flex-start">
            <span><DpFlag :flagISOCode="$dp.mapSpCountryToFlag(get(report, 'sp.country.id', undefined))"/> {{displayValues('taxhub.pages.Reports.ListReports.contractCountry.displayValues-', get(report, 'sp.country.id', undefined))}}</span>
            <StatusTagMobile :status="get(report, 'payment._isPaid', false)"/>
          </div>
        </template>
        <template #description>
            <span style="white-space: nowrap;" v-if="get(report, 'reportingPeriod.startDate', false)">{{ dayjs.utc(get(report, 'reportingPeriod.startDate', null)).format('LL') }}</span>&nbsp;-&nbsp;
            <span style="white-space: nowrap" v-if="get(report, 'reportingPeriod.endDate', false)">{{ dayjs.utc(get(report, 'reportingPeriod.endDate', null)).format('LL') }}</span>
        </template>
      </DpMobileListItem>
      </template>
      <template v-if="loadingMore">
        <DpMobileListItem v-for="index in numberOfResultsMobileView"
                          :key="index"
                          class="dp-skeleton-load"
                          style="height: 3.37rem;">
        </DpMobileListItem>
      </template>
    </div>

    <div class="load-more" v-show="(data.token)">
      <dp-button type="text" @click="loadMore">{{t('loadMore.buttonLabel')}}</dp-button>
    </div>

  </div>
</template>

<style scoped lang="scss">
  .list-item__hover-animation {
    transition: 0.3s;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: ease-out;
    transition-delay: 0.11s;
    &:hover {
      box-shadow: 0 3px 12px 0 rgba(0,0,0,.10);
      transform: translateY(-0.5px);
    }
  }

  .load-more {
    margin: 2rem auto;
    text-align: center;
  }

  .marked {
    border-color: #E6A23C;
  }

  .quick-actions__payment-information {
    .dp-flex {
      padding-inline-start: 1.3rem;

      .partition {
        margin-top: .66rem;
        margin-bottom: 1.3rem;
        margin-inline-end: 1.3rem;
        margin-inline-start: 0;

        h3, h4 {
          padding: 0 0 .3em 0;
        }
      }
    }
  }

  ul.quick-actions__links {
    color: #999999;
    font-size: 0.85em;
    list-style: none;
    margin: 0;
    padding: .7rem 1.3rem;

    li {
      display: inline;
      padding: 0;
      margin: 0;
      &:last-child {
        margin-inline-end: 0;
      }
      &:first-child {
        margin-inline-start: 0;
      }
    }
  }
</style>

<i18n>
{
  "ar-AE": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "تحرير تفاصيل الدفع"
        }
      },
      "information": {
        "label": "معلومات الدفع",
        "show": {
          "buttonLabel": "معلومات الدفع"
        }
      },
      "instrument": {
        "label": "وسيلة الدفع"
      },
      "startTime": {
        "label": "وقت السداد"
      },
      "endDueTime": {
        "label": "نهاية فترة السداد"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "الفترة المشمولة بالتقرير"
      },
      "year": {
        "label": "عام"
      },
      "months": {
        "label": "شهر"
      }
    },
    "created": {
      "startTime": {
        "label": "تاريخ الإنشاء"
      }
    },
    "overview": {
      "label": "نظرة عامة"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "منقي"
      }
    },
    "loadMore": {
      "buttonLabel": "تحميل المزيد"
    },
    "totalPaymentDue": {
      "label": "مبلغ الدفع"
    },
    "order": {
      "date": {
        "label": "تاريخ الطلب"
      }
    },
    "contractCountry": {
      "label": "بلد متعاقد"
    },
    "iCSOG": {
      "label": "الإمدادات داخل المجتمع"
    }
  },
  "ar-EG": {
    "payment": {
      "status": {
        "edit": {}
      },
      "information": {
        "show": {}
      },
      "instrument": {},
      "startTime": {},
      "endDueTime": {}
    },
    "reportingPeriod": {
      "period": {},
      "year": {},
      "months": {}
    },
    "created": {
      "startTime": {}
    },
    "overview": {},
    "filter": {
      "toggle": {}
    },
    "loadMore": {},
    "totalPaymentDue": {},
    "order": {
      "date": {}
    },
    "contractCountry": {},
    "iCSOG": {}
  },
  "ar-SA": {
    "payment": {
      "status": {
        "edit": {}
      },
      "information": {
        "show": {}
      },
      "instrument": {},
      "startTime": {},
      "endDueTime": {}
    },
    "reportingPeriod": {
      "period": {},
      "year": {},
      "months": {}
    },
    "created": {
      "startTime": {}
    },
    "overview": {},
    "filter": {
      "toggle": {}
    },
    "loadMore": {},
    "totalPaymentDue": {},
    "order": {
      "date": {}
    },
    "contractCountry": {},
    "iCSOG": {}
  },
  "de-DE": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Zahlungsdetails bearbeiten"
        }
      },
      "information": {
        "label": "Zahlungsinformation",
        "show": {
          "buttonLabel": "Zahlungsinformation"
        }
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      },
      "startTime": {
        "label": "Zahlungszeitpunkt"
      },
      "endDueTime": {
        "label": "Ende der Zahlfrist"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Meldezeitraum"
      },
      "year": {
        "label": "Jahr"
      },
      "months": {
        "label": "Monat"
      }
    },
    "created": {
      "startTime": {
        "label": "Erstelldatum"
      }
    },
    "overview": {
      "label": "Übersicht"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filter"
      }
    },
    "loadMore": {
      "buttonLabel": "Mehr laden"
    },
    "totalPaymentDue": {
      "label": "Zahlbetrag"
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum"
      }
    },
    "contractCountry": {
      "label": "Vertragsland"
    },
    "iCSOG": {
      "label": "Innergemeinschaftliche Lieferungen"
    }
  },
  "de-AT": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Zahlungsdetails bearbeiten"
        }
      },
      "information": {
        "label": "Zahlungsinformation",
        "show": {
          "buttonLabel": "Zahlungsinformation"
        }
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      },
      "startTime": {
        "label": "Zahlungszeitpunkt"
      },
      "endDueTime": {
        "label": "Ende der Zahlfrist"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Meldezeitraum"
      },
      "year": {
        "label": "Jahr"
      },
      "months": {
        "label": "Monat"
      }
    },
    "created": {
      "startTime": {
        "label": "Erstelldatum"
      }
    },
    "overview": {
      "label": "Übersicht"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filter"
      }
    },
    "loadMore": {
      "buttonLabel": "Mehr laden"
    },
    "totalPaymentDue": {
      "label": "Zahlbetrag"
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum"
      }
    },
    "contractCountry": {
      "label": "Vertragsland"
    },
    "iCSOG": {
      "label": "Innergemeinschaftliche Lieferungen"
    }
  },
  "de-CH": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Zahlungsdetails bearbeiten"
        }
      },
      "information": {
        "label": "Zahlungsinformation",
        "show": {
          "buttonLabel": "Zahlungsinformation"
        }
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      },
      "startTime": {
        "label": "Zahlungszeitpunkt"
      },
      "endDueTime": {
        "label": "Ende der Zahlfrist"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Meldezeitraum"
      },
      "year": {
        "label": "Jahr"
      },
      "months": {
        "label": "Monat"
      }
    },
    "created": {
      "startTime": {
        "label": "Erstelldatum"
      }
    },
    "overview": {
      "label": "Übersicht"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filter"
      }
    },
    "loadMore": {
      "buttonLabel": "Mehr laden"
    },
    "totalPaymentDue": {
      "label": "Zahlbetrag"
    },
    "order": {
      "date": {
        "label": "Beauftragungsdatum"
      }
    },
    "contractCountry": {
      "label": "Vertragsland"
    },
    "iCSOG": {
      "label": "Innergemeinschaftliche Lieferungen"
    }
  },
  "en-US": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Edit payment details"
        }
      },
      "information": {
        "label": "Payment information",
        "show": {
          "buttonLabel": "Payment information"
        }
      },
      "instrument": {
        "label": "Payment instrument"
      },
      "startTime": {
        "label": "Transfer time"
      },
      "endDueTime": {
        "label": "End of the payment period"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Reporting period"
      },
      "year": {
        "label": "Year"
      },
      "months": {
        "label": "Month"
      }
    },
    "created": {
      "startTime": {
        "label": "Creation date"
      }
    },
    "overview": {
      "label": "Overview"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filter"
      }
    },
    "loadMore": {
      "buttonLabel": "Load more"
    },
    "totalPaymentDue": {
      "label": "Payment amount"
    },
    "order": {
      "date": {
        "label": "Order date"
      }
    },
    "contractCountry": {
      "label": "Contract country"
    },
    "iCSOG": {
      "label": "Intra-community supplies"
    }
  },
  "en-GB": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Edit payment details"
        }
      },
      "information": {
        "label": "Payment information",
        "show": {
          "buttonLabel": "Payment information"
        }
      },
      "instrument": {
        "label": "Payment instrument"
      },
      "startTime": {
        "label": "Transfer time"
      },
      "endDueTime": {
        "label": "End of the payment period"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Reporting period"
      },
      "year": {
        "label": "Year"
      },
      "months": {
        "label": "Month"
      }
    },
    "created": {
      "startTime": {
        "label": "Creation date"
      }
    },
    "overview": {
      "label": "Overview"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filter"
      }
    },
    "loadMore": {
      "buttonLabel": "Load more"
    },
    "totalPaymentDue": {
      "label": "Payment amount"
    },
    "order": {
      "date": {
        "label": "Order date"
      }
    },
    "contractCountry": {
      "label": "Contract country"
    },
    "iCSOG": {
      "label": "Intra-community supply"
    }
  },
  "it-IT": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Modifica i dettagli di pagamento"
        }
      },
      "information": {
        "label": "Informazioni sul pagamento",
        "show": {
          "buttonLabel": "Informazioni sul pagamento"
        }
      },
      "instrument": {
        "label": "Strumento di pagamento"
      },
      "startTime": {
        "label": "Tempo di trasferimento"
      },
      "endDueTime": {
        "label": "Fine del periodo di pagamento"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Periodo di riferimento"
      },
      "year": {
        "label": "Anno"
      },
      "months": {
        "label": "Mese"
      }
    },
    "created": {
      "startTime": {
        "label": "Data di creazione"
      }
    },
    "overview": {
      "label": "Panoramica"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtro"
      }
    },
    "loadMore": {
      "buttonLabel": "Carica di più"
    },
    "totalPaymentDue": {
      "label": "Importo del pagamento"
    },
    "order": {
      "date": {
        "label": "Data dell'ordine"
      }
    },
    "contractCountry": {
      "label": "Paese contraente"
    },
    "iCSOG": {
      "label": "Forniture intracomunitarie"
    }
  },
  "fr-FR": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Modifier les détails du paiement"
        }
      },
      "information": {
        "label": "Informations de paiement",
        "show": {
          "buttonLabel": "Informations de paiement"
        }
      },
      "instrument": {
        "label": "Instrument de paiement"
      },
      "startTime": {
        "label": "Temps de transfert"
      },
      "endDueTime": {
        "label": "Fin de la période de paiement"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Période de rapport"
      },
      "year": {
        "label": "Année"
      },
      "months": {
        "label": "Mois"
      }
    },
    "created": {
      "startTime": {
        "label": "Date de création"
      }
    },
    "overview": {
      "label": "Aperçu"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtre"
      }
    },
    "loadMore": {
      "buttonLabel": "Charger plus"
    },
    "totalPaymentDue": {
      "label": "Montant du paiement"
    },
    "order": {
      "date": {
        "label": "Date de commande"
      }
    },
    "contractCountry": {
      "label": "Pays contractant"
    },
    "iCSOG": {
      "label": "Fournitures intracommunautaires"
    }
  },
  "es-ES": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Editar detalles de pago"
        }
      },
      "information": {
        "label": "Información del pago",
        "show": {
          "buttonLabel": "Información del pago"
        }
      },
      "instrument": {
        "label": "Instrumento de pago"
      },
      "startTime": {
        "label": "Tiempo de transferencia"
      },
      "endDueTime": {
        "label": "Fin del período de pago"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Período de información"
      },
      "year": {
        "label": "Año"
      },
      "months": {
        "label": "Mes"
      }
    },
    "created": {
      "startTime": {
        "label": "Fecha de creación"
      }
    },
    "overview": {
      "label": "Visión general"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtrar"
      }
    },
    "loadMore": {
      "buttonLabel": "Carga más"
    },
    "totalPaymentDue": {
      "label": "Monto del pago"
    },
    "order": {
      "date": {
        "label": "Fecha de orden"
      }
    },
    "contractCountry": {
      "label": "País contratante"
    },
    "iCSOG": {
      "label": "Suministros intracomunitarios"
    }
  },
  "pl-PL": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Edytuj szczegóły płatności"
        }
      },
      "information": {
        "label": "Informacja o płatności",
        "show": {
          "buttonLabel": "Informacja o płatności"
        }
      },
      "instrument": {
        "label": "Metoda płatności"
      },
      "startTime": {
        "label": "Data płatności"
      },
      "endDueTime": {
        "label": "Koniec terminu płatności"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Okres sprawozdawczy"
      },
      "year": {
        "label": "Rok"
      },
      "months": {
        "label": "Miesiąc"
      }
    },
    "created": {
      "startTime": {
        "label": "Data utworzenia"
      }
    },
    "overview": {
      "label": "Przegląd"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtr"
      }
    },
    "loadMore": {
      "buttonLabel": "Załaduj więcej"
    },
    "totalPaymentDue": {
      "label": "Wysokość należności"
    },
    "order": {
      "date": {
        "label": "Data zatwierdzenia danych"
      }
    },
    "contractCountry": {
      "label": "Obsługiwany kraj"
    },
    "iCSOG": {
      "label": "Dostawy wewnątrzwspólnotowe"
    }
  },
  "nl-NL": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Betalingsgegevens bewerken"
        }
      },
      "information": {
        "label": "Betalingsinformatie",
        "show": {
          "buttonLabel": "Betalingsinformatie"
        }
      },
      "instrument": {
        "label": "Betaalinstrument"
      },
      "startTime": {
        "label": "Tijdstip van betaling"
      },
      "endDueTime": {
        "label": "Einde van de betalingsperiode"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Verslagperiode"
      },
      "year": {
        "label": "Jaar"
      },
      "months": {
        "label": "Maand"
      }
    },
    "created": {
      "startTime": {
        "label": "Aanmaakdatum"
      }
    },
    "overview": {
      "label": "Overzicht"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filter"
      }
    },
    "loadMore": {
      "buttonLabel": "Meer laden"
    },
    "totalPaymentDue": {
      "label": "Te betalen bedrag"
    },
    "order": {
      "date": {
        "label": "Besteldatum"
      }
    },
    "contractCountry": {
      "label": "Verdragsluitende land"
    },
    "iCSOG": {
      "label": "Intracommunautaire leveringen"
    }
  },
  "bg-BG": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Редактиране на данните за плащане"
        }
      },
      "information": {
        "label": "Информация за плащане",
        "show": {
          "buttonLabel": "Информация за плащане"
        }
      },
      "instrument": {
        "label": "Платежно нареждане"
      },
      "startTime": {
        "label": "Време на плащане"
      },
      "endDueTime": {
        "label": "Срок за плащане на ДДС"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Отчетен период"
      },
      "year": {
        "label": "Година"
      },
      "months": {
        "label": "Месец"
      }
    },
    "created": {
      "startTime": {
        "label": "Дата на създаване"
      }
    },
    "overview": {
      "label": "Преглед"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "филтър"
      }
    },
    "loadMore": {
      "buttonLabel": "Зареди още"
    },
    "totalPaymentDue": {
      "label": "Сума за плащане"
    },
    "order": {
      "date": {
        "label": "Дата на поръчка"
      }
    },
    "contractCountry": {
      "label": "Договаряща се страна"
    },
    "iCSOG": {
      "label": "Вътрешнообщностни доставки"
    }
  },
  "bs-BA": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Uređivanje podataka o plaćanju"
        }
      },
      "information": {
        "label": "Informacije o plaćanju",
        "show": {
          "buttonLabel": "Informacije o plaćanju"
        }
      },
      "instrument": {
        "label": "Instrument plaćanja"
      },
      "startTime": {
        "label": "Vrijeme plaćanja"
      },
      "endDueTime": {
        "label": "Kraj perioda plaćanja"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Izveštajni period"
      },
      "year": {
        "label": "Godina"
      },
      "months": {
        "label": "Mjesec"
      }
    },
    "created": {
      "startTime": {
        "label": "Datum stvaranja"
      }
    },
    "overview": {
      "label": "Pregled"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filter"
      }
    },
    "loadMore": {
      "buttonLabel": "Učitaj još"
    },
    "totalPaymentDue": {
      "label": "Iznos plaćanja"
    },
    "order": {
      "date": {
        "label": "Datum narudžbe"
      }
    },
    "contractCountry": {
      "label": "Država ugovornica"
    },
    "iCSOG": {
      "label": "Opskrba unutar zajednice"
    }
  },
  "da-DK": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Rediger betalingsoplysninger"
        }
      },
      "information": {
        "label": "Betalingsinformation",
        "show": {
          "buttonLabel": "Betalingsinformation"
        }
      },
      "instrument": {
        "label": "Betalingsinstrument"
      },
      "startTime": {
        "label": "Tidspunkt for betaling"
      },
      "endDueTime": {
        "label": "Betalingsperiodens slutning"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Rapporteringsperiode"
      },
      "year": {
        "label": "År"
      },
      "months": {
        "label": "Måned"
      }
    },
    "created": {
      "startTime": {
        "label": "Oprettelsesdato"
      }
    },
    "overview": {
      "label": "Oversigt"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filter"
      }
    },
    "loadMore": {
      "buttonLabel": "Indlæs mere"
    },
    "totalPaymentDue": {
      "label": "Betalingsbeløb"
    },
    "order": {
      "date": {
        "label": "Bestillingsdato"
      }
    },
    "contractCountry": {
      "label": "Kontraherende land"
    },
    "iCSOG": {
      "label": "Forsyninger inden for samfundet"
    }
  },
  "et-EE": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Muutke makse üksikasju"
        }
      },
      "information": {
        "label": "Makseteave",
        "show": {
          "buttonLabel": "Makseteave"
        }
      },
      "instrument": {
        "label": "Maksevahend"
      },
      "startTime": {
        "label": "Makse aeg"
      },
      "endDueTime": {
        "label": "Makseperioodi lõpp"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Aruandeperiood"
      },
      "year": {
        "label": "Aasta"
      },
      "months": {
        "label": "Kuu"
      }
    },
    "created": {
      "startTime": {
        "label": "Loomise kuupäev"
      }
    },
    "overview": {
      "label": "Ülevaade"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtreeri"
      }
    },
    "loadMore": {
      "buttonLabel": "Lae rohkem"
    },
    "totalPaymentDue": {
      "label": "Makse summa"
    },
    "order": {
      "date": {
        "label": "Tellimuse kuupäev"
      }
    },
    "contractCountry": {
      "label": "Lepinguosaline riik"
    },
    "iCSOG": {
      "label": "Ühendusesisesed tarned"
    }
  },
  "fi-FI": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Muokkaa maksutietoja"
        }
      },
      "information": {
        "label": "Tietoja maksusta",
        "show": {
          "buttonLabel": "Tietoja maksusta"
        }
      },
      "instrument": {
        "label": "Maksutapa"
      },
      "startTime": {
        "label": "Maksuaika"
      },
      "endDueTime": {
        "label": "Maksuajan päättyminen"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Raportointikausi"
      },
      "year": {
        "label": "Vuosi"
      },
      "months": {
        "label": "Kuukausi"
      }
    },
    "created": {
      "startTime": {
        "label": "Luomispäivä"
      }
    },
    "overview": {
      "label": "Yleiskatsaus"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Suodattaa"
      }
    },
    "loadMore": {
      "buttonLabel": "Lataa lisää"
    },
    "totalPaymentDue": {
      "label": "Maksun määrä"
    },
    "order": {
      "date": {
        "label": "Tilauspäivämäärä"
      }
    },
    "contractCountry": {
      "label": "Sopimusmaa"
    },
    "iCSOG": {
      "label": "Yhteisön sisäiset tarvikkeet"
    }
  },
  "el-GR": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Επεξεργασία στοιχείων πληρωμής"
        }
      },
      "information": {
        "label": "Πληροφορίες Πληρωμής",
        "show": {
          "buttonLabel": "Πληροφορίες Πληρωμής"
        }
      },
      "instrument": {
        "label": "Μέσο πληρωμής"
      },
      "startTime": {
        "label": "Ώρα πληρωμής"
      },
      "endDueTime": {
        "label": "Λήξη της περιόδου πληρωμής"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Περίοδος αναφοράς"
      },
      "year": {
        "label": "Ετος"
      },
      "months": {
        "label": "Μήνας"
      }
    },
    "created": {
      "startTime": {
        "label": "Ημερομηνία δημιουργίας"
      }
    },
    "overview": {
      "label": "ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Φίλτρο"
      }
    },
    "loadMore": {
      "buttonLabel": "Φόρτωσε περισσότερα"
    },
    "totalPaymentDue": {
      "label": "Ποσό ΠΛΗΡΩΜΗΣ"
    },
    "order": {
      "date": {
        "label": "Ημερομηνία παραγγελίας"
      }
    },
    "contractCountry": {
      "label": "Συμβαλλόμενη χώρα"
    },
    "iCSOG": {
      "label": "Ενδοκοινοτικές προμήθειες"
    }
  },
  "he-IL": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "ערוך את פרטי התשלום"
        }
      },
      "information": {
        "label": "מידע על התשלום",
        "show": {
          "buttonLabel": "מידע על התשלום"
        }
      },
      "instrument": {
        "label": "מכשיר תשלום"
      },
      "startTime": {
        "label": "זמן התשלום"
      },
      "endDueTime": {
        "label": "סוף תקופת התשלום"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "תקופת הדיווח"
      },
      "year": {
        "label": "שָׁנָה"
      },
      "months": {
        "label": "חוֹדֶשׁ"
      }
    },
    "created": {
      "startTime": {
        "label": "תאריך היווצרות"
      }
    },
    "overview": {
      "label": "סקירה כללית"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "לְסַנֵן"
      }
    },
    "loadMore": {
      "buttonLabel": "טען עוד"
    },
    "totalPaymentDue": {
      "label": "סכום לתשלום"
    },
    "order": {
      "date": {
        "label": "תאריך הזמנה"
      }
    },
    "contractCountry": {
      "label": "מדינה מתקשרת"
    },
    "iCSOG": {
      "label": "אספקה פנים-קהילתית"
    }
  },
  "ga-IE": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Cuir sonraí íocaíochta in eagar"
        }
      },
      "information": {
        "label": "Faisnéis íocaíochta",
        "show": {
          "buttonLabel": "Faisnéis íocaíochta"
        }
      },
      "instrument": {
        "label": "Lonstraim íocaíochta"
      },
      "startTime": {
        "label": "Am na híocaíochta"
      },
      "endDueTime": {
        "label": "Deireadh na tréimhse íocaíochta"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Tréimhse tuairiscithe"
      },
      "year": {
        "label": "Bliain"
      },
      "months": {
        "label": "Mí"
      }
    },
    "created": {
      "startTime": {
        "label": "Dáta cruthaithe"
      }
    },
    "overview": {
      "label": "Forbhreathnú"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Scagaire"
      }
    },
    "loadMore": {
      "buttonLabel": "Luchtaigh níos mó"
    },
    "totalPaymentDue": {
      "label": "Méid íocaíochta"
    },
    "order": {
      "date": {
        "label": "Dáta ordaithe"
      }
    },
    "contractCountry": {
      "label": "Tír chonarthach"
    },
    "iCSOG": {
      "label": "Soláthairtí laistigh den phobal"
    }
  },
  "ja-JP": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "お支払いの詳細を編集"
        }
      },
      "information": {
        "label": "支払情報",
        "show": {
          "buttonLabel": "支払情報"
        }
      },
      "instrument": {
        "label": "支払い方法"
      },
      "startTime": {
        "label": "お支払い時期"
      },
      "endDueTime": {
        "label": "支払い期間の終了"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "報告期間"
      },
      "year": {
        "label": "年"
      },
      "months": {
        "label": "月"
      }
    },
    "created": {
      "startTime": {
        "label": "作成日"
      }
    },
    "overview": {
      "label": "概観"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "フィルタ"
      }
    },
    "loadMore": {
      "buttonLabel": "もっと読み込む"
    },
    "totalPaymentDue": {
      "label": "支払金額"
    },
    "order": {
      "date": {
        "label": "注文日"
      }
    },
    "contractCountry": {
      "label": "締約国"
    },
    "iCSOG": {
      "label": "コミュニティ内供給"
    }
  },
  "hr-HR": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Uređivanje podataka o plaćanju"
        }
      },
      "information": {
        "label": "Informacije o plaćanju",
        "show": {
          "buttonLabel": "Informacije o plaćanju"
        }
      },
      "instrument": {
        "label": "Instrument plaćanja"
      },
      "startTime": {
        "label": "Vrijeme plaćanja"
      },
      "endDueTime": {
        "label": "Kraj razdoblja plaćanja"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Izvještajno razdoblje"
      },
      "year": {
        "label": "Godina"
      },
      "months": {
        "label": "Mjesec"
      }
    },
    "created": {
      "startTime": {
        "label": "Datum stvaranja"
      }
    },
    "overview": {
      "label": "Pregled"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtar"
      }
    },
    "loadMore": {
      "buttonLabel": "Učitaj više"
    },
    "totalPaymentDue": {
      "label": "Iznos plaćanja"
    },
    "order": {
      "date": {
        "label": "Datum narudžbe"
      }
    },
    "contractCountry": {
      "label": "Zemlja ugovornica"
    },
    "iCSOG": {
      "label": "Opskrba unutar zajednice"
    }
  },
  "lv-LV": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Rediģēt maksājuma informāciju"
        }
      },
      "information": {
        "label": "Informācija par maksājumu",
        "show": {
          "buttonLabel": "Informācija par maksājumu"
        }
      },
      "instrument": {
        "label": "Maksāšanas līdzeklis"
      },
      "startTime": {
        "label": "Apmaksas laiks"
      },
      "endDueTime": {
        "label": "Maksājuma termiņa beigas"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Pārskata periods"
      },
      "year": {
        "label": "Gads"
      },
      "months": {
        "label": "Mēnesis"
      }
    },
    "created": {
      "startTime": {
        "label": "Izveidošanas datums"
      }
    },
    "overview": {
      "label": "Pārskats"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtrēt"
      }
    },
    "loadMore": {
      "buttonLabel": "Lelādēt vairāk"
    },
    "totalPaymentDue": {
      "label": "Maksājuma summa"
    },
    "order": {
      "date": {
        "label": "Pasūtījuma datums"
      }
    },
    "contractCountry": {
      "label": "Līgumslēdzēja valsts"
    },
    "iCSOG": {
      "label": "Krājumi Kopienas iekšienē"
    }
  },
  "lt-LT": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Redaguoti mokėjimo informaciją"
        }
      },
      "information": {
        "label": "Mokėjimo informacija",
        "show": {
          "buttonLabel": "Mokėjimo informacija"
        }
      },
      "instrument": {
        "label": "Mokėjimo priemonė"
      },
      "startTime": {
        "label": "Mokėjimo laikas"
      },
      "endDueTime": {
        "label": "Mokėjimo laikotarpio pabaiga"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Ataskaitinis laikotarpis"
      },
      "year": {
        "label": "Metai"
      },
      "months": {
        "label": "Mėnuo"
      }
    },
    "created": {
      "startTime": {
        "label": "Sukūrimo data"
      }
    },
    "overview": {
      "label": "Apžvalga"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtras"
      }
    },
    "loadMore": {
      "buttonLabel": "Įkelkite daugiau"
    },
    "totalPaymentDue": {
      "label": "Mokėjimo suma"
    },
    "order": {
      "date": {
        "label": "Užsakymo data"
      }
    },
    "contractCountry": {
      "label": "Susitariančioji šalis"
    },
    "iCSOG": {
      "label": "Atsargos bendruomenės viduje"
    }
  },
  "pt-PT": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Editar detalhes do pagamento"
        }
      },
      "information": {
        "label": "Informação de pagamento",
        "show": {
          "buttonLabel": "Informação de pagamento"
        }
      },
      "instrument": {
        "label": "Instrumento de pagamento"
      },
      "startTime": {
        "label": "Hora do pagamento"
      },
      "endDueTime": {
        "label": "Fim do período de pagamento"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Período coberto pelo relatório"
      },
      "year": {
        "label": "Ano"
      },
      "months": {
        "label": "Mês"
      }
    },
    "created": {
      "startTime": {
        "label": "Data de criação"
      }
    },
    "overview": {
      "label": "Visão geral"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtro"
      }
    },
    "loadMore": {
      "buttonLabel": "Carregue mais"
    },
    "totalPaymentDue": {
      "label": "Valor do pagamento"
    },
    "order": {
      "date": {
        "label": "Data do pedido"
      }
    },
    "contractCountry": {
      "label": "País contratante"
    },
    "iCSOG": {
      "label": "Suprimentos intracomunitários"
    }
  },
  "ro-RO": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Editați detaliile de plată"
        }
      },
      "information": {
        "label": "Informatii de plata",
        "show": {
          "buttonLabel": "Informatii de plata"
        }
      },
      "instrument": {
        "label": "Instrument de plată"
      },
      "startTime": {
        "label": "Ora de plată"
      },
      "endDueTime": {
        "label": "Sfârșitul perioadei de plată"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Perioadă de raportare"
      },
      "year": {
        "label": "An"
      },
      "months": {
        "label": "Lună"
      }
    },
    "created": {
      "startTime": {
        "label": "Data crearii"
      }
    },
    "overview": {
      "label": "Prezentare generală"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtru"
      }
    },
    "loadMore": {
      "buttonLabel": "Incarca mai mult"
    },
    "totalPaymentDue": {
      "label": "Suma de plată"
    },
    "order": {
      "date": {
        "label": "Data comandă"
      }
    },
    "contractCountry": {
      "label": "Țara contractantă"
    },
    "iCSOG": {
      "label": "Furnizare intracomunitară"
    }
  },
  "ru-RU": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Изменить платежные реквизиты"
        }
      },
      "information": {
        "label": "Платежная информация",
        "show": {
          "buttonLabel": "Платежная информация"
        }
      },
      "instrument": {
        "label": "Платежный инструмент"
      },
      "startTime": {
        "label": "Время оплаты"
      },
      "endDueTime": {
        "label": "Конец платежного периода"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Отчетный период"
      },
      "year": {
        "label": "Год"
      },
      "months": {
        "label": "Месяц"
      }
    },
    "created": {
      "startTime": {
        "label": "Дата создания"
      }
    },
    "overview": {
      "label": "обзор"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Фильтр"
      }
    },
    "loadMore": {
      "buttonLabel": "Загрузи больше"
    },
    "totalPaymentDue": {
      "label": "Сумма к оплате"
    },
    "order": {
      "date": {
        "label": "Дата предоставления данных"
      }
    },
    "contractCountry": {
      "label": "Договаривающаяся страна"
    },
    "iCSOG": {
      "label": "доставка в пределах сообщества (ЕС)"
    }
  },
  "sv-SE": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Redigera betalningsinformation"
        }
      },
      "information": {
        "label": "Information om betalning",
        "show": {
          "buttonLabel": "Information om betalning"
        }
      },
      "instrument": {
        "label": "Betalningsinstrument"
      },
      "startTime": {
        "label": "Betalningstid"
      },
      "endDueTime": {
        "label": "Betalningsperiodens slut"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Rapporteringsperiod"
      },
      "year": {
        "label": "År"
      },
      "months": {
        "label": "Månad"
      }
    },
    "created": {
      "startTime": {
        "label": "Skapelsedagen"
      }
    },
    "overview": {
      "label": "Översikt"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtrera"
      }
    },
    "loadMore": {
      "buttonLabel": "Ladda mer"
    },
    "totalPaymentDue": {
      "label": "Betalningsbelopp"
    },
    "order": {
      "date": {
        "label": "Orderdatum"
      }
    },
    "contractCountry": {
      "label": "Avtalsland"
    },
    "iCSOG": {
      "label": "Tillbehör inom gemenskapen"
    }
  },
  "sk-SK": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Upravte podrobnosti platby"
        }
      },
      "information": {
        "label": "Informácie o platbe",
        "show": {
          "buttonLabel": "Informácie o platbe"
        }
      },
      "instrument": {
        "label": "Platobný nástroj"
      },
      "startTime": {
        "label": "Čas platby"
      },
      "endDueTime": {
        "label": "Koniec platobného obdobia"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Vykazované obdobie"
      },
      "year": {
        "label": "Rok"
      },
      "months": {
        "label": "Mesiac"
      }
    },
    "created": {
      "startTime": {
        "label": "Dátum vytvorenia"
      }
    },
    "overview": {
      "label": "Prehľad"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filter"
      }
    },
    "loadMore": {
      "buttonLabel": "Načítať viac"
    },
    "totalPaymentDue": {
      "label": "Čiastka na úhradu"
    },
    "order": {
      "date": {
        "label": "Dátum objednávky"
      }
    },
    "contractCountry": {
      "label": "Zmluvná krajina"
    },
    "iCSOG": {
      "label": "Dodávky v rámci Spoločenstva"
    }
  },
  "cs-CZ": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Upravit platební údaje"
        }
      },
      "information": {
        "label": "Informace o platbě",
        "show": {
          "buttonLabel": "Informace o platbě"
        }
      },
      "instrument": {
        "label": "Platební nástroj"
      },
      "startTime": {
        "label": "Čas platby"
      },
      "endDueTime": {
        "label": "Konec platebního období"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Doba ohlášení"
      },
      "year": {
        "label": "Rok"
      },
      "months": {
        "label": "Měsíc"
      }
    },
    "created": {
      "startTime": {
        "label": "Datum vzniku"
      }
    },
    "overview": {
      "label": "Přehled"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtr"
      }
    },
    "loadMore": {
      "buttonLabel": "Načíst další"
    },
    "totalPaymentDue": {
      "label": "Částka k úhradě"
    },
    "order": {
      "date": {
        "label": "Datum objednávky"
      }
    },
    "contractCountry": {
      "label": "Smluvní země"
    },
    "iCSOG": {
      "label": "Zásoby uvnitř Společenství"
    }
  },
  "tr-TR": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Ödeme ayrıntılarını düzenleme"
        }
      },
      "information": {
        "label": "Ödeme bilgileri",
        "show": {
          "buttonLabel": "Ödeme bilgileri"
        }
      },
      "instrument": {
        "label": "Ödeme aracı"
      },
      "startTime": {
        "label": "Ödeme zamanı"
      },
      "endDueTime": {
        "label": "Ödeme süresinin sonu"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Raporlama dönemi"
      },
      "year": {
        "label": "Yıl"
      },
      "months": {
        "label": "Ay"
      }
    },
    "created": {
      "startTime": {
        "label": "Oluşturulma tarihi"
      }
    },
    "overview": {
      "label": "Genel bakış"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtre"
      }
    },
    "loadMore": {
      "buttonLabel": "Daha fazla yükle"
    },
    "totalPaymentDue": {
      "label": "Ödeme miktarı"
    },
    "order": {
      "date": {
        "label": "Sipariş tarihi"
      }
    },
    "contractCountry": {
      "label": "Sözleşme yapılan ülke"
    },
    "iCSOG": {
      "label": "Topluluk içi malzemeler"
    }
  },
  "hu-HU": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "A fizetési adatok szerkesztése"
        }
      },
      "information": {
        "label": "Fizetési információ",
        "show": {
          "buttonLabel": "Fizetési információ"
        }
      },
      "instrument": {
        "label": "Fizetés módja"
      },
      "startTime": {
        "label": "Fizetés időpontja"
      },
      "endDueTime": {
        "label": "A fizetési időszak vége"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Jelentési időszak"
      },
      "year": {
        "label": "Év"
      },
      "months": {
        "label": "Hónap"
      }
    },
    "created": {
      "startTime": {
        "label": "Létrehozás dátuma"
      }
    },
    "overview": {
      "label": "Áttekintés"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Szűrő"
      }
    },
    "loadMore": {
      "buttonLabel": "Töltsön be többet"
    },
    "totalPaymentDue": {
      "label": "Végösszeg"
    },
    "order": {
      "date": {
        "label": "Rendelés dátuma"
      }
    },
    "contractCountry": {
      "label": "Szerződő ország"
    },
    "iCSOG": {
      "label": "Közösségen belüli ellátás"
    }
  },
  "fr-BE": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Modifier les détails du paiement"
        }
      },
      "information": {
        "label": "Informations de paiement",
        "show": {
          "buttonLabel": "Informations de paiement"
        }
      },
      "instrument": {
        "label": "Instrument de paiement"
      },
      "startTime": {
        "label": "Temps de transfert"
      },
      "endDueTime": {
        "label": "Fin de la période de paiement"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Période de rapport"
      },
      "year": {
        "label": "Année"
      },
      "months": {
        "label": "Mois"
      }
    },
    "created": {
      "startTime": {
        "label": "Date de création"
      }
    },
    "overview": {
      "label": "Aperçu"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtre"
      }
    },
    "loadMore": {
      "buttonLabel": "Charger plus"
    },
    "totalPaymentDue": {
      "label": "Montant du paiement"
    },
    "order": {
      "date": {
        "label": "Date de commande"
      }
    },
    "contractCountry": {
      "label": "Pays contractant"
    },
    "iCSOG": {
      "label": "Fournitures intracommunautaires"
    }
  },
  "fr-LU": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Modifier les détails du paiement"
        }
      },
      "information": {
        "label": "Informations de paiement",
        "show": {
          "buttonLabel": "Informations de paiement"
        }
      },
      "instrument": {
        "label": "Instrument de paiement"
      },
      "startTime": {
        "label": "Temps de transfert"
      },
      "endDueTime": {
        "label": "Fin de la période de paiement"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Période de rapport"
      },
      "year": {
        "label": "Année"
      },
      "months": {
        "label": "Mois"
      }
    },
    "created": {
      "startTime": {
        "label": "Date de création"
      }
    },
    "overview": {
      "label": "Aperçu"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtre"
      }
    },
    "loadMore": {
      "buttonLabel": "Charger plus"
    },
    "totalPaymentDue": {
      "label": "Montant du paiement"
    },
    "order": {
      "date": {
        "label": "Date de commande"
      }
    },
    "contractCountry": {
      "label": "Pays contractant"
    },
    "iCSOG": {
      "label": "Fournitures intracommunautaires"
    }
  },
  "mt-MT": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Editja d-dettalji tal-ħlas"
        }
      },
      "information": {
        "label": "Informazzjoni dwar il-ħlas",
        "show": {
          "buttonLabel": "Informazzjoni dwar il-ħlas"
        }
      },
      "instrument": {
        "label": "Strument tal-ħlas"
      },
      "startTime": {
        "label": "Ħin tat-trasferiment"
      },
      "endDueTime": {
        "label": "Tmiem tal-perjodu tal-ħlas"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Perjodu ta 'Rappurtar"
      },
      "year": {
        "label": "Sena"
      },
      "months": {
        "label": "Xahar"
      }
    },
    "created": {
      "startTime": {
        "label": "Data tal-ħolqien"
      }
    },
    "overview": {
      "label": "Ħarsa ġenerali"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Iffiltra"
      }
    },
    "loadMore": {
      "buttonLabel": "Tagħbija aktar"
    },
    "totalPaymentDue": {
      "label": "Ammont tal-ħlas"
    },
    "order": {
      "date": {
        "label": "data tal-ordni"
      }
    },
    "contractCountry": {
      "label": "Pajjiż Kontraenti"
    },
    "iCSOG": {
      "label": "Provvisti intra-komunitarji"
    }
  },
  "sl-SI": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Uredite podatke o plačilu"
        }
      },
      "information": {
        "label": "Informacije o plačilu",
        "show": {
          "buttonLabel": "Informacije o plačilu"
        }
      },
      "instrument": {
        "label": "Plačilni instrument"
      },
      "startTime": {
        "label": "Čas plačila"
      },
      "endDueTime": {
        "label": "Konec plačilnega obdobja"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Obdobje poročanja"
      },
      "year": {
        "label": "Leto"
      },
      "months": {
        "label": "Mesec"
      }
    },
    "created": {
      "startTime": {
        "label": "Datum nastanka"
      }
    },
    "overview": {
      "label": "Pregled"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filter"
      }
    },
    "loadMore": {
      "buttonLabel": "Naloži več"
    },
    "totalPaymentDue": {
      "label": "Znesek plačila"
    },
    "order": {
      "date": {
        "label": "Datum naročila"
      }
    },
    "contractCountry": {
      "label": "Država pogodbenica"
    },
    "iCSOG": {
      "label": "Potrebščine znotraj skupnosti"
    }
  },
  "el-CY": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Επεξεργασία στοιχείων πληρωμής"
        }
      },
      "information": {
        "label": "Πληροφορίες Πληρωμής",
        "show": {
          "buttonLabel": "Πληροφορίες Πληρωμής"
        }
      },
      "instrument": {
        "label": "Μέσο πληρωμής"
      },
      "startTime": {
        "label": "Ώρα πληρωμής"
      },
      "endDueTime": {
        "label": "Λήξη της περιόδου πληρωμής"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Περίοδος αναφοράς"
      },
      "year": {
        "label": "Ετος"
      },
      "months": {
        "label": "Μήνας"
      }
    },
    "created": {
      "startTime": {
        "label": "Ημερομηνία δημιουργίας"
      }
    },
    "overview": {
      "label": "ΣΦΑΙΡΙΚΗ ΕΙΚΟΝΑ"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Φίλτρο"
      }
    },
    "loadMore": {
      "buttonLabel": "Φόρτωσε περισσότερα"
    },
    "totalPaymentDue": {
      "label": "Ποσό ΠΛΗΡΩΜΗΣ"
    },
    "order": {
      "date": {
        "label": "Ημερομηνία παραγγελίας"
      }
    },
    "contractCountry": {
      "label": "Συμβαλλόμενη χώρα"
    },
    "iCSOG": {
      "label": "Ενδοκοινοτικές προμήθειες"
    }
  },
  "tr-CY": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "Ödeme ayrıntılarını düzenleme"
        }
      },
      "information": {
        "label": "Ödeme bilgileri",
        "show": {
          "buttonLabel": "Ödeme bilgileri"
        }
      },
      "instrument": {
        "label": "Ödeme aracı"
      },
      "startTime": {
        "label": "Ödeme zamanı"
      },
      "endDueTime": {
        "label": "Ödeme süresinin sonu"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "Raporlama dönemi"
      },
      "year": {
        "label": "Yıl"
      },
      "months": {
        "label": "Ay"
      }
    },
    "created": {
      "startTime": {
        "label": "Oluşturulma tarihi"
      }
    },
    "overview": {
      "label": "Genel bakış"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "Filtre"
      }
    },
    "loadMore": {
      "buttonLabel": "Daha fazla yükle"
    },
    "totalPaymentDue": {
      "label": "Ödeme miktarı"
    },
    "order": {
      "date": {
        "label": "Sipariş tarihi"
      }
    },
    "contractCountry": {
      "label": "Sözleşme yapılan ülke"
    },
    "iCSOG": {
      "label": "Topluluk içi malzemeler"
    }
  },
  "uk-UA": {
    "payment": {
      "status": {
        "edit": {}
      },
      "information": {
        "show": {}
      },
      "instrument": {},
      "startTime": {},
      "endDueTime": {}
    },
    "reportingPeriod": {
      "period": {},
      "year": {},
      "months": {}
    },
    "created": {
      "startTime": {}
    },
    "overview": {},
    "filter": {
      "toggle": {}
    },
    "loadMore": {},
    "totalPaymentDue": {},
    "order": {
      "date": {}
    },
    "contractCountry": {},
    "iCSOG": {}
  },
  "zh-CN": {
    "payment": {
      "status": {
        "edit": {
          "buttonLabel": "修改付款明细"
        }
      },
      "information": {
        "label": "支付信息",
        "show": {
          "buttonLabel": "支付信息"
        }
      },
      "instrument": {
        "label": "付款方式"
      },
      "startTime": {
        "label": "付款时间"
      },
      "endDueTime": {
        "label": "付款期结束"
      }
    },
    "reportingPeriod": {
      "period": {
        "label": "报告期"
      },
      "year": {
        "label": "年"
      },
      "months": {
        "label": "月"
      }
    },
    "created": {
      "startTime": {
        "label": "创建日期"
      }
    },
    "overview": {
      "label": "总览"
    },
    "filter": {
      "toggle": {
        "buttonLabel": "过滤"
      }
    },
    "loadMore": {
      "buttonLabel": "装载更多"
    },
    "totalPaymentDue": {
      "label": "支付金额"
    },
    "order": {
      "date": {
        "label": "订购日期"
      }
    },
    "contractCountry": {
      "label": "缔约国"
    },
    "iCSOG": {
      "label": "社区内用品"
    }
  }
}
</i18n>
