import {createRouter, createWebHistory} from 'vue-router'

// Public pages
import PrivacyPolicyPage from "./public/PrivacyPolicy/PrivacyPolicy";
import ImprintPage from "./public/Imprint/Imprint";

// Settings
import AccountSettings from "@dutypay/settings-pages/src/pages/AccountSettings";
const AccountInformation = () => import('@dutypay/settings-pages/src/pages/AccountSettings/AccountInformation');
const SecuritySettings = () => import('@dutypay/settings-pages/src/pages/AccountSettings/SecuritySettings');

import CompanySettings from "@dutypay/settings-pages/src/pages/CompanySettings";
const IntegrationSettings = () => import('@dutypay/settings-pages/src/pages/CompanySettings/IntegrationSettings');

import DeveloperSettings from "@dutypay/settings-pages/src/pages/DeveloperSettings";
const TranslationReport = () => import('@dutypay/settings-pages/src/pages/DeveloperSettings/TranslationReport');
const TranslationEmailReportOverview = () => import('@dutypay/settings-pages/src/pages/DeveloperSettings/TranslationEmailReportOverview');

import AdminSettings from "@dutypay/settings-pages/src/pages/AdminSettings";
const CompanyAdministration = () => import('@dutypay/settings-pages/src/pages/AdminSettings/CompanyAdministration');
const CompanyDetails = () => import('@dutypay/settings-pages/src/pages/AdminSettings/CompanyDetails');
const UserAdministration = () => import('@dutypay/settings-pages/src/pages/AdminSettings/UserAdministration');
const UserDetails = () => import('@dutypay/settings-pages/src/pages/AdminSettings/UserDetails');

// Pages
import {pages} from './pages.js';

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { left: 0, top: 0 }
        }
    },
    routes: [
        // ----------------
        //  PAGES
        // ----------------

        ...pages,

        // ----------------
        //  PUBLIC PAGES
        // ----------------
        {
            path: '/imprint',
            name: 'Imprint',
            component: ImprintPage,
            // meta: {
            //   public: true
            // }
        },
        {
            path: '/privacy-policy',
            name: 'Privacy Policy',
            component: PrivacyPolicyPage,
            // meta: {
            //   public: true
            // }
        },

        // ----------------
        //  SETTINGS
        // ----------------
        {
            path: '/account-settings',
            component: AccountSettings,
            children: [
                {
                    path: 'account-information',
                    component: AccountInformation
                },{
                    path: 'security-settings',
                    component: SecuritySettings
                }
            ]
        },
        {
            path: '/company-settings',
            component: CompanySettings,
            children: [{
                path: 'integrations',
                component: IntegrationSettings
            }
            ]
        },
        {
            path: '/developer-settings',
            component: DeveloperSettings,
            children: [
                {
                    path: 'translation-report',
                    component: TranslationReport,
                    beforeEnter: (to, from, next) => {
                        next();
                    }
                },
                {
                    path: 'translation-email-report-overview',
                    component: TranslationEmailReportOverview,
                    beforeEnter: (to, from, next) => {
                        next();
                    }
                }
            ]
        },
        {
            path: '/admin-settings',
            component: AdminSettings,
            children: [
                {
                    path: 'companies',
                    component: CompanyAdministration,
                },
                {
                    path: 'companies/:companyId',
                    component: CompanyDetails,
                    name: 'companyDetails',
                    props: true
                },
                {
                    path: 'users',
                    component: UserAdministration
                },
                {
                    path: 'users/:userId',
                    component: UserDetails,
                    name: 'userDetails',
                    props: true
                },
            ]
        }
    ]
});
