export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرشحات النشطة"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرشحات واضحة"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["على"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في أو قبل"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في أو بعد"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("operator")), " ", _interpolate(_named("date")), " :تاريخ بدء الإبلاغ"])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("operator")), " ", _interpolate(_named("date")), " :تاريخ بدء الإبلاغ"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :تاريخ انتهاء التقرير"])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :تاريخ الطلب"])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :تاريخ الطلب"])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :وقت السداد"])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :وقت السداد"])}
          }
        }
      },
      "ar-EG": {
        "activeFilter": {
          
        },
        "clearAllFilters": {
          
        },
        "operator": {
          "on": {
            
          },
          "onOrBefore": {
            
          },
          "onOrAfter": {
            
          }
        },
        "reportingPeriod": {
          "startDate": {
            
          },
          "endDate": {
            
          }
        },
        "order": {
          "date": {
            
          }
        },
        "payment": {
          "startTime": {
            
          }
        }
      },
      "ar-SA": {
        "activeFilter": {
          
        },
        "clearAllFilters": {
          
        },
        "operator": {
          "on": {
            
          },
          "onOrBefore": {
            
          },
          "onOrAfter": {
            
          }
        },
        "reportingPeriod": {
          "startDate": {
            
          },
          "endDate": {
            
          }
        },
        "order": {
          "date": {
            
          }
        },
        "payment": {
          "startTime": {
            
          }
        }
      },
      "de-DE": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Filter"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder vor"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder nach"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meldezeitraumbeginn: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meldezeitraumbeginn: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meldezeitraumende: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beauftragungsdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beauftragungsdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zahlungszeitpunkt: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zahlungszeitpunkt: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "de-AT": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Filter"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder vor"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder nach"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meldezeitraumbeginn: ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meldezeitraumbeginn: ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meldezeitraumende: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beauftragungsdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beauftragungsdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          }
        }
      },
      "de-CH": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Filter"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder vor"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am oder nach"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meldezeitraumbeginn: ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meldezeitraumbeginn: ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meldezeitraumende: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beauftragungsdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beauftragungsdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          }
        }
      },
      "en-US": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active filters"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filters"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on or before"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on or after"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reporting start date: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reporting start date: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reporting end date: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Order date: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Order date: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Transfer time: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Transfer time: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "en-GB": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active filters"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filters"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on or before"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on or after"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reporting start date: ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reporting start date: ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reporting end date: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Order date: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Order date: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer time"])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer time"])}
          }
        }
      },
      "it-IT": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri attivi"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella filtri"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sopra"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prima o poi"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["su o dopo"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data di inizio della segnalazione: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data di inizio della segnalazione: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data di fine del rapporto: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data dell'ordine: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data dell'ordine: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tempo di trasferimento: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tempo di trasferimento: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "fr-FR": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres actifs"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou avant"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou après"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de début du rapport: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de début du rapport: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de fin du rapport: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de commande: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de commande: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Temps de transfert: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Temps de transfert: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "es-ES": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros activos"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros claros"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en o antes"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sobre o después"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fecha de inicio de informes: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fecha de inicio de informes: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fecha de finalización del informe: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fecha de orden: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fecha de orden: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiempo de transferencia: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiempo de transferencia: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "pl-PL": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne filtry"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wyczyść filtry"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w dniu lub wcześniej"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w dniu lub po"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data rozpoczęcia raportowania: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data rozpoczęcia raportowania: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data zakończenia raportowania: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data zatwierdzenia danych : ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data zatwierdzenia danych : ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data płatności: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data płatności: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "nl-NL": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actieve filters"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duidelijke filters"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aan"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["op of voor"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["op of na"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startdatum rapportage: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Startdatum rapportage: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Einddatum rapportage: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Besteldatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Besteldatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tijdstip van betaling: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tijdstip van betaling: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "bg-BG": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активни филтри"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изчистване на филтрите"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hа"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на или преди"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на или след"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Отчитане на начална дата: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Отчитане на начална дата: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Крайна дата на отчитане: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата на поръчка: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата на поръчка: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Време на плащане: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Време на плащане: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "bs-BA": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivni filtri"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jasni filtri"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ili pre"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ili posle"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum početka izvještavanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum početka izvještavanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Krajnji datum izvještavanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum narudžbe: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum narudžbe: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vrijeme plaćanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vrijeme plaćanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "da-DK": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive filtre"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ryd filtre"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på eller før"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på eller efter"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rapporterings startdato: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rapporterings startdato: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rapporterings slutdato: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bestillingsdato: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bestillingsdato: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tidspunkt for betaling: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tidspunkt for betaling: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "et-EE": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivsed filtrid"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tühjendage filtrid"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peal"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enne või enne"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sisse või pärast"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aruande alguskuupäev: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aruande alguskuupäev: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aruande lõppkuupäev: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tellimuse kuupäev: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tellimuse kuupäev: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Makse aeg: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Makse aeg: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "fi-FI": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiiviset suodattimet"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tyhjennä suodattimet"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päällä"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ennen tai ennen"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päällä tai sen jälkeen"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raportoinnin alkamispäivä: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raportoinnin alkamispäivä: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raportoinnin lopetuspäivä: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilauspäivämäärä: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilauspäivämäärä: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksuaika: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksuaika: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "el-GR": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργά φίλτρα"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["καθαρά φίλτρα"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επί"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["στις ή πριν από"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μετά ή μετά"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ημερομηνία έναρξης αναφοράς: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ημερομηνία έναρξης αναφοράς: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ημερομηνία λήξης αναφοράς: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ημερομηνία παραγγελίας: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ημερομηνία παραγγελίας: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ώρα πληρωμής: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ώρα πληρωμής: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "he-IL": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["פילטרים פעילים"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מסננים ברורים"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עַל"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בזמן או לפני"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["על או אחרי"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("operator")), " ", _interpolate(_named("date")), " :מדווח על תאריך התחלה"])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("operator")), " ", _interpolate(_named("date")), " :מדווח על תאריך התחלה"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["תאריך סיום מדווח: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :תאריך הזמנה"])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :תאריך הזמנה"])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :זמן התשלום"])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :זמן התשלום"])}
          }
        }
      },
      "ga-IE": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scagairí gníomhacha"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scagairí soiléire"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar nó roimhe"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar nó ina dhiaidh"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dáta tosaigh tuairiscithe: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dáta tosaigh tuairiscithe: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dáta deiridh tuairiscithe: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dáta ordaithe: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dáta ordaithe: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Am na híocaíochta: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Am na híocaíochta: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "ja-JP": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブフィルター"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターをクリア"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オン"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期日またはそれ以前"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以降"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["レポート開始日: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["レポート開始日: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["レポート終了日: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["注文日: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["注文日: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お支払い時期: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["お支払い時期: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "hr-HR": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivni filtri"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čisti filtri"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ili prije"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ili poslije"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum početka izvještavanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum početka izvještavanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Krajnji datum izvještavanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum narudžbe: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum narudžbe: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vrijeme plaćanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vrijeme plaćanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "lv-LV": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktīvie filtri"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notīrīt filtrus"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ieslēgts"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uz vai pirms tam"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uz vai pēc"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ziņošanas sākuma datums: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ziņošanas sākuma datums: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pārskata beigu datums: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pasūtījuma datums: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pasūtījuma datums: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apmaksas laiks: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apmaksas laiks: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "lt-LT": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktyvūs filtrai"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["išvalyti filtrus"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["įjungta"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prieš arba prieš"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["įjungtas arba po jo"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ataskaitų teikimo pradžios data: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ataskaitų teikimo pradžios data: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ataskaitos pabaigos data: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Užsakymo data: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Užsakymo data: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mokėjimo laikas: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mokėjimo laikas: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "pt-PT": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros ativos"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limpar filtros"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em ou antes"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em ou depois"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data de início do relatório: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data de início do relatório: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data de término do relatório: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data do pedido: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data do pedido: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hora do pagamento: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hora do pagamento: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "ro-RO": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre active"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtre clare"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe sau înainte"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe sau după"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data începerii raportării: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data începerii raportării: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data de încheiere a raportării: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data comandă: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data comandă: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ora de plată: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ora de plată: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "ru-RU": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные фильтры"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["очистить фильтры"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или раньше"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на или после"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата начала отчетности: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата начала отчетности: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата окончания отчетности: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата заказа: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Дата заказа: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Время оплаты: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Время оплаты: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "sv-SE": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva filter"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rensa filter"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på eller innan"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på eller efter"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rapporteringsstartdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rapporteringsstartdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rapporteringens slutdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Orderdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Orderdatum: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Betalningstid: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Betalningstid: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "sk-SK": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívne filtre"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vyčistiť filtre"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na alebo skôr"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["po alebo po"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dátum začiatku vykazovania: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dátum začiatku vykazovania: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dátum ukončenia prehľadu: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dátum objednávky: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dátum objednávky: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Čas platby: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Čas platby: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "cs-CZ": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní filtry"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vymazat filtry"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na nebo před"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na nebo po"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum zahájení hlášení: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum zahájení hlášení: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum ukončení vykazování: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum objednávky: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum objednávky: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Čas platby: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Čas platby: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "tr-TR": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif filtreler"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtreleri temizle"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["üzerinde"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["önce veya önce"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzerinde veya sonrasında"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raporlama başlangıç tarihi: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raporlama başlangıç tarihi: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raporlama bitiş tarihi: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sipariş tarihi: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sipariş tarihi: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ödeme zamanı: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ödeme zamanı: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "hu-HU": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív szűrők"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["törölje a szűrőket"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tovább"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rajta vagy előtte"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["be vagy után"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jelentés kezdete: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jelentés kezdete: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jelentés befejezési dátuma: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rendelés dátuma: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rendelés dátuma: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A fizetés időpontja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A fizetés időpontja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "fr-BE": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres actifs"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou avant"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou après"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de début du rapport: ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de début du rapport: ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de fin du rapport: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de commande: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de commande: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Temps de transfert: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Temps de transfert: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "fr-LU": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres actifs"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou avant"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le ou après"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de début du rapport: ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de début du rapport: ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de fin du rapport: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de commande: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Date de commande: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Temps de transfert: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Temps de transfert: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "mt-MT": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri attivi"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri Ċari"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fil -"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fuq jew qabel"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fuq jew wara"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bidu tal-perjodu tar-rappurtar: ", _interpolate(_named("data"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bidu tal-perjodu tar-rappurtar: ", _interpolate(_named("data"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tmiem tal-perjodu tar-rappurtar: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data tal-ordni: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Data tal-ordni: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ħin tat-trasferiment: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ħin tat-trasferiment: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "sl-SI": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivni filtri"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čisti filtri"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naprej"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ali pred"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ob ali pozneje"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum začetka poročanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum začetka poročanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Končni datum poročanja: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum naročila: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datum naročila: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Čas plačila: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Čas plačila: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "el-CY": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργά φίλτρα"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["καθαρά φίλτρα"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επί"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["στις ή πριν από"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μετά ή μετά"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ημερομηνία έναρξης αναφοράς: ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ημερομηνία έναρξης αναφοράς: ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ημερομηνία λήξης αναφοράς: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ημερομηνία παραγγελίας: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ημερομηνία παραγγελίας: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ώρα πληρωμής: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ώρα πληρωμής: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "tr-CY": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif filtreler"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtreleri temizle"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["üzerinde"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["önce veya önce"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzerinde veya sonrasında"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raporlama başlangıç tarihi: ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raporlama başlangıç tarihi: ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Raporlama bitiş tarihi: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sipariş tarihi: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sipariş tarihi: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ödeme zamanı: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ödeme zamanı: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        }
      },
      "uk-UA": {
        "activeFilter": {
          
        },
        "clearAllFilters": {
          
        },
        "operator": {
          "on": {
            
          },
          "onOrBefore": {
            
          },
          "onOrAfter": {
            
          }
        },
        "reportingPeriod": {
          "startDate": {
            
          },
          "endDate": {
            
          }
        },
        "order": {
          "date": {
            
          }
        },
        "payment": {
          "startTime": {
            
          }
        }
      },
      "zh-CN": {
        "activeFilter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有源过滤器"])}
        },
        "clearAllFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除筛选"])}
        },
        "operator": {
          "on": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上"])}
          },
          "onOrBefore": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или раньше"])}
          },
          "onOrAfter": {
            "displayValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或之后"])}
          }
        },
        "reportingPeriod": {
          "startDate": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["报告开始日期: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["报告开始日期: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["报告结束日期: ", _interpolate(_named("operator")), " ", _interpolate(_named("date"))])}
          }
        },
        "order": {
          "date": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :订购日期"])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :订购日期"])}
          }
        },
        "payment": {
          "startTime": {
            "displayValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :付款时间"])},
            "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("operator")), " :付款时间"])}
          }
        }
      }
    }
  })
}
